import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import ButtonSpinner from "../Spinners/buttonSpinner";
import TranslationService from "../../Services/translationService";

const ActionButton = props => {
	const { translations } = useSelector(state => state.translation);
	const { buttonSpinners } = useSelector(state => state.spinner);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const currentSpinner = useMemo(() => {
		return buttonSpinners.find(spinner => spinner === props.spinnerId);
	}, [buttonSpinners]);

	return translationService ? (
		<div className={`d-flex justify-content-end ${props.blockClassName ? props.blockClassName : ""}`}>
			<button
				type={props.type}
				className={`action-button d-flex align-items-center ${props.className
					} ${currentSpinner || props.disabled ? "pointer-events-none" : ""} `}
				onClick={props.clicked}
				disabled={currentSpinner || props.disabled}>
				{currentSpinner
					? translationService.translate("TR_LOADING")
					: props.name}
				{currentSpinner ? <ButtonSpinner spinner={currentSpinner} /> : null}
			</button>
		</div>
	) : null;
};

export default ActionButton;
