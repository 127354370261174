import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
	ERROR_KEY,
	MET_DEPARTMENT_USER_TYPE,
	STUDENT_USER_TYPE,
	SUCCESS_KEY,
	TR_DELETE_CONFIRM_MESSAGE_KEY,
	TR_NO,
	TR_YES,
	VALID_IMAGE_TYPES_KEY,
} from "../../Constants/mainKeys";
import TranslationService from "../../Services/translationService";
import ApiService from "./../../Services/apiService";
import ViewSvg from "./../../Components/Svg/viewSvg";
import EditSvg from "./../../Components/Svg/editSvg";
import DeleteSvg from "./../../Components/Svg/deleteSvg";
import AlertService from "../../Services/alertService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import uuid from "react-uuid";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import FileSvg from "./../../Components/Svg/fileSvg";
import {
	DOCUMENT_FORM_KEY,
	DOCUMENT_KEY,
	DOCUMENT_VIEW_KEY,
} from "../../Constants/urlKeys";
import NoDataImage from "./../../assets/images/illustrations/nodata.svg";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import MainService from "../../Services/mainService";
import CloseSvg from "./../../Components/Svg/closeSvg";
import UploadButton from "../../Components/UploadButton/uploadButton";
import RolesService from "../../Services/rolesService";
import { setBackUrl } from "../../Store/Actions/main";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

const Documents = props => {
	const mainService = new MainService();
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const role = useSelector(state => state.user.role);
	const [translationService, setTranslationService] = useState(null);
	const [studentDocuments, setStudentDocuments] = useState([]);
	const [currentDocument, setCurrentDocument] = useState(null);
	const [addButtonIsDisabled, setAddButtonIsDisabled] = useState(false);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [file, setFile] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

	const [roleService, setRoleService] = useState(null);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getStudentDocumentsByStudent();
	}, []);

	const getStudentDocumentsByStudent = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getStudentDocumentsByStudent()
			.then(response => {
				if (response.data) {
					setStudentDocuments([...response.data]);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const deleteDocument = document => {
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
				document.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteDocument(document.id)
				.then(() => {
					dispatch(removePartialViewSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DOCUMENT_SUCCESSFULLY_DELETED"),
					);
				})
				.catch(error => getFail(error, spinnerId));
			setStudentDocuments(
				studentDocuments.filter(
					documentItem => documentItem.id !== document.id,
				),
			);
		});
	};

	const documentSign = currentDocument => {
		const spinnerId = uuid();
		if (!file) {
			setIsInvalidSubmit(true);
			return;
		}
		dispatch(addModalSpinner(spinnerId));
		setAddButtonIsDisabled(true);
		const formData = new FormData();
		formData.append("documentId", currentDocument.id);
		formData.append("DocumentSignedFile", file);
		ApiService.documentSign(formData)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				dispatch(removeModalSpinner(spinnerId));
				cancel();
			})
			.catch(error => getFail(error, spinnerId));
	};

	const cancel = () => {
		setCurrentDocument(null);
		setAddButtonIsDisabled(false);
		setFile(null);
		setUploadedFile(null);
	};

	const uploadFile = event => {
		const file = event.target.files[0];
		if (file) {
			mainService
				.readFile(file, VALID_IMAGE_TYPES_KEY)
				.then(uploadedFile => {
					setUploadedFile(uploadedFile);
					setFile(file);
					setIsInvalidSubmit(false);
				})
				.catch(
					error =>
						error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							translationService.translate("TR_INVALID_FILE_FORMAT_EXC"),
						),
				);
		}
	};

	const removeImg = () => {
		setUploadedFile(null);
		setFile(null);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
		setAddButtonIsDisabled(false);
	};

	return translationService ? (
		<div className="container document-styles">
			{currentDocument ? (
				<ModalComponent
					title={currentDocument.name}
					isLarge={true}
					actionButtonTitle={translationService.translate("TR_SAVE")}
					addButtonIsDisabled={addButtonIsDisabled}
					cancel={cancel}
					onSubmit={() => documentSign(currentDocument)}>
					<form>
						<div className="row">
							<div className="col-12 col-lg-4 col-md-6">
								<div className="course-media-wrapper">
									<p className="my-2">
										{translationService.translate("TR_UPLOAD_FILE")} *
									</p>
									{uploadedFile ? (
										<div className="upload-file-content">
											<div onClick={removeImg}>
												<CloseSvg />
											</div>
											<div
												className="img"
												style={{ backgroundImage: `url(${uploadedFile})` }}
												alt="course-image"></div>
										</div>
									) : null}
								</div>
								<div className="course-media-wrapper">
									<div className="form-group position-relative">
										<UploadButton
											textInputClasses="pl-100"
											isInvalidSubmit={isInvalidSubmit}
											text={`${translationService.translate(
												"TR_UPLOAD_FILE",
											)} *`}
											clicked={uploadFile}
										/>
									</div>
									<small>
										{translationService
											.translate("TR_DOCUMENT_SIGN_VALID_IMAGE_TYPES")
											.replace("{0}", "'jpg'")
											.replace("{1}", "'jpeg'")
											.replace("{2}", "'png'")}
									</small>
								</div>
							</div>
						</div>
					</form>
				</ModalComponent>
			) : null}

			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_DOCUMENTS")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_DOCUMENTS")}
						pageName="student_documents"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />

			{user && user.userTypeId === MET_DEPARTMENT_USER_TYPE ? (
				<div className="d-flex align-items-center justify-content-end">
					<Link
						to={`/${language}/document/document-form`}
						className="mindalay--btn-default mr-2">
						{translationService.translate("TR_CREATE_DOCUMENT")}
					</Link>
				</div>
			) : null}

			<div className="row mt-3">
				{studentDocuments && studentDocuments.length ? (
					studentDocuments.map(document => {
						return (
							<div
								key={document.id}
								className="col-xl-4 col-lg-6 col-sm-6 col-12 mb-4">
								<div className={`course-wrapper--instructor`}>
									<div className="course-document-wrapper--instructor">
										<div className="course-document-icon">
											<FileSvg />
										</div>
										<div className="document-button-group">
											<Auxiliary>
												<Link
													className={`course-view-button`}
													title={translationService.translate("TR_VIEW")}
													to={`/${language}/${DOCUMENT_KEY}/${DOCUMENT_VIEW_KEY}/${document.id}`}
													onClick={() => {
														dispatch(setBackUrl(props.location.pathname));
													}}>
													<ViewSvg />
												</Link>
												{user &&
													user.userTypeId === MET_DEPARTMENT_USER_TYPE && (
														<>
															<Link
																className={`course-edit-button ${
																	document.signed ? "disabled" : ""
																}`}
																to="#"
																title={translationService.translate("TR_EDIT")}
																onClick={() => {
																	if (document.signed) {
																		return false;
																	} else {
																		props.history.push(
																			`/${language}/${DOCUMENT_KEY}/${DOCUMENT_FORM_KEY}/${document.id}`,
																		);
																	}
																}}>
																<EditSvg />
															</Link>
															<Link
																className={`course-delet-button ${
																	document.signed ? "disabled" : ""
																}`}
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																onClick={() => {
																	if (document.signed) {
																		return false;
																	} else {
																		deleteDocument(document);
																	}
																}}>
																<DeleteSvg />
															</Link>
														</>
													)}
												{roleService.checkRole("organizationdocument_sign") ? (
													<Link
														className={`course-edit-button ${
															document.signed ? "disabled" : ""
														}`}
														to="#"
														title={translationService.translate("TR_SIGN")}
														onClick={() => {
															if (document.signed) {
																return false;
															} else {
																setCurrentDocument(document);
															}
														}}>
														<FileSvg />
													</Link>
												) : null}
											</Auxiliary>
										</div>
									</div>

									<div className="p-3 border-bottom">
										<h6
											className="course-name--instructor cursor-pointer"
											title={document.name}
											onClick={() => {
												dispatch(setBackUrl(props.location.pathname));
												props.history.push(
													`/${language}/document/document-view/${document.id}`,
												);
											}}>
											{document.name ? document.name : ""}
										</h6>
									</div>
								</div>
							</div>
						);
					})
				) : (
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DOCUMENTS_INFO")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
		</div>
	) : null;
};

export default withRouter(Documents);
