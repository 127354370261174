import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Search from "../../Components/Search/search";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import OrganizationCourseBlock from "./organizationCourseBlock";
import { setPureLayoutBackUrl } from "../../Store/Actions/main";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import {
	COURSE_DASHBOARD_KEY,
	ORGANIZATION_KEY,
} from "../../Constants/urlKeys";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
	TR_DELETE_CONFIRM_MESSAGE_KEY,
	TR_NOT_SUBMITTED_FOR_VERIFICATION_KEY,
	TR_SUBMITTED_FOR_VERIFICATION_KEY,
	TR_DENIED_KEY,
	TR_VERIFIED_BUT_NOT_PUBLISHED_KEY,
	TR_PUBLISHED_KEY,
} from "../../Constants/mainKeys";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import ReactPaginate from "react-paginate";

class MyCreatedCourses extends Component {
	_isMounted = false;

	state = {
		courses: [],
		filteredCourses: [],
		translationService: null,
		numberOfDisplayedCourses: 12,
		filterValue: "",
		searchValue: "",
		triggerSearch: false,
		pagination: null,
		activePageNumber: 0,
		pageSize: 12,
	};

	componentDidMount() {
		this._isMounted = true;
		this.setTranslations();
		this.getCourses(1);
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
			JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this._isMounted &&
				this.setState({
					translationService: new TranslationService(this.props.translations),
				});
		}
	};

	getCourses = page => {
		const spinnerId = uuid();

		this.props.addPartialViewSpinner(spinnerId);
		ApiService.getMyCoursesAsOrgUserByPagination(page, this.state.pageSize)
			.then(response => {
				if (response.data && this._isMounted) {
					if (response && response.pagination) {
						this.setState({ pagination: JSON.parse(response.pagination) });
					}
					const courses = [...response.data];
					this.setState({ courses, filteredCourses: courses });
				}
				this.props.removePartialViewSpinner(spinnerId);
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	setSearchData = (data, searchValue) => {
		const { filterValue, courses } = this.state;
		let coursesData = !data && !searchValue ? courses : data;
		if (filterValue) {
			coursesData = this.switchCourses(coursesData, filterValue);
		}
		this.setState({ filteredCourses: coursesData, searchValue });
	};

	switchCourses = (courses, key) => {
		switch (key) {
			case TR_NOT_SUBMITTED_FOR_VERIFICATION_KEY:
				return courses.filter(
					data => !data.submitedForVerification && !data.verified,
				);
			case TR_SUBMITTED_FOR_VERIFICATION_KEY:
				return courses.filter(
					data =>
						data.submitedForVerification &&
						!data.verified &&
						!data.verificationDenied,
				);
			case TR_DENIED_KEY:
				return courses.filter(data => data.verificationDenied);
			case TR_VERIFIED_BUT_NOT_PUBLISHED_KEY:
				return courses.filter(data => data.verified && !data.published);
			case TR_PUBLISHED_KEY:
				return courses.filter(data => data.published);
		}
	};

	courseDeleteByOrgUser = course => {
		if (!course) {
			return;
		}
		const { translationService } = this.state;
		const courseId = course.id;
		const spinnerId = uuid();
		const { courses } = this.state;
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${course.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				this.props.addPartialViewSpinner(spinnerId);
				ApiService.courseDeleteByOrgUser(courseId)
					.then(() => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate("TR_COURSE_DELET_MESSAGE"),
						);
						var index = courses.findIndex(function (o) {
							return o.id === courseId;
						});
						if (index !== -1) courses.splice(index, 1);
						this.setState({ courses });
						this.props.removePartialViewSpinner(spinnerId);
					})
					.catch(error => this.deleteFail(error, spinnerId));
			})
			.catch(error => this.deleteFail(error, spinnerId));
	};

	deleteFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
	};

	getFail = (error, spinnerId) => {
		this.props.removePartialViewSpinner(spinnerId);
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	goBack = () => {
		this.props.history.push(`/${this.props.language}`);
	};

	handlePageClick = event => {
		this.getCourses(event.selected + 1);
		this.setState({ activePageNumber: event.selected });
	};

	render() {
		const {
			translationService,
			courses,
			numberOfDisplayedCourses,
			filteredCourses,
			activePageNumber,
			pagination,
		} = this.state;
		const { language } = this.props;

		var isEmptyMessage = null;
		if (!filteredCourses.length && courses.length) {
			isEmptyMessage = "Sorry, we couldn't find any results.";
		}
		if (!courses.length && translationService) {
			isEmptyMessage = translationService.translate("TR_NO_CREATED_COURSE");
		}

		return translationService ? (
			<div className="container">
				<div className="row mt-4">
					<div className="col-12">
						<h2 className="section-title">
							{translationService.translate("TR_COURSES")}
						</h2>
						<PageInfoBlock
							pageTitle={translationService.translate("TR_COURSES")}
							pageName="lecturer_courses"
							translationService={translationService}
						/>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col-12">
						<div className="mb-3">
							<div className="d-sm-flex flex-wrap d-block align-items-center justify-content-between">
								<div className="d-lg-flex d-block mb-2 mr-lg-2">
									{/* <Search
										searchData={courses}
										searchFieldName="name"
										emitSearchedData={(data, searchContent) =>
											this.setSearchData(data, searchContent)
										}
									/> */}
								</div>
								<div className="d-flex flex-wrap mindalay--btn-link-wrapper--instructor">
									<Link
										to={`/${language}/${ORGANIZATION_KEY}/${COURSE_DASHBOARD_KEY}`}
										className="mindalay--btn-default mindalay--btn-link mr-sm-2 mb-2"
									// onClick={() => this.props.setPureLayoutBackUrl(`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`)}
									>
										{translationService.translate("TR_CREATE_COURSE")}
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="row">
							{filteredCourses && filteredCourses.length ? (
								filteredCourses.map((course, courseIndex) => {
									return numberOfDisplayedCourses > courseIndex ? (
										<div
											key={course.id}
											className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4">
											<OrganizationCourseBlock
												isShowCommunications={true}
												course={course}
												delete={this.courseDeleteByOrgUser}
												getCourses={this.getCourses}
											/>
										</div>
									) : null;
								})
							) : isEmptyMessage ? (
								<div className="no-data-container">
									<div className="no-data-wrapper">
										<p className="col-12">{isEmptyMessage}</p>
										<img src={NoDataImage} />
									</div>
								</div>
							) : null}
						</div>
					</div>
					<div className="col-12 mt-3">
						{pagination ? (
							<div className="d-flex w-100 justify-content-end">
								<ReactPaginate
									nextLabel={translationService.translate("TR_NEXT")}
									onPageChange={this.handlePageClick}
									pageRangeDisplayed={3}
									marginPagesDisplayed={2}
									pageCount={pagination.TotalPages}
									previousLabel={translationService.translate("TR_PREVIOUS")}
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									breakLabel="..."
									breakClassName="page-item"
									breakLinkClassName="page-link"
									containerClassName="pagination"
									activeClassName="active"
									renderOnZeroPageCount={null}
									forcePage={activePageNumber}
								/>
							</div>
						) : null}
						{/* {filteredCourses &&
						filteredCourses.length > numberOfDisplayedCourses ? (
							<button
								type="button"
								className="mindalay--btn-dark w-100 mb-3"
								onClick={() =>
									this.setState({
										numberOfDisplayedCourses: numberOfDisplayedCourses + 12,
									})
								}>
								{translationService.translate("TR_SHOW_MORE")}
							</button>
						) : null} */}
					</div>
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	language: state.language.language,
	user: state.user.user
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	setPureLayoutBackUrl,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(MyCreatedCourses);
