import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "./../../Services/translationService";
import { Link, withRouter } from "react-router-dom";
import ApiService from "./../../Services/apiService";
import AlertService from "../../Services/alertService";
import uuid from "react-uuid";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import MainService from "../../Services/mainService";
import UploadButton from "../../Components/UploadButton/uploadButton";
import CloseSvg from "../../Components/Svg/closeSvg";
import Input from "../../Components/Inputs/input";
import Textarea from "../../Components/Inputs/textArea";
import ActionButton from "../../Components/ActionButton/actionButton";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import {
	addPartialViewSpinner,
	addButtonSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import { WEBINAR_KEY } from "../../Constants/urlKeys";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
	WEEK_DAYS_KEY,
	MONTH_ARR_KEY,
	VALID_TIME_KEY,
	WARNING_KEY,
} from "./../../Constants/mainKeys";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import SmallQuestionMark from "../../Components/SmallQuestionMark/smallQuestionMark";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import HelpService from "../../Services/helpService";
import moment from "moment";

const WebinarForm = props => {
	const weekDaysArr = WEEK_DAYS_KEY;
	const monthArr = MONTH_ARR_KEY;
	const { user } = useSelector(state => state.user);
	const [weekDays, setWeekDays] = useState([
		{ name: "TR_SUNDAY", active: false, days: [] },
		{ name: "TR_MONDAY", active: false, days: [] },
		{ name: "TR_TUESDAY", active: false, days: [] },
		{ name: "TR_WEDNESDAY", active: false, days: [] },
		{ name: "TR_THURSDAY", active: false, days: [] },
		{ name: "TR_FRIDAY", active: false, days: [] },
		{ name: "TR_SATURDAY", active: false, days: [] },
	]);
	const webinarDay = { webinarDay: null, startTime: "", endTime: "" };

	const spinnerId = uuid();
	const mainService = new MainService();
	const nameMaxLength = 120;
	const descriptionMaxLength = 1000;
	const dispatch = useDispatch();
	const webinarId = props.match.params.webinarId;
	const translations = useSelector(state => state.translation.translations);
	const currentQuestionMarkData = useSelector(
		state => state.main.currentQuestionMarkData,
	);
	const language = useSelector(state => state.language.language);
	const [translationService, setTranslationService] = useState(null);
	const [file, setFile] = useState(null);
	const [groups, setGroups] = useState([]);
	const [disciplines, setDisciplines] = useState([]);
	const [lecturers, setLecturers] = useState([]);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [divisionId, setDivisionId] = useState(null);
	const [disciplineId, setDisciplineId] = useState(null);
	const [lecturerId, setLecturerId] = useState(null);
	const [currentUploadedImage, setCurrentUploadedImage] = useState(null);
	const [failedFields, setFailedFields] = useState(null);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [webinarDate, setWebinarDate] = useState("");
	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [isShowSingleWebinar, setIsShowSingleWebinar] = useState(false);
	const [isShowPeriodicWebinar, setIsShowPeriodicWebinar] = useState(false);
	const [isShowCustomWebinar, setIsShowCustomWebinar] = useState(false);
	const [periodicity, setPeriodicity] = useState(null);
	const [defaultDisciplinesVlaue, setDefaultDisciplinesValue] = useState("");
	const [defaultLecturersValue, setDefaultLecturersValue] = useState("");

	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [isInvalidDate, setIsInvalidDate] = useState(false);
	const [isInvalidWebinar, setIsInvalidWebinar] = useState(false);
	const [isInvalidWeekDays, setIsInvalidWeekDays] = useState(false);
	const [isInvalidTime, setIsInvalidTime] = useState(false);
	const [isStartTimeMoreThanEndTime, setIsStartTimeMoreThanEndTime] =
		useState(false);
	const [areMessagesByConfirmation, setAreMessagesByConfirmation] =
		useState(false);
	const [restrictMessages, setRestrictMessages] = useState(false);

	const [occupiedDates, setOccupiedDates] = useState(null);

	const [selectedDivision, setSelectedDivision] = useState(null);
	const [selectedEducationalUnit, setSelectedEducationalUnit] = useState(null);
	const [selectedSubject, setSelectedSubject] = useState(null);
	const [selectedId, setSelectedId] = useState(null);

	var [rangeDays, setRangeDays] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		// getGroups();
		getOwnerDivisionsForWebinars();
	}, []);

	useEffect(() => {
		if (webinarId) {
			getWebinarById(webinarId);
		}
	}, []);

	const selecteDivision = division => {
		if (!division) {
			return false;
		}
		if (
			!division.educationalUnits ||
			(division.educationalUnits && !division.educationalUnits.length)
		) {
			AlertService.alert(
				WARNING_KEY,
				"There are no educational units in the current division",
			);
			setSelectedDivision(null);
			setDivisionId(null);
			setSelectedEducationalUnit(null);
			setSelectedSubject(null);
			setLecturerId(null);
			return;
		}
		setIsInvalidSubmit(false);
		setSelectedDivision(division);
		setDivisionId(division.id);
		setSelectedEducationalUnit(null);
		setSelectedSubject(null);
		setLecturerId(null);
	};

	const getWebinarById = webinarId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getWebinarbyIdFromOrgUser(webinarId, user)
			.then(response => {
				if (response.data) {
					const data = { ...response.data };
					setName(data.name);
					setDescription(data.description ? data.description : "");
					setDivisionId(data.divisionId);
					setDisciplineId(data.subjectId);
					setLecturerId(data.lecturerUserId);
					setPeriodicity(data.periodicity);
					// data.avatarFilePath && setUploadedFile(`${data.avatarFilePath}?${uuid()}`);
					data.avatarFilePath && setUploadedFile(`${data.avatarFilePath}`);
					setDefaultDisciplinesValue(data.subjectName ? data.subjectName : "");
					setDefaultLecturersValue(data.lecturerName ? data.lecturerName : "");
					setAreMessagesByConfirmation(
						data.areMessagesByConfirmation ? true : false,
					);
					setRestrictMessages(data.restrictMessages ? true : false);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const checkOnlyTime = (startTime, endTime) => {
		if (!startTime || !endTime) {
			return;
		} else {
			if (MainService.checkOnlyTime(startTime, endTime) === VALID_TIME_KEY) {
				setIsStartTimeMoreThanEndTime(false);
			} else {
				setIsStartTimeMoreThanEndTime(true);
			}
		}
	};

	const onChange = (event, maxLength = null, cb) => {
		const failedFields = removeFailedFields(event.target.name);
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setIsInvalidSubmit(false);
		setFailedFields(failedFields);
		cb(event.target.value);
	};

	const removeFailedFields = name => {
		const failedFieldsItem = failedFields && { ...failedFields };
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFieldsItem && delete failedFieldsItem[fieldName];
		return failedFieldsItem;
	};

	// const getGroups = () => {
	// 	const spinnerId = uuid();
	// 	dispatch(addPartialViewSpinner(spinnerId));
	// 	ApiService.getGroups()
	// 		.then(response => {
	// 			if (response.data) {
	// 				setGroups(response.data);
	// 			}
	// 			dispatch(removePartialViewSpinner(spinnerId));
	// 		})
	// 		.catch(error => getFail(error, spinnerId));
	// };

	const getOwnerDivisionsForWebinars = (divisionId = null, index = null) => {
		const spinnerId = uuid();
		setSelectedId(divisionId);
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOwnerDivisionsForWebinars(divisionId)
			.then(response => {
				if (response && response.data) {
					if (response.data.length) {
						let newGrops = groups;
						if (index !== null) {
							newGrops = groups.slice(0, index + 1);
						}
						setGroups([...newGrops, ...[response.data]]);
					} else {
						if (index !== null) {
							let newGrops = groups.slice(0, index + 1);
							setGroups([...newGrops]);
						}
						divisionId &&
							AlertService.alert(
								WARNING_KEY,
								translationService.translate(
									"TR_CURRENT_DIVISION_HASNO_CHILD_DIVISION",
								),
							);
					}
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const onSelectChangeWithRequest = (selectedCategory, cb) => {
		if (!selectedCategory) {
			return;
		}
		cb(selectedCategory.id);
		const spinnerId = uuid();
		setIsInvalidSubmit(false);
		setDisciplineId(null);
		setLecturerId(null);
		setDefaultDisciplinesValue("");
		setDefaultLecturersValue("");
		ApiService.getOrganizationEducationalLevelsById(
			selectedCategory.organizationEducationLevelId,
		)
			.then(response => {
				if (response.data) {
					setDisciplines(response.data);
				}
			})
			.catch(error => getFail(error, spinnerId));
	};

	const onDisciplineChange = (selectedCategory, cb) => {
		if (!selectedCategory) {
			return;
		}
		cb(selectedCategory.id);
		setLecturerId(null);
		setIsInvalidSubmit(false);
		const spinnerId = uuid();
		ApiService.getLecturersByDisciplineId(selectedCategory.id)
			.then(response => {
				if (response.data) {
					setLecturers(response.data);
				}
			})
			.catch(error => getFail(error, spinnerId));
	};

	// const onSelectChangeWithOutRequest = (selectedCategory, cb) => {
	// 	if (!selectedCategory) {
	// 		return;
	// 	}
	// 	cb(selectedCategory.id);
	// 	setIsInvalidSubmit(false);
	// };

	const uploadFile = event => {
		const file = event.target.files[0];
		if (file) {
			mainService
				.readFile(file, VALID_IMAGE_TYPES_KEY)
				.then(uploadedImage => {
					setUploadedFile(uploadedImage);
					setFile(file);
				})
				.catch(
					error =>
						error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							translationService.translate("TR_INVALID_FILE_FORMAT_EXC"),
						),
				);
		}
	};

	const removeImg = () => {
		setUploadedFile(null);
		setCurrentUploadedImage(null);
	};

	const onDateChange = (event, cb) => {
		setIsInvalidSubmit(false);
		cb(event.target.value);
	};

	const checkDateFormatAndIsValid = date => {
		const isInvalidDateFormat = MainService.isValidDateTime(date);
		const isInvalidDate = MainService.validateOnlyDate(date);
		if (!isInvalidDateFormat || !isInvalidDate) {
			setIsInvalidDate(true);
		} else setIsInvalidDate(false);
	};

	const checkStartEndDateIsValid = date => {
		setIsInvalidDate(false);
		const isInvalidDateFormat = MainService.isValidDateTime(date);
		const isInvalidDate = MainService.validateOnlyDate(date);
		if (!isInvalidDateFormat || !isInvalidDate) {
			return setIsInvalidDate(true);
		} else {
			if (startDate && endDate) {
				const dateCompaire = MainService.checkDates(startDate, endDate);
				if (dateCompaire && isInvalidDateFormat && isInvalidDate) {
					setIsInvalidDate(false);
				} else setIsInvalidDate(true);
			}
			if (startDate && !endDate) {
				if (isInvalidDateFormat && isInvalidDate) {
					setIsInvalidDate(false);
				} else setIsInvalidDate(true);
			}
		}
	};

	const onChangeCustomWebinarStartDate = (event, cb) => {
		cb(event.target.value);
	};

	const onChangeCustomWebinarEndDate = (event, cb) => {
		cb(event.target.value);
	};

	const checkStartEndDateIsValidCustomWebinar = date => {
		setIsInvalidDate(false);
		const isInvalidDateFormat = MainService.isValidDateTime(date);
		const isInvalidDate = MainService.validateOnlyDate(date);
		if (!isInvalidDateFormat || !isInvalidDate) {
			setIsInvalidDate(true);
			return;
		} else {
			setIsInvalidDate(false);
			if (startDate && endDate) {
				var rangeAllDays = getDates(new Date(startDate), new Date(endDate));
				rangeAllDays &&
					rangeAllDays.length &&
					setRangeDays(gerDuplicates(rangeAllDays));
			}
		}
	};

	const gerDuplicates = arr => {
		var map = arr.reduce((r, i) => {
			r[i.year] = r[i.year] || [];
			r[i.year].push(i);
			return r;
		}, {});
		var sortedArr = [];
		for (var key in map) {
			sortedArr.push(map[key]);
		}
		return sortedArr;
	};

	const getDates = (startDate, endDate) => {
		const dates = [];
		let currentDate = startDate;
		const addDays = function (days) {
			const date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
		while (currentDate <= endDate) {
			dates.push(getCurrentDateInfo(currentDate));
			currentDate = addDays.call(currentDate, 1);
		}
		return dates;
	};

	const getCurrentDateInfo = currentDay => {
		const days = [...weekDaysArr];
		return {
			year: currentDay.getFullYear(),
			month: currentDay.getMonth(),
			day: currentDay.getDate(),
			dayName: days[currentDay.getDay()],
			dataArr: [],
		};
	};

	const onTiemChange = (event, cb) => {
		var isValid = MainService.isValidTime(event.target.value);
		if (isValid) {
			setIsInvalidTime(false);
			setIsInvalidDate(false);
			cb(event.target.value);
		}
	};

	const addStartTimeEndTimeToCurrentWeekDayPeriodicWebinar = (
		index,
		weekDaysitem,
	) => {
		const weekDaysCopy = weekDaysitem ? [...weekDaysitem] : [...weekDays];
		const currentWeekDay = { ...weekDaysCopy[index] };
		const webinarDayCopy = { ...webinarDay };
		webinarDayCopy.webinarDay = index;
		if (
			!currentWeekDay.days.length ||
			(currentWeekDay.days[currentWeekDay.days.length - 1].startTime &&
				currentWeekDay.days[currentWeekDay.days.length - 1].endTime)
		) {
			currentWeekDay.days.push(webinarDayCopy);
		}
		weekDaysCopy[index] = currentWeekDay;
		setWeekDays(weekDaysCopy);
	};

	const addStartTimeEndTimeToCurrentWeekDayCustomWebinar = (
		monthIndex,
		dayIndex,
	) => {
		setIsInvalidWeekDays(false);
		const rangeDaysCopy = [...rangeDays];
		const currentMonth = [...rangeDaysCopy[monthIndex]];
		const currentDay = { ...currentMonth[dayIndex] };
		if (
			!currentDay.dataArr.length ||
			(currentDay.dataArr[currentDay.dataArr.length - 1].startTime &&
				currentDay.dataArr[currentDay.dataArr.length - 1].endTime)
		) {
			currentDay.dataArr.push({
				webinarDate: "",
				startTime: "",
				endTime: "",
			});
		}
		currentMonth[dayIndex] = currentDay;
		rangeDaysCopy[monthIndex] = currentMonth;
		setRangeDays(rangeDaysCopy);
	};

	const removeStartTimeEndTimeFromCurrentWeekDayCustomWebinar = (
		monthIndex,
		dayIndex,
	) => {
		const rangeDaysCopy = [...rangeDays];
		const currentMonth = [...rangeDaysCopy[monthIndex]];
		const currentDay = { ...currentMonth[dayIndex] };
		currentDay.dataArr.splice(-1);
		currentMonth[dayIndex] = currentDay;
		rangeDaysCopy[monthIndex] = currentMonth;
		setRangeDays(rangeDaysCopy);
	};

	const removeStartTimeEndTimeFromCurrentWeekDay = index => {
		const weekDaysCopy = [...weekDays];
		const currentWeekDay = { ...weekDaysCopy[index] };
		if (currentWeekDay.days.length > 1) {
			currentWeekDay.days.splice(-1);
			weekDaysCopy[index] = currentWeekDay;
			setWeekDays(weekDaysCopy);
		} else {
			return;
		}
	};

	const onChangeWeekDayStatus = index => {
		setIsInvalidWeekDays(false);
		const weekDaysCopy = [...weekDays];
		const webinarDayCopy = { ...webinarDay };
		const currentWeekDay = { ...weekDaysCopy[index] };
		if (!currentWeekDay.active) {
			currentWeekDay.active = true;
			webinarDayCopy.webinarDay = index;
			currentWeekDay.days.push(webinarDayCopy);
		} else {
			currentWeekDay.days = [];
			currentWeekDay.active = false;
		}
		weekDaysCopy[index] = currentWeekDay;
		setWeekDays(weekDaysCopy);
	};

	const compareTimePeriodicWebinar = (index, fieldIndex) => {
		const weekDaysCopy = [...weekDays];
		const currendDay = { ...weekDaysCopy[index] };
		const currentField = currendDay.days[fieldIndex];
		if (
			currentField.startTime &&
			currentField.endTime &&
			currendDay.days.length > 1
		) {
			currendDay.days.forEach(day => {
				if (
					(day.startTime <= currentField.endTime &&
						day.endTime >= currentField.endTime &&
						day !== currentField) ||
					(day.startTime <= currentField.startTime &&
						day.endTime >= currentField.startTime &&
						day !== currentField) ||
					(day.startTime >= currentField.startTime &&
						day.endTime <= currentField.endTime &&
						day !== currentField) ||
					(day.startTime <= currentField.startTime &&
						day.endTime >= currentField.endTime &&
						day !== currentField)
				) {
					AlertService.alert(
						WARNING_KEY,
						translationService.translate("TR_TIME_CONFLICT"),
					);
					currentField.startTime = "";
					currentField.endTime = "";
				}
			});
		}
		currendDay.days[fieldIndex] = currentField;
		weekDaysCopy[index] = currendDay;
		setWeekDays(weekDaysCopy);
	};

	const changeDateOfTheWeek = (event, index, fieldIndex, name) => {
		setIsInvalidWeekDays(false);
		const weekDaysCopy = [...weekDays];
		const currendDay = { ...weekDaysCopy[index] };
		const currentField = currendDay.days[fieldIndex];
		currentField[name] = event.target.value;
		currendDay.days[fieldIndex] = currentField;
		weekDaysCopy[index] = currendDay;
		setWeekDays(weekDaysCopy);
	};

	const changeDateOfTheWeekCustomWebinar = (
		event,
		monthIndex,
		index,
		fieldIndex,
		name,
		monthItem,
	) => {
		setIsInvalidWeekDays(false);
		const webinarDate = `${monthItem.year}-${monthItem.month + 1}-${monthItem.day
			}`;
		const rangeDaysCopy = [...rangeDays];
		const currentMonth = [...rangeDaysCopy[monthIndex]];
		const currentRangeDay = { ...currentMonth[index] };
		const currentField = currentRangeDay.dataArr[fieldIndex];
		currentField[name] = event.target.value;
		currentField.webinarDate = webinarDate;
		currentRangeDay.dataArr[fieldIndex] = currentField;
		currentMonth[index] = currentRangeDay;
		rangeDaysCopy[monthIndex] = currentMonth;
		setRangeDays(rangeDaysCopy);
	};

	const compareTimeCustomWebinar = (
		monthIndex,
		index,
		fieldIndex,
		monthItem,
	) => {
		const webinarDate = `${monthItem.year}-${monthItem.month + 1}-${monthItem.day
			}`;
		const rangeDaysCopy = [...rangeDays];
		const currentMonth = [...rangeDaysCopy[monthIndex]];
		const currentRangeDay = { ...currentMonth[index] };
		const currentField = currentRangeDay.dataArr[fieldIndex];

		if (
			currentField.startTime &&
			currentField.endTime &&
			currentRangeDay.dataArr.length > 1
		) {
			currentRangeDay.dataArr.forEach(day => {
				if (
					(day.startTime <= currentField.endTime &&
						day.endTime >= currentField.endTime &&
						day !== currentField) ||
					(day.startTime <= currentField.startTime &&
						day.endTime >= currentField.startTime &&
						day !== currentField) ||
					(day.startTime >= currentField.startTime &&
						day.endTime <= currentField.endTime &&
						day !== currentField) ||
					(day.startTime <= currentField.startTime &&
						day.endTime >= currentField.endTime &&
						day !== currentField)
				) {
					AlertService.alert(
						WARNING_KEY,
						translationService.translate("TR_TIME_CONFLICT"),
					);
					currentField.startTime = "";
					currentField.endTime = "";
				}
			});
		}
		currentField.webinarDate = webinarDate;
		currentRangeDay.dataArr[fieldIndex] = currentField;
		currentMonth[index] = currentRangeDay;
		rangeDaysCopy[monthIndex] = currentMonth;
		setRangeDays(rangeDaysCopy);
	};

	useEffect(() => {
		if (divisionId && lecturerId) {
			if (isShowSingleWebinar && webinarDate) {
				checkWebinarDays();
			}
			if (
				(isShowPeriodicWebinar || isShowCustomWebinar) &&
				startDate &&
				endDate
			) {
				checkWebinarDays();
			}
		}
	}, [
		divisionId,
		lecturerId,
		startDate,
		endDate,
		webinarDate,
		isShowSingleWebinar,
		isShowPeriodicWebinar,
		isShowCustomWebinar,
	]);

	const invertNumber = number => {
		if (typeof number !== "number") {
			return NaN;
		}
		if (number < 0) {
			return Math.abs(number); // If the number is negative, return its absolute value
		} else if (number > 0) {
			return -number; // If the number is positive, return its negative
		} else {
			return 0; // If the number is 0, return 0
		}
	};

	const checkWebinarDays = () => {
		let url = "b2BWebinar/";
		if (isShowSingleWebinar) {
			url = url + "GetOccupiedSingleWebinars";
		} else if (isShowPeriodicWebinar) {
			url = url + "GetOccupiedPeriodicWeibars";
		} else if (isShowCustomWebinar) {
			url = url + "GetOccupiedCustomWebinars";
		}

		const data = {
			startDate: startDate ? startDate : webinarDate,
			endDate: endDate ? endDate : null,
			divisionId: divisionId,
			lecturerUserId: lecturerId,
			timeZoneOffSet: invertNumber(new Date().getTimezoneOffset() / 60),
		};

		ApiService.checkWebinarDays(url, data)
			.then(response => {
				if (response && response.data) {
					setOccupiedDates(response.data);
				} else {
					setOccupiedDates(null);
				}
			})
			.catch(error => getFail(error));
	};

	const getPeriodicGroupWebinarDeys = dey => {
		if (occupiedDates && occupiedDates.divisionOccupiedWebinars) {
			const periodicWebinarDeys = Object.entries(
				occupiedDates.divisionOccupiedWebinars,
			);
			if (periodicWebinarDeys.length) {
				const periodicDey = periodicWebinarDeys.find(([key, value]) => {
					return +key === +dey;
				});
				if (periodicDey && periodicDey[1].length) {
					return periodicDey[1].map((__item, i) => {
						return (
							<div
								key={i}
								className="py-1 px-2 text-warning"
								style={{ fontSize: "14px" }}>
								<hr style={{ marginBottom: "4px" }} />
								{translationService.translate("TR_CURRENT_GROUP_IS_BUSY")}{" "}
								&nbsp;
								{__item.occupiedTimes && __item.occupiedTimes.length
									? __item.occupiedTimes.map((_item, idx) => {
										return (
											<div key={idx}>
												<span>
													{moment(_item.startTime).format(
														"DD-MM-YYYY (HH:mm)",
													)}
												</span>
												&nbsp; - &nbsp;
												<span>
													{moment(_item.endTime).format("DD-MM-YYYY (HH:mm)")}
												</span>
											</div>
										);
									})
									: ""}
							</div>
						);
					});
				}
				return "";
			}
			return "";
		}
		return "";
	};

	const getPeriodicLecturerWebinarDays = dey => {
		if (occupiedDates && occupiedDates.lecturerOccupiedWebinars) {
			const periodicWebinarDeys = Object.entries(
				occupiedDates.lecturerOccupiedWebinars,
			);
			if (periodicWebinarDeys.length) {
				const periodicDey = periodicWebinarDeys.find(([key, value]) => {
					return +key === +dey;
				});
				if (periodicDey && periodicDey[1].length) {
					return periodicDey[1].map((__item, i) => {
						return (
							<div
								key={i}
								className="py-1 px-2 text-red"
								style={{ fontSize: "14px" }}>
								<hr style={{ marginBottom: "4px" }} />
								{translationService.translate(
									"TR_CURRENT_LECTURER_IS_BUSY",
								)}{" "}
								&nbsp;
								{__item.occupiedTimes && __item.occupiedTimes.length
									? __item.occupiedTimes.map((_item, idx) => {
										return (
											<div key={idx}>
												<span>
													{moment(_item.startTime).format(
														"DD-MM-YYYY (HH:mm)",
													)}
												</span>
												&nbsp; - &nbsp;
												<span>
													{moment(_item.endTime).format("DD-MM-YYYY (HH:mm)")}
												</span>
											</div>
										);
									})
									: ""}
							</div>
						);
					});
				}
				return "";
			}
			return "";
		}
		return "";
	};

	const getGetGroupOccupiedDays = monthItem => {
		if (occupiedDates && occupiedDates.divisionOccupiedWebinars) {
			return Object.entries(occupiedDates.divisionOccupiedWebinars).length
				? Object.entries(occupiedDates.divisionOccupiedWebinars).map(item => {
					return moment(item[0]).format("ll") ===
						moment(
							`${monthItem.year}-${monthItem.month + 1}-${monthItem.day}`,
						).format("ll")
						? item[1] && item[1].length
							? item[1].map((__item, i) => {
								return (
									<div
										key={i}
										className="py-1 px-2 text-warning"
										style={{ fontSize: "14px" }}>
										<hr style={{ marginBottom: "4px" }} />
										{translationService.translate(
											"TR_CURRENT_LECTURER_IS_BUSY",
										)}{" "}
										&nbsp;
										{__item.occupiedTimes && __item.occupiedTimes.length
											? __item.occupiedTimes.map((_item, idx) => {
												return (
													<div key={idx}>
														<span>
															{moment(_item.startTime).format("HH:mm")}
														</span>
														-
														<span>
															{moment(_item.endTime).format("HH:mm")}
														</span>
													</div>
												);
											})
											: ""}
									</div>
								);
							})
							: ""
						: "";
				})
				: "";
		} else if (occupiedDates && occupiedDates.divisionOccupiedWebinars) {
			return Object.entries(occupiedDates.divisionOccupiedWebinars).length
				? Object.entries(occupiedDates.divisionOccupiedWebinars).map(item => {
					return moment(item[0]).format("ll") ===
						moment(
							`${monthItem.year}-${monthItem.month + 1}-${monthItem.day}`,
						).format("ll")
						? item[1] && item[1].length
							? item[1].map((__item, i) => {
								return (
									<div
										className="py-1 px-2 text-warning"
										style={{ fontSize: "14px" }}>
										<hr style={{ marginBottom: "4px" }} />
										Current group has a webinar scheduled &nbsp;
										{__item.occupiedTimes && __item.occupiedTimes.length
											? __item.occupiedTimes.map((_item, idx) => {
												return (
													<div key={idx}>
														<span>
															{moment(_item.startTime).format("HH:mm")}
														</span>
														-
														<span>
															{moment(_item.endTime).format("HH:mm")}
														</span>
													</div>
												);
											})
											: ""}
									</div>
								);
							})
							: ""
						: "";
				})
				: "";
		} else {
			return "";
		}
	};

	const getGetOccupiedDays = monthItem => {
		if (occupiedDates && occupiedDates.lecturerOccupiedWebinars) {
			return Object.entries(occupiedDates.lecturerOccupiedWebinars).length
				? Object.entries(occupiedDates.lecturerOccupiedWebinars).map(item => {
					return moment(item[0]).format("ll") ===
						moment(
							`${monthItem.year}-${monthItem.month + 1}-${monthItem.day}`,
						).format("ll")
						? item[1] && item[1].length
							? item[1].map((__item, i) => {
								return (
									<div
										key={i}
										className="py-1 px-2 text-red"
										style={{ fontSize: "14px" }}>
										<hr style={{ marginBottom: "4px" }} />
										{translationService.translate(
											"TR_CURRENT_LECTURER_IS_BUSY",
										)}{" "}
										&nbsp;
										{__item.occupiedTimes && __item.occupiedTimes.length
											? __item.occupiedTimes.map((_item, idx) => {
												return (
													<div key={idx}>
														<span>
															{moment(_item.startTime).format("HH:mm")}
														</span>
														-
														<span>
															{moment(_item.endTime).format("HH:mm")}
														</span>
													</div>
												);
											})
											: ""}
									</div>
								);
							})
							: ""
						: "";
				})
				: "";
		} else if (occupiedDates && occupiedDates.divisionOccupiedWebinars) {
			return Object.entries(occupiedDates.divisionOccupiedWebinars).length
				? Object.entries(occupiedDates.divisionOccupiedWebinars).map(item => {
					return moment(item[0]).format("ll") ===
						moment(
							`${monthItem.year}-${monthItem.month + 1}-${monthItem.day}`,
						).format("ll")
						? item[1] && item[1].length
							? item[1].map((__item, i) => {
								return (
									<div
										className="py-1 px-2 text-warning"
										style={{ fontSize: "14px" }}>
										<hr style={{ marginBottom: "4px" }} />
										Current group has a webinar scheduled &nbsp;
										{__item.occupiedTimes && __item.occupiedTimes.length
											? __item.occupiedTimes.map((_item, idx) => {
												return (
													<div key={idx}>
														<span>
															{moment(_item.startTime).format("HH:mm")}
														</span>
														-
														<span>
															{moment(_item.endTime).format("HH:mm")}
														</span>
													</div>
												);
											})
											: ""}
									</div>
								);
							})
							: ""
						: "";
				})
				: "";
		} else {
			return "";
		}
	};

	//webinars
	const showSingleWebinar = () => {
		setPeriodicity(1);
		setIsInvalidTime(false);
		setIsInvalidDate(false);
		setIsInvalidWebinar(false);
		setIsShowSingleWebinar(true);
		setIsShowPeriodicWebinar(false);
		setIsShowCustomWebinar(false);
		setEndDate("");
		setStartDate("");
		setWebinarDate("");
	};

	const showPeriodicWebinar = () => {
		setPeriodicity(2);
		setIsInvalidTime(false);
		setIsInvalidDate(false);
		setIsInvalidWebinar(false);
		setIsShowPeriodicWebinar(true);
		setIsShowSingleWebinar(false);
		setIsShowCustomWebinar(false);
		setEndDate("");
		setStartDate("");
		setWebinarDate("");
	};

	const showCustomWebinar = () => {
		setPeriodicity(3);
		setIsInvalidTime(false);
		setIsInvalidDate(false);
		setIsInvalidWebinar(false);
		setIsShowCustomWebinar(true);
		setIsShowSingleWebinar(false);
		setIsShowPeriodicWebinar(false);
		setEndDate("");
		setStartDate("");
		setWebinarDate("");
	};
	//

	const getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(props.location.pathname)) {
			dispatch(
				getCurrentQuestionMarkData(
					HelpService.setPageName(props.location.pathname),
					key,
				),
			);
		}
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!name.trim() || !divisionId || !disciplineId || !lecturerId) {
			setIsInvalidSubmit(true);
			MainService.scrollToInvalidFieldPosition();
			return false;
		}
		if (
			!isShowSingleWebinar &&
			!isShowPeriodicWebinar &&
			!isShowCustomWebinar &&
			!webinarId
		) {
			setIsInvalidWebinar(true);
			MainService.scrollToInvalidFieldPosition();
			return false;
		}
		const form = {
			divisionId,
			subjectId: disciplineId,
			lecturerUserId: lecturerId,
			image: file,
			name: name.trim(),
			description: description.trim(),
			periodicity: +periodicity,
			timeZoneOffset: new Date().getTimezoneOffset() / 60,
		};
		if (!isInvalidWebinar && !isInvalidSubmit && !isInvalidDate) {
			if (!webinarId) {
				if (isShowSingleWebinar) {
					if (!webinarDate || !startTime || !endTime || isInvalidTime) {
						setIsInvalidDate(true);
						return;
					} else {
						const days = [
							{
								webinarDate,
								startTime,
								endTime,
							},
						];
						form.days = JSON.stringify(days);
					}
				}
				if (isShowPeriodicWebinar) {
					if (!startDate || !endDate) {
						setIsInvalidDate(true);
						return;
					}
					const dateCompaire = MainService.checkDates(startDate, endDate);
					if (!dateCompaire) {
						setIsInvalidDate(true);
						return;
					}
					const isEmptyAllWeekDays = weekDays.find(
						weekDay =>
							weekDay.days.length &&
							weekDay.days[0].startTime &&
							weekDay.days[0].endTime,
					);
					if (!isEmptyAllWeekDays) {
						setIsInvalidWeekDays(true);
						return;
					}
					if (startDate && endDate && isEmptyAllWeekDays) {
						const days = [];
						weekDays.forEach(webinarDay => {
							if (webinarDay.days && webinarDay.days.length) {
								webinarDay.days?.forEach(webinarDayItem => {
									if (webinarDayItem.startTime && webinarDayItem.endTime) {
										days.push(webinarDayItem);
									}
								});
							}
						});
						form.days = JSON.stringify(days);
						form.startDate = startDate;
						form.endDate = endDate;
					}
				}
				if (isShowCustomWebinar) {
					const dateCompaire = MainService.checkDates(startDate, endDate);
					if (!startDate || !endDate || !dateCompaire || !rangeDays.length) {
						setIsInvalidDate(true);
						MainService.scrollToInvalidFieldPosition();
						return;
					}
					if (rangeDays.length) {
						const allMonths = [];
						rangeDays.forEach(rangeDay => {
							if (rangeDay.length) {
								rangeDay.forEach(rangeDayItem => {
									allMonths.push(rangeDayItem);
								});
							}
						});
						const isEmptyAllWeekDays = allMonths.find(
							weekDay =>
								weekDay.dataArr.length &&
								weekDay.dataArr[0].startTime &&
								weekDay.dataArr[0].endTime,
						);
						if (!isEmptyAllWeekDays) {
							setIsInvalidWeekDays(true);
							return;
						} else {
							const days = [];
							allMonths.forEach(rangeDay => {
								rangeDay.dataArr?.forEach(data => {
									if (data.startTime && data.endTime && data.webinarDate) {
										days.push(data);
									}
								});
							});
							form.days = JSON.stringify(days);
							form.startDate = startDate;
							form.endDate = endDate;
						}
					}
				}
			} else {
				form.id = webinarId;
			}
			const formData = new FormData();
			for (let i in form) {
				!form[i] ? delete form[i] : formData.append(`${i}`, form[i]);
			}
			formData.append("areMessagesByConfirmation", areMessagesByConfirmation);
			formData.append("restrictMessages", restrictMessages);
			dispatch(addButtonSpinner(spinnerId));
			(webinarId
				? ApiService.updateOrgWebinar(formData)
				: ApiService.createOrgWebinar(formData)
			)
				.then(() => {
					!webinarId
						? AlertService.alert(
							SUCCESS_KEY,
							translationService.translate("TR_WEBINAR_SUCCESSFULLY_CREATED"),
						)
						: AlertService.alert(
							SUCCESS_KEY,
							translationService.translate("TR_WEBINAR_SUCCESSFULLY_UPDATED"),
						);
					dispatch(removeButtonSpinner(spinnerId));
					props.history.push(`/${language}/${WEBINAR_KEY}`);
				})
				.catch(error => getFail(error, spinnerId));
		}
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<div className="row">
				<div className="col-12 content-title">
					<div className="d-flex align-items-center">
						<Link
							to={`/${language}/${WEBINAR_KEY}`}
							title={translationService.translate("TR_BACK")}>
							<ArrowBackSvg />
						</Link>
						<h2 className="title">
							{webinarId
								? translationService.translate("TR_UPDATE_WEBINAR")
								: translationService.translate("TR_CREATE_WEBINAR")}
						</h2>
					</div>
				</div>
			</div>
			<hr />
			{groups && groups.length ? (
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-12">
							<div className="row">
								<div className="col-12">
									<label className="mb-2">{`${translationService.translate(
										"TR_GROUPS",
									)} *`}</label>
								</div>
								{groups.map((_item, index) => {
									return (
										<div key={index} className="col-lg-6">
											<div
												className={`border rounded shedow p-3 mt-2 ${isInvalidSubmit && selectedId ? "error-bordered" : ""
													}`}>
												{_item.map((item, idx) => {
													return (
														<div key={idx}>
															<div className="d-flex align-items-center mb-2 justify-content-between">
																<p
																	className="mr-3 fw-500 max-line-3"
																	style={{ fontWeight: "500" }}
																	title={item.name}>
																	{item.name}
																</p>
																<div className="d-flex align-items-center">
																	{!item.isStudentDivision &&
																		selectedId !== item.id ? (
																		<button
																			type="button"
																			className="mindalay--btn-small mindalay--btn-blue mindalay--btn mr-2 text-nowrap"
																			onClick={() => {
																				getOwnerDivisionsForWebinars(
																					item.id,
																					index,
																				);
																			}}>
																			{translationService.translate(
																				"TR_GET_CHILD_DIVISIONS",
																			)}
																		</button>
																	) : null}
																	<button
																		type="button"
																		className={`mindalay--btn-small mindalay--btn-secondary${selectedDivision &&
																			selectedDivision.id === item.id
																			? ""
																			: "-outline"
																			} mindalay--btn text-nowrap`}
																		onClick={() => {
																			selecteDivision(item);
																		}}>
																		{translationService.translate("TR_SELECT")}
																	</button>
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									);
								})}
							</div>
							{selectedDivision && selectedDivision.educationalUnits ? (
								<div className="row mt-3">
									<div className="col-md-4 col-6 my-1">
										<label className="mb-2">
											{translationService.translate("TR_EDUCATIONAL_UNITS")} *
										</label>
										<ReactSelectOption
											value={selectedEducationalUnit?.id}
											isInvalidSubmit={isInvalidSubmit}
											selectedValue={(() => {
												const selectedItem = {
													...selectedDivision.educationalUnits.find(
														data => data.id === selectedEducationalUnit?.id,
													),
												};
												if (Object.keys(selectedItem).length) {
													selectedItem.label = selectedItem.name;
													selectedItem.value = selectedItem.id;
													return selectedItem;
												} else {
													return {
														label: translationService.translate("TR_CHOOSE"),
														value: "",
													};
												}
											})()}
											items={selectedDivision.educationalUnits.map(data => ({
												id: data.id,
												label: data.name,
												value: data.id,
												subjects: data.subjects,
											}))}
											onChange={unit => {
												setIsInvalidSubmit(false);
												setSelectedEducationalUnit(unit);
											}}
										/>
									</div>
									{selectedEducationalUnit &&
										selectedEducationalUnit.subjects ? (
										<div className="col-md-4 col-6 my-1">
											<label className="mb-2">
												{translationService.translate("TR_SUBJECTS")} *
											</label>
											<ReactSelectOption
												value={selectedSubject?.id || disciplineId}
												isInvalidSubmit={isInvalidSubmit}
												selectedValue={(() => {
													const selectedItem = {
														...selectedEducationalUnit.subjects.find(
															data =>
																data.id === selectedSubject?.id ||
																data.id === disciplineId,
														),
													};
													if (Object.keys(selectedItem).length) {
														selectedItem.label = selectedItem.name;
														selectedItem.value = selectedItem.id;
														return selectedItem;
													} else {
														return {
															label: translationService.translate("TR_CHOOSE"),
															value: "",
														};
													}
												})()}
												items={selectedEducationalUnit.subjects.map(data => ({
													id: data.id,
													label: data.name,
													value: data.id,
													lecturers: data.lecturers,
												}))}
												onChange={subject => {
													setIsInvalidSubmit(false);
													setSelectedSubject(subject);
													setDisciplineId(subject.id);
												}}
											/>
										</div>
									) : null}
									{selectedSubject && selectedSubject.lecturers ? (
										<div className="col-md-4 col-6 my-1">
											<label>{`${translationService.translate(
												"TR_LECTURERS",
											)} *`}</label>
											<ReactSelectOption
												value={lecturerId}
												isInvalidSubmit={isInvalidSubmit}
												selectedValue={(() => {
													const selectedItem = {
														...selectedSubject.lecturers.find(
															data => data.id === lecturerId,
														),
													};
													if (Object.keys(selectedItem).length) {
														selectedItem.label = selectedItem.lecturerName;
														selectedItem.value = selectedItem.id;
														return selectedItem;
													} else {
														return {
															label: translationService.translate("TR_CHOOSE"),
															value: "",
														};
													}
												})()}
												items={selectedSubject.lecturers.map(data => ({
													id: data.id,
													label: data.lecturerName,
													value: data.id,
												}))}
												onChange={lecturer => {
													setIsInvalidSubmit(false);
													setLecturerId(lecturer.id);
												}}
											/>
										</div>
									) : null}
								</div>
							) : null}

							<hr />

							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
									<div className="course-media-wrapper">
										<span className="d-block my-2">
											{translationService.translate("TR_UPLOADAVATAR")}
										</span>
										{uploadedFile ? (
											<div className="upload-file-content">
												{currentUploadedImage ? (
													<div onClick={removeImg}>
														<CloseSvg />
													</div>
												) : null}
												<div
													className="img"
													style={{ backgroundImage: `url(${uploadedFile})` }}
													alt="course-image"></div>
											</div>
										) : null}
									</div>
									<div className="course-media-wrapper">
										<div className="form-group position-relative">
											<UploadButton
												textInputClasses="pl-100"
												text={translationService.translate("TR_UPLOAD_FILE")}
												clicked={uploadFile}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<div className="col-12 d-flex">
									<InputCheckBox
										id="areMessagesByConfirmation"
										name="areMessagesByConfirmation"
										checked={areMessagesByConfirmation}
										isInvalidField={isInvalidWebinar ? true : false}
										labelValue={translationService.translate(
											"TR_MESSAGES_BY_CONFIRMATION",
										)}
										onChange={event =>
											setAreMessagesByConfirmation(event.target.checked)
										}
									/>
									<div className="d-inline">
										<SmallQuestionMark
											id="???"
											blockClassName="ml-1"
											onMouseEnter={() => getQuestionMarkData("???")}
										/>
									</div>
								</div>
								<div className="col-12 d-flex">
									<InputCheckBox
										id="restrictMessages"
										name="restrictMessages"
										checked={restrictMessages}
										isInvalidField={isInvalidWebinar ? true : false}
										labelValue={translationService.translate(
											"TR_RESTRICT_MESSAGES",
										)}
										onChange={event =>
											setRestrictMessages(event.target.checked)
										}
									/>
									<div className="d-inline">
										<SmallQuestionMark
											id="???"
											blockClassName="ml-1"
											onMouseEnter={() => getQuestionMarkData("???")}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-12 my-1">
									<Input
										type="text"
										id="name"
										name="name"
										inputClassName="pr--5"
										value={name}
										fieldLength={nameMaxLength}
										isInvalidSubmit={isInvalidSubmit}
										isInvalidField={isInvalidSubmit && !name.trim()}
										labelValue={`${translationService.translate("TR_NAME")} *`}
										onChange={event => onChange(event, nameMaxLength, setName)}
										failedFields={failedFields}
									/>
								</div>
								<div className="col-12">
									<Textarea
										rows="3"
										id="description"
										name="description"
										value={description}
										max={descriptionMaxLength}
										textAreaClassName="pr--5"
										labelValue={`${translationService.translate(
											"TR_DESCRIPTION",
										)}`}
										onChange={event =>
											onChange(event, descriptionMaxLength, setDescription)
										}
										failedFields={failedFields}
									/>
								</div>
							</div>
							{!webinarId ? (
								<Auxiliary>
									<div className="row">
										<div className="col-12 d-flex">
											<InputCheckBox
												id="isShowSingleWebinar"
												name="isShowSingleWebinar"
												checked={isShowSingleWebinar}
												isInvalidField={isInvalidWebinar ? true : false}
												labelValue={translationService.translate(
													"TR_SINGLE_WEBINAR",
												)}
												onChange={showSingleWebinar}
											/>
											<div className="d-inline">
												<SmallQuestionMark
													id="single_webinar"
													blockClassName="ml-1"
													onMouseEnter={() =>
														getQuestionMarkData("single_webinar")
													}
												/>
											</div>
										</div>
										<div className="col-12 d-flex">
											<InputCheckBox
												id="isShowPeriodicWebinar"
												name="isShowPeriodicWebinar"
												checked={isShowPeriodicWebinar}
												isInvalidField={isInvalidWebinar ? true : false}
												labelValue={translationService.translate(
													"TR_PERIODIC_WEBINAR",
												)}
												onChange={showPeriodicWebinar}
											/>
											<div className="d-inline">
												<SmallQuestionMark
													id="periodic_webinar"
													blockClassName="ml-1"
													onMouseEnter={() =>
														getQuestionMarkData("periodic_webinar")
													}
												/>
											</div>
										</div>
										<div className="col-12 d-flex">
											<InputCheckBox
												id="isShowCustomWebinar"
												name="isShowCustomWebinar"
												checked={isShowCustomWebinar}
												isInvalidField={isInvalidWebinar ? true : false}
												labelValue={translationService.translate(
													"TR_CUSTOM_WEBINAR",
												)}
												onChange={showCustomWebinar}
											/>
											<div className="d-inline">
												<SmallQuestionMark
													id="custom_webinar"
													blockClassName="ml-1"
													onMouseEnter={() =>
														getQuestionMarkData("custom_webinar")
													}
												/>
											</div>
										</div>
									</div>
									<hr />
									{isShowSingleWebinar ? (
										<div className="row">
											<div className="col-12">
												<div className=" mt-3">
													<div>
														<div className="col-md-4 col-6 my-1 pl-0">
															<Input
																type="date"
																id="webinarDate"
																name="webinarDate"
																blockClassName="mx-1"
																min={`${new Date().toISOString().split("T")[0]
																	}`}
																inputClassName={`mr-2 ${isInvalidDate && !webinarDate
																	? "is-invalid error-bordered"
																	: ""
																	}`}
																labelValue={`${translationService.translate(
																	"TR_WEBINAR_DATE",
																)} *`}
																value={webinarDate}
																onChange={event =>
																	onDateChange(event, setWebinarDate)
																}
																onBlur={() =>
																	checkDateFormatAndIsValid(webinarDate)
																}
															/>
														</div>
														<div className="col-md-4 col-6 my-1 pl-0">
															<Input
																type="time"
																id="startTime"
																name="startTime"
																blockClassName="mx-1"
																inputClassName={`mr-2 ${isInvalidDate && !startTime
																	? "is-invalid error-bordered"
																	: ""
																	}`}
																labelValue={`${translationService.translate(
																	"TR_START_TIME",
																)} *`}
																value={startTime}
																onChange={event =>
																	onTiemChange(event, setStartTime)
																}
																onBlur={() => checkOnlyTime(startTime, endTime)}
															/>
														</div>
														<div className="col-md-4 col-6 my-1 pl-0">
															<Input
																type="time"
																id="endTime"
																name="endTime"
																blockClassName="mx-1"
																inputClassName={`mr-2 ${isInvalidDate && !endTime
																	? "is-invalid error-bordered"
																	: ""
																	}`}
																labelValue={`${translationService.translate(
																	"TR_END_TIME",
																)} *`}
																value={endTime}
																onChange={event =>
																	onTiemChange(event, setEndTime)
																}
																onBlur={() => checkOnlyTime(startTime, endTime)}
															/>
														</div>
														{isShowSingleWebinar && occupiedDates ? (
															<div>
																{occupiedDates.divisionOccupiedWebinars &&
																	occupiedDates.divisionOccupiedWebinars
																		.length ? (
																	<div
																		className="text-warning"
																		style={{ fontSize: "14px" }}>
																		{translationService.translate("TR_FOR")}{" "}
																		&nbsp;
																		{moment(webinarDate).format("ll")}, &nbsp;
																		the current group is busy &nbsp;
																		{occupiedDates.divisionOccupiedWebinars.map(
																			(item, index) => {
																				return (
																					<span key={index}>
																						{item.occupiedTimes &&
																							item.occupiedTimes.length
																							? item.occupiedTimes.map(
																								(_item, _index) => {
																									return (
																										<span key={index}>
																											{translationService.translate(
																												"TR_FROM",
																											)}
																											&nbsp;
																											{moment(
																												_item.startTime,
																											).format("HH:mm")}
																											&nbsp; - &nbsp;
																											{moment(
																												_item.endTime,
																											).format("HH:mm")}
																											, &nbsp;
																										</span>
																									);
																								},
																							)
																							: null}
																					</span>
																				);
																			},
																		)}
																	</div>
																) : (
																	""
																)}
																{occupiedDates.lecturerOccupiedWebinars &&
																	occupiedDates.lecturerOccupiedWebinars
																		.length ? (
																	<div
																		className="text-red"
																		style={{ fontSize: "14px" }}>
																		{translationService.translate("TR_FOR")}{" "}
																		&nbsp;
																		{moment(webinarDate).format("ll")}, &nbsp;
																		the current lecturer is busy &nbsp;
																		{occupiedDates.lecturerOccupiedWebinars.map(
																			(item, index) => {
																				return (
																					<span key={index}>
																						{item.occupiedTimes &&
																							item.occupiedTimes.length
																							? item.occupiedTimes.map(
																								(_item, _index) => {
																									return (
																										<span key={index}>
																											{translationService.translate(
																												"TR_FROM",
																											)}{" "}
																											&nbsp;
																											{moment(
																												_item.startTime,
																											).format("HH:mm")}
																											&nbsp; - &nbsp;
																											{moment(
																												_item.endTime,
																											).format("HH:mm")}
																											, &nbsp;
																										</span>
																									);
																								},
																							)
																							: null}
																					</span>
																				);
																			},
																		)}
																	</div>
																) : null}
															</div>
														) : null}
														{/* {
                                  isInvalidTime ?
                                    <small className="red-color">The time you entered is not valid, the start time of the webinar must be less than the end time.</small>
                                    : null
                                } */}
														{isStartTimeMoreThanEndTime ? (
															<small className="mx-1">
																{translationService.translate(
																	"TR_START_TIME_LESS_THEN_END_TIME_INFO",
																)}
															</small>
														) : null}
													</div>
												</div>
											</div>
										</div>
									) : null}
									{isShowPeriodicWebinar ? (
										<div className="row">
											<div className="w-100 pl-0">
												<div className="col-md-6 col-12 my-1">
													<Input
														type="date"
														id="startDate"
														name="startDate"
														min={`${new Date().toISOString().split("T")[0]}`}
														max={endDate ? endDate : ""}
														blockClassName="mx-1"
														inputClassName={`mr-2 ${isInvalidDate ? "is-invalid error-bordered" : ""
															}`}
														labelValue={`${translationService.translate(
															"TR_START_DATE",
														)} *`}
														value={startDate}
														onChange={event =>
															onDateChange(event, setStartDate)
														}
														onBlur={() => checkStartEndDateIsValid(startDate)}
													/>
												</div>
												<div className="col-md-6 col-12 my-1">
													<Input
														type="date"
														id="endDate"
														name="endDate"
														blockClassName="mx-1"
														min={`${startDate
															? startDate
															: new Date().toISOString().split("T")[0]
															}`}
														inputClassName={`mr-2 ${isInvalidDate ? "is-invalid error-bordered" : ""
															}`}
														labelValue={`${translationService.translate(
															"TR_END_DATE",
														)} *`}
														value={endDate}
														onChange={event => onDateChange(event, setEndDate)}
														onBlur={() => checkStartEndDateIsValid(endDate)}
													/>
												</div>
											</div>
											{weekDays.map((weekDay, index) => {
												return (
													<div
														key={index}
														className="col-md-4 col-sm-6 col-12 my-1">
														<div
															className={`day-of-the-week-block day-of-the-week-block-item ${isInvalidWeekDays
																? "is-invalid error-bordered"
																: ""
																}`}>
															<div className="title-block">
																<InputCheckBox
																	id={index}
																	blockClassName="my-2"
																	checked={weekDay.active}
																	labelValue={translationService.translate(
																		weekDay.name,
																	)}
																	onChange={() => onChangeWeekDayStatus(index)}
																/>
																{weekDay.active ? (
																	<div className="plus-minus-block">
																		<i
																			className="fas fa-plus-square"
																			onClick={() =>
																				addStartTimeEndTimeToCurrentWeekDayPeriodicWebinar(
																					index,
																				)
																			}></i>
																		<i
																			className="fas fa-minus-square"
																			onClick={() =>
																				removeStartTimeEndTimeFromCurrentWeekDay(
																					index,
																				)
																			}></i>
																	</div>
																) : null}
															</div>

															<div className="week-days">
																{weekDay.days.map((day, dayIndex) => {
																	return (
																		<div key={dayIndex} className="d-flex">
																			<Input
																				type="time"
																				id="startTime"
																				name="startTime"
																				value={day.startTime}
																				blockClassName="m-2 flex-1"
																				labelValue={translationService.translate(
																					"TR_S_T",
																				)}
																				onChange={event =>
																					changeDateOfTheWeek(
																						event,
																						index,
																						dayIndex,
																						"startTime",
																					)
																				}
																				onBlur={() =>
																					compareTimePeriodicWebinar(
																						index,
																						dayIndex,
																					)
																				}
																			/>
																			<Input
																				type="time"
																				id="endTime"
																				name="endTime"
																				value={day.endTime}
																				blockClassName="m-2 flex-1"
																				labelValue={translationService.translate(
																					"TR_E_T",
																				)}
																				onChange={event =>
																					changeDateOfTheWeek(
																						event,
																						index,
																						dayIndex,
																						"endTime",
																					)
																				}
																				onBlur={() =>
																					compareTimePeriodicWebinar(
																						index,
																						dayIndex,
																					)
																				}
																			/>
																		</div>
																	);
																})}
															</div>
															{getPeriodicGroupWebinarDeys(index)}
															{getPeriodicLecturerWebinarDays(index)}
														</div>
													</div>
												);
											})}
										</div>
									) : null}
									{isShowCustomWebinar ? (
										<div className="row">
											<div className="w-100 pl-0">
												<div className="col-md-6 col-12 my-1">
													<Input
														type="date"
														id="startDate"
														name="startDate"
														min={`${new Date().toISOString().split("T")[0]}`}
														max={endDate ? endDate : ""}
														blockClassName="mx-1"
														inputClassName={`mr-2 ${isInvalidDate ? "is-invalid error-bordered" : ""
															}`}
														labelValue={`${translationService.translate(
															"TR_START_DATE",
														)} *`}
														value={startDate}
														onChange={event =>
															onChangeCustomWebinarStartDate(
																event,
																setStartDate,
															)
														}
														onBlur={() =>
															checkStartEndDateIsValidCustomWebinar(startDate)
														}
													/>
												</div>
												<div className="col-md-6 col-12 my-1">
													<Input
														type="date"
														id="endDate"
														name="endDate"
														min={`${startDate
															? startDate
															: new Date().toISOString().split("T")[0]
															}`}
														blockClassName="mx-1"
														inputClassName={`mr-2 ${isInvalidDate ? "is-invalid error-bordered" : ""
															}`}
														labelValue={`${translationService.translate(
															"TR_END_DATE",
														)} *`}
														value={endDate}
														onChange={event =>
															onChangeCustomWebinarEndDate(event, setEndDate)
														}
														onBlur={() =>
															checkStartEndDateIsValidCustomWebinar(endDate)
														}
													/>
												</div>
											</div>
											{rangeDays && rangeDays.length
												? rangeDays.map((month, monthIndex) => {
													return (
														<div key={monthIndex} className="col-12">
															{month[0] ? (
																<h2 className="section-title mt-2 mb-0">
																	{month[0].year}
																</h2>
															) : null}
															<div className="row">
																{month.length
																	? month.map((monthItem, index) => {
																		return (
																			<div
																				key={index}
																				className="col-md-4 col-sm-6 col-12 my-1">
																				<div
																					className={`day-of-the-week-block h-100 ${isInvalidWeekDays
																						? "is-invalid error-bordered"
																						: ""
																						}`}>
																					<div className="title-block">
																						<div className="w-100">
																							<b className="my-2 d-block secondary-color">{`${translationService.translate(
																								monthArr[monthItem.month],
																							)}`}</b>
																							<hr className="my-1" />
																							<div className="d-flex align-items-center justify-content-between">
																								<b className="my-2 d-block">{`${monthItem.day
																									} | ${translationService.translate(
																										monthItem.dayName,
																									)}`}</b>
																								<div className="plus-minus-block">
																									<i
																										className="fas fa-plus-square"
																										onClick={() =>
																											addStartTimeEndTimeToCurrentWeekDayCustomWebinar(
																												monthIndex,
																												index,
																											)
																										}></i>
																									<i
																										className="fas fa-minus-square"
																										onClick={() =>
																											removeStartTimeEndTimeFromCurrentWeekDayCustomWebinar(
																												monthIndex,
																												index,
																											)
																										}></i>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="week-days">
																						{monthItem.dataArr
																							? monthItem.dataArr.map(
																								(day, dayIndex) => {
																									return (
																										<div
																											key={dayIndex}
																											className="d-flex">
																											<Input
																												type="time"
																												id="startTime"
																												name="startTime"
																												value={
																													day.startTime
																												}
																												blockClassName="m-2 flex-1"
																												labelValue={translationService.translate(
																													"TR_S_T",
																												)}
																												onChange={event =>
																													changeDateOfTheWeekCustomWebinar(
																														event,
																														monthIndex,
																														index,
																														dayIndex,
																														"startTime",
																														monthItem,
																													)
																												}
																												onBlur={() =>
																													compareTimeCustomWebinar(
																														monthIndex,
																														index,
																														dayIndex,
																														monthItem,
																													)
																												}
																											/>
																											<Input
																												type="time"
																												id="endTime"
																												name="endTime"
																												value={
																													day.endTime
																												}
																												blockClassName="m-2 flex-1"
																												labelValue={translationService.translate(
																													"TR_E_T",
																												)}
																												onChange={event =>
																													changeDateOfTheWeekCustomWebinar(
																														event,
																														monthIndex,
																														index,
																														dayIndex,
																														"endTime",
																														monthItem,
																													)
																												}
																												onBlur={() =>
																													compareTimeCustomWebinar(
																														monthIndex,
																														index,
																														dayIndex,
																														monthItem,
																													)
																												}
																											/>
																										</div>
																									);
																								},
																							)
																							: null}
																					</div>
																					{getGetGroupOccupiedDays(
																						monthItem,
																					)}
																					{getGetOccupiedDays(monthItem)}
																				</div>
																			</div>
																		);
																	})
																	: null}
															</div>
														</div>
													);
												})
												: null}
										</div>
									) : null}
								</Auxiliary>
							) : null}
						</div>
						<div className="col-12">
							<div className="mt-3">
								<ActionButton
									spinnerId={spinnerId}
									type="submit"
									className="mindalay--btn-dark position-relative mb-3"
									name={translationService.translate(
										!webinarId ? "TR_CREATE" : "TR_UPDATE",
									)}
								/>
							</div>
						</div>
					</div>
				</form>
			) : (
				<div>
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p className="col-12">
								{translationService.translate("TR_YOU_HAVE_NO_GROUPS")}
							</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(WebinarForm);
