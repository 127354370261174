import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Link } from "react-router-dom";
import ArrowBackSvg from "./../../../Components/Svg/arrowBackSvg";
import { Card } from "react-bootstrap";
import NoDataImage from "./../../../assets/images/illustrations/nodata.svg";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import AlertService from "../../../Services/alertService";
import GamifyApiService from "../../../Services/gamifyApiService";
import {
	ADMIN_USER_TYPE,
	ERROR_KEY,
	NUMBER_KEY,
	STUDENT_USER_TYPE,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../../Constants/mainKeys";
import TextSpinner from "../../../Components/Spinners/textSpinner";
import {
	addButtonSpinner,
	addModalSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import NukaCarousel from "nuka-carousel";
import uuid from "react-uuid";
import ActionButton from "../../../Components/ActionButton/actionButton";
import ResourceCount from "../Resources/Components/ResourceCount";
import ModalComponentWithoutActionButton from "../../../Components/ModalComponent/modalComponentWithoutActionButton";
import CardSvg from "../../../Components/Svg/cardSvg";
import StarSvg from "../../../Components/Svg/starSvg";
import ReactSelectOption from "../../../Components/SelectOption/reactSelectOption";
import Input from "../../../Components/Inputs/input";
import moment from "moment";
import MainService from "../../../Services/mainService";

const buttonSpinnerId = uuid();

const Badge = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [categories, setCategories] = useState([]);
	const [isDisabledFields, setIsDisabledFields] = useState(false);
	const [shopItems, setShopItems] = useState([]);
	const [loaderId, setLoaderId] = useState(null);
	const [categoryId, setCategoryId] = useState(null);
	const [resourcesAsStudent, setResourcesAsStudent] = useState([]);
	const [isShowResourcesModal, setIsShowResourcesModal] = useState(false);
	const [isClosedInfoBlock, setIsClosedInfoBlock] = useState(false);
	const [isShowConvertForm, setIsShowConvertForm] = useState(false);
	const [givenResource, setGivenResource] = useState(null);

	const [values, setValues] = useState({
		userId: user.studentId?.toString(),
		quantity: "",
		givenResourceId: null,
		receivedResourceId: null,
	});

	useEffect(() => {
		getAllCategories();
	}, []);

	useEffect(() => {
		getCategoryById(categoryId || null);
	}, [categoryId]);

	const cancelConvert = () => {
		setValues(values => ({
			...values,
			quantity: "",
			givenResourceId: null,
			receivedResourceId: null,
		}));
		setIsShowConvertForm(false);
	};

	const onNumberChange = (event, fieldName, maxAmount = Infinity) => {
		if (+event.target.value.charAt(0) === 0) {
			event.target.value = event.target.value.substring(1);
		}
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				+event.target.value >= 0 &&
				+event.target.value <= maxAmount)
		) {
			setValues(values => ({
				...values,
				[fieldName]: event.target.value,
			}));
		}
	};

	const onSelectChange = (item, fieldName, cb) => {
		let valuesCopy = { ...values };
		if (
			fieldName === "givenResourceId" &&
			values.receivedResourceId &&
			item.resourceId === values.receivedResourceId
		) {
			valuesCopy.receivedResourceId = values.givenResourceId;
			valuesCopy.givenResourceId = values.receivedResourceId;
			let givenResource = resourcesAsStudent.find(
				item => item.resourceId === values.receivedResourceId,
			);
			if (cb) cb(givenResource);
		} else if (
			fieldName === "receivedResourceId" &&
			values.givenResourceId &&
			item.resourceId === values.givenResourceId
		) {
			valuesCopy.givenResourceId = values.receivedResourceId;
			valuesCopy.receivedResourceId = values.givenResourceId;
			let givenResource = resourcesAsStudent.find(
				item => item.resourceId === values.givenResourceId,
			);
			if (cb) cb(givenResource);
		} else {
			if (cb) cb(item);
		}
		valuesCopy[fieldName] = item.value;
		setValues(valuesCopy);
	};

	const getAllCategories = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		GamifyApiService.getAllCategories()
			.then(response => {
				if (response && response.data) {
					setCategories(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getCategoryById = (categoryId = 0) => {
		setIsDisabledFields(true);
		const data = {
			categoryId: categoryId,
			userId: user.studentId ? user.studentId.toString() : null,
			shopItemImageSize: 3,
			resourceImageSize: 1,
		};
		GamifyApiService.getCategoryById(data)
			.then(response => {
				if (response && response.data) {
					if (response.data && response.data.length) {
						let data = [...response.data];
						data.forEach(item => (item.quantity = 1));
						setShopItems(data);
					} else {
						setShopItems([]);
					}
				}
				window.scrollTo({ top: 0, behavior: "smooth" });
				setIsDisabledFields(false);
			})
			.catch(error => getFail(error));
	};

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const addOrRemoveFavorite = item => {
		if (!item || loaderId) {
			return false;
		}
		let shopItemsCopy = [...shopItems];
		setLoaderId(item.id);
		(item.isFavorite
			? GamifyApiService.removeShopItemFromFavoritesByUserId(
				user.studentId ? user.studentId.toString() : null,
				item.id,
			)
			: GamifyApiService.addShopItemToFavorites({
				userId: user.studentId ? user.studentId.toString() : null,
				shopItemId: item.id,
			})
		)
			.then(response => {
				shopItemsCopy.forEach(_item => {
					if (_item.id === item.id) {
						_item.isFavorite = !item.isFavorite;
					}
				});
				setShopItems(shopItemsCopy);
				setLoaderId(null);
			})
			.catch(error => getFail(error));
	};

	const changeShopItemCount = (item, num) => {
		if (num < 1 || item.availability < num) {
			return false;
		}
		let shopItemsCopy = [...shopItems];
		shopItemsCopy.forEach(_item => {
			if (_item.id === item.id) {
				_item.quantity = num;
			}
		});
		setShopItems(shopItemsCopy);
	};

	const addOrRemoveShoppingCart = (item, spinnerId) => {
		if (!item || loaderId) {
			return false;
		}
		dispatch(addButtonSpinner(spinnerId));
		let shopItemsCopy = [...shopItems];
		(item.isShopingCart
			? GamifyApiService.removeShopItemFromShoppingCartByUserId(
				user.studentId ? user.studentId.toString() : null,
				item.id,
			)
			: GamifyApiService.addShopItemToShoppingCartByUserId({
				userId: user.studentId ? user.studentId.toString() : null,
				shopItemId: item.id,
				quantity: item.quantity,
			})
		)
			.then(response => {
				shopItemsCopy.forEach(_item => {
					if (_item.id === item.id) {
						_item.isShopingCart = !item.isShopingCart;
						_item.quantity = 1;
					}
				});
				setShopItems(shopItemsCopy);
				dispatch(removeButtonSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const generateGamifyToken = (
		scopeType,
		redirectToCategoryForm,
		id,
		spinnerId,
	) => {
		if (!scopeType || isDisabledFields) {
			return false;
		}
		if (spinnerId) {
			dispatch(addButtonSpinner(spinnerId));
		}
		setIsDisabledFields(true);
		GamifyApiService.generateGamifyToken({ scopeType })
			.then(response => {
				if (response && response.data) {
					let redirectUrl = "";
					if (redirectToCategoryForm) {
						redirectUrl = `${process.env.REACT_APP_GAMIFICATION_URL}/category/${response.data}/form`;
					} else if (id) {
						redirectUrl = `${process.env.REACT_APP_GAMIFICATION_URL}/shop-item/${response.data}/form/${id}`;
					} else {
						redirectUrl = `${process.env.REACT_APP_GAMIFICATION_URL}/shop-item/${response.data}/form`;
					}

					window.open(redirectUrl, "_blank");
				}
				setIsDisabledFields(false);
				dispatch(removeButtonSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const calculate = () => {
		if (
			!values.quantity ||
			!values.givenResourceId ||
			!values.receivedResourceId ||
			!resourcesAsStudent.length
		) {
			return false;
		}
		let firstResource = resourcesAsStudent.find(
			item => item.resourceId === values.receivedResourceId,
		);
		let secondResource = resourcesAsStudent.find(
			item => item.resourceId === values.givenResourceId,
		);
		if (!firstResource || !secondResource) {
			return false;
		}
		let calculatedPrice =
			(values.quantity * firstResource.resource.rate) /
			secondResource.resource.rate;
		return {
			firstResource,
			secondResource,
			calculatedPrice: calculatedPrice ? Math.floor(calculatedPrice) : 0,
		};
	};

	const onChangeCoin = () => {
		if (
			!values.quantity ||
			!values.givenResourceId ||
			!values.receivedResourceId
		) {
			return false;
		}
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")}`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			const spinnerId = `${buttonSpinnerId}_convert`;
			dispatch(addButtonSpinner(spinnerId));
			values.quantity = +values.quantity;
			GamifyApiService.onChangeCoin(values)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					cancelConvert();
					getStudentResources();
					dispatch(removeButtonSpinner(spinnerId));
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const buyShopItem = (shopItem, spinnerId) => {
		if (!shopItem) return false;

		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addButtonSpinner(spinnerId));
			const data = {
				id: shopItem.id,
				userId: user.studentId ? user.studentId.toString() : null,
				quantity: shopItem.quantity,
				shopItemImageSize: 3,
				resourceImageSize: 1,
			};

			GamifyApiService.buyShopItem(data)
				.then(response => {
					if (response && response.data && response.data.shopItem) {
						setShopItems(prev =>
							prev.map(_item => {
								if (_item.id === response.data.shopItem.id) {
									let data = { ...response.data.shopItem };
									data.quantity = 1;
									data.isFavorite = shopItem.isFavorite;
									data.isShopingCart = shopItem.isShopingCart;
									return data;
								}
								return _item;
							}),
						);
					}
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					dispatch(removeButtonSpinner(spinnerId));
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const getStudentResources = () => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		const data = {
			userId: user.studentId ? user.studentId.toString() : null,
			resourceImageSize: 3,
			fragmentImageSize: 2,
		};
		setIsShowResourcesModal(true);
		GamifyApiService.getStudentResources(data)
			.then(response => {
				if (response && response.data) {
					setResourcesAsStudent(response.data);
				} else {
					setResourcesAsStudent([]);
				}
				dispatch(removeModalSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		setIsDisabledFields(false);
		setLoaderId(false);
	};

	const makeAccordion = (categories, id = 1, customClass) => {
		if (categories && categories.length) {
			return categories.map((item, index) => {
				return (
					<div key={item.id}>
						<div id={`headingOne_${item.id}_${id}`}>
							<div
								className={`cursor-pointer ${customClass} rounded w-100 mb-1 gamify-category-item p-3 ${isDisabledFields ? "disabled" : ""
									}`}
								data-toggle="collapse"
								data-target={`#collapseOne_${item.id}_${id}`}
								aria-expanded="true"
								aria-controls={`collapseOne_${item.id}_${id}`}
								onClick={() => {
									if (categoryId === item.id) {
										return false;
									}
									setCategoryId(item.id);
								}}>
								<div className="pl-2 d-flex align-items-center">
									<h6
										className={` ${categoryId === item.id
												? "active-header-left-menu-item-accordion"
												: ""
											}`}
										style={{
											fontWeight: `${categoryId === item.id ? "700" : ""}`,
										}}>
										{item.name}
									</h6>
									{(item.categories && item.categories.length) ||
										categoryId === item.id ? (
										<i
											className="fas fa-angle-down"
											style={{
												fontSize: "14px",
												marginLeft: "10px",
												transform:
													categoryId === item.id ? "rotate(-90deg)" : "",
											}}></i>
									) : null}
								</div>
							</div>
						</div>
						{item.categories && item.categories.length ? (
							<div
								id={`collapseOne_${item.id}_${id}`}
								className={`collapse`}
								aria-labelledby={`headingOne_${item.id}_${id}`}>
								<div className="pl-3 gamify-category-bg-light">
									{makeAccordion(
										item.categories,
										item.id,
										customClass === "gamify-category-bg-light",
									)}
								</div>
							</div>
						) : null}
					</div>
				);
			});
		}
	};

	const convertTranslation = (text = "", values, calculate) => {
		if (!text || !values || !calculate) return null;
		if (text.includes("{0}")) {
			text = text.replace(
				"{0}",
				`<h5 style="">&nbsp;${values.quantity} ${calculate?.firstResource?.resource?.name} &nbsp;</h5>`,
			);
		}
		if (text.includes("{1}")) {
			text = text.replace(
				"{1}",
				`<h5> &nbsp;${calculate?.calculatedPrice} ${calculate?.secondResource?.resource?.name}</h5>`,
			);
			return text;
		}
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					{user.userTypeId === STUDENT_USER_TYPE ? (
						<div className="p-0 instructor-consultant-navbar">
							<div className="d-flex justify-content-between flex-wrap align-items-center">
								<h2 className="section-title">
									{translationService.translate("TR_CATEGORIES_SHOP_ITEMS")}
								</h2>
								<div className="navbar">
									<ul className="navbar-nav">
										<li className="nav-item position-relative dropdown ">
											<Link
												to={`/${language}/gamification/favorites`}
												className="nav-link p-1"
												title="Favorites">
												<StarSvg />
											</Link>
										</li>

										<li
											className={`nav-item position-relative dropdown ${isClosedInfoBlock ? "mr-4" : ""
												}`}>
											<Link
												to={`/${language}/gamification/shopping-cart`}
												className="nav-link p-1"
												title={translationService.translate(
													"TR_SHOPPING_CART",
												)}>
												<CardSvg />
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					) : (
						<h2 className="content-title p-0">
							<Link
								to={`/${language}/gamification/dashboard`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							{translationService.translate("TR_CATEGORIES_SHOP_ITEMS")}
						</h2>
					)}

					<PageInfoBlock
						pageTitle={
							user && user.userTypeId === ADMIN_USER_TYPE
								? "Admin category & shop items"
								: "Student categories and shoping items"
						}
						pageName={
							user && user.userTypeId === ADMIN_USER_TYPE
								? "admin_category_and_shop_items"
								: "student_categories_and_shoping_items"
						}
						translationService={translationService}
						closeCb={() => setIsClosedInfoBlock(!isClosedInfoBlock)}
					/>
				</div>
			</div>
			<hr />
			{
				<div className="row mx-0" style={{ minHeight: "500px" }}>
					<div className="col-12 col-lg-4 p-1">
						<div className="border rounded h-100 px-3 py-2">
							<div className="d-flex justify-content-between align-items-center flex-wrap">
								<h2 className="content-title p-0">
									{translationService.translate("TR_CATEGORIES")}
								</h2>
								{user.userTypeId === STUDENT_USER_TYPE ? null : (
									<button
										type="button"
										className={`mindalay--btn-primary-outline mindalay--btn-small mt-2 ${isDisabledFields ? "disabled" : ""
											}`}
										onClick={() => generateGamifyToken(5, true)}>
										+ {translationService.translate("TR_ADD")}
									</button>
								)}
							</div>
							<div className="my-3">
								{categories && categories.length ? (
									<div id="accordion">
										{makeAccordion(categories, null, "gamify-category-bg-dark")}
									</div>
								) : null}
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-8 p-1 mt-lg-0 p-1">
						<div className="border rounded px-3 py-2 h-100">
							<div className="row align-items-stretch">
								<div className="col-12">
									<div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
										<h2 className="content-title p-0">
											{translationService.translate("TR_SHOP_ITEMS")}
										</h2>
										{user.userTypeId === STUDENT_USER_TYPE ? (
											<button
												type="button"
												className={`mindalay--btn-primary-outline mindalay--btn-small ${isDisabledFields ? "disabled" : ""
													}`}
												onClick={() => getStudentResources()}>
												{translationService.translate("TR_SHOW_MY_RESOURCES")}
											</button>
										) : (
											<button
												type="button"
												className={`mindalay--btn-primary-outline mindalay--btn-small mt-2 ${isDisabledFields ? "disabled" : ""
													}`}
												onClick={() => generateGamifyToken(5, false)}>
												+ {translationService.translate("TR_ADD")}
											</button>
										)}
									</div>
								</div>
								{shopItems && shopItems.length ? (
									shopItems.map((item, index) => {
										return (
											<div key={index} className="col-12 col-md-6 col-lg-4 p-2">
												<Card className="w-100 h-100">
													{user.userTypeId === STUDENT_USER_TYPE ? (
														<div
															className={`d-flex align-items-center course-ruls-wrapper py-2 position-relative ${item.availability && item.availability > 0
																	? ""
																	: "disabled"
																}`}>
															{
																<div
																	className="wish-list-icon"
																	onClick={() => {
																		addOrRemoveFavorite(item);
																	}}>
																	{loaderId === item.id ? (
																		<div className="mx-1">
																			<TextSpinner
																				spinner={true}
																				color="#F7C93D"
																			/>
																		</div>
																	) : item.isFavorite ? (
																		<i
																			className="fa fa-star cursor-pointer"
																			style={{
																				color: "#F7C93D",
																				fontSize: "20px",
																			}}
																		/>
																	) : !item.isFavorite ? (
																		<i
																			className="fa fa-star-o cursor-pointer"
																			style={{
																				color: "#F7C93D",
																				fontSize: "20px",
																			}}
																		/>
																	) : null}
																</div>
															}
														</div>
													) : null}
													{item.imagePaths && item.imagePaths.length ? (
														item.imagePaths.length > 1 ? (
															<NukaCarousel
																autoplay={
																	item.imagePaths.length > 1 ? true : false
																}
																wrapAround={true}
																autoplayInterval={80000}
																speed={1000}
																height="200px"
																className="small-carousel"
																renderCenterLeftControls={({
																	previousSlide,
																}) => (
																	<button
																		onClick={previousSlide}
																		className="arrow left-arrow">
																		<i className="fa fa-arrow-left" />
																	</button>
																)}
																renderCenterRightControls={({ nextSlide }) => (
																	<button
																		onClick={nextSlide}
																		className="arrow right-arrow">
																		<i className="fa fa-arrow-right" />
																	</button>
																)}>
																{item.imagePaths.map((item, index) => {
																	return (
																		<div
																			key={index}
																			className="w-100 pt-3"
																			style={{
																				backgroundImage: `url(${item})`,
																				backgroundPosition: "center",
																				backgroundSize: "contain",
																				backgroundRepeat: "no-repeat",
																				height: "200px",
																			}}
																		/>
																	);
																})}
															</NukaCarousel>
														) : (
															<div
																key={index}
																className="w-100 pt-3"
																style={{
																	backgroundImage: `url(${item.imagePaths[0]})`,
																	backgroundPosition: "center",
																	backgroundSize: "contain",
																	backgroundRepeat: "no-repeat",
																	height: "200px",
																}}
															/>
														)
													) : null}
													<Card.Body className="position-relative pt-0 d-flex flex-column justify-content-between p-2">
														<div>
															<h3 className="title text-center">{item.name}</h3>
															<hr className="my-2" />
														</div>
														<div>
															<p className="max-line-3">{item.description}</p>
															<p className="d-flex justify-content-between mt-2">
																<b>
																	{translationService.translate(
																		"TR_AVAILABILITY",
																	)}
																</b>
																<b
																	className={`${item.availability && item.availability > 0
																			? ""
																			: "text-red"
																		}`}>
																	{item.availability}
																</b>
															</p>
															<p className="d-flex justify-content-between mt-2 align-items-center">
																<b>
																	{translationService.translate("TR_PRICE")}
																</b>
																<span className="d-flex align-items-center word-break-break-word max-line-1">
																	<small className="mx-2 ">
																		{item.resourceAmount}
																	</small>
																	<img
																		className="rounded"
																		src={item?.resource.imagePath}
																		alt={item?.resource.name}
																		title={item?.resource.name}
																		style={{ width: "30px", height: "30px" }}
																	/>
																</span>
															</p>
															{item.startDate && (
																<p className="d-flex justify-content-between mt-2 align-items-center">
																	<b>
																		{translationService.translate(
																			"TR_START_DATE",
																		)}
																	</b>
																	<span className="d-flex align-items-center word-break-break-word max-line-1">
																		<small className="mx-2 ">
																			{moment(new Date(item.startDate)).format(
																				"DD.MM.YYYY",
																			)}
																		</small>
																	</span>
																</p>
															)}
															{item.endDate && (
																<p className="d-flex justify-content-between mt-2 align-items-center">
																	<b>
																		{translationService.translate(
																			"TR_END_DATE",
																		)}
																	</b>
																	<span className="d-flex align-items-center word-break-break-word max-line-1">
																		<small className="mx-2 ">
																			{moment(new Date(item.endDate)).format(
																				"DD.MM.YYYY",
																			)}
																		</small>
																	</span>
																</p>
															)}
														</div>
														{user.userTypeId === STUDENT_USER_TYPE ? (
															<div
																className={`${item.availability && item.availability > 0
																		? ""
																		: "disabled"
																	}`}>
																<Auxiliary>
																	<hr className="mt-2" />
																	<div
																		className={`d-flex justify-content-around align-items-center`}>
																		<button
																			className=" mindalay--btn-blue mindalay--btn outline-none d-flex justify-content-center align-items-center p-3"
																			style={{
																				fontSize: "25px",
																				height: "20px",
																				width: "20px",
																				borderRadius: "50%",
																			}}
																			onClick={() =>
																				changeShopItemCount(
																					item,
																					(item.quantity || 0) - 1,
																				)
																			}>
																			<span className="mb-1">-</span>
																		</button>
																		<b>{item.quantity}</b>
																		<button
																			className=" mindalay--btn-blue mindalay--btn outline-none d-flex justify-content-center align-items-center p-3"
																			style={{
																				fontSize: "25px",
																				height: "20px",
																				width: "20px",
																				borderRadius: "50%",
																			}}
																			onClick={() =>
																				changeShopItemCount(
																					item,
																					(item.quantity || 0) + 1,
																				)
																			}>
																			<span className="mb-1">+</span>
																		</button>
																	</div>
																</Auxiliary>
																<ActionButton
																	type="button"
																	spinnerId={`${buttonSpinnerId}_${item.id}`}
																	clicked={() => {
																		addOrRemoveShoppingCart(
																			item,
																			`${buttonSpinnerId}_${item.id}`,
																		);
																	}}
																	className="mindalay--btn-secondary w-100 mt-3 mb-1 py-2"
																	name={
																		item.isShopingCart
																			? translationService.translate(
																				"TR_REMOVE_FROM_CART",
																			)
																			: translationService.translate(
																				"TR_ADD_TO_CART",
																			)
																	}
																/>
																<ActionButton
																	type="button"
																	spinnerId={`${buttonSpinnerId}_${item.id}_but_now`}
																	clicked={() =>
																		buyShopItem(
																			item,
																			`${buttonSpinnerId}_${item.id}_but_now`,
																		)
																	}
																	className="mindalay--btn-dark w-100 py-2"
																	name={translationService.translate(
																		"TR_BUY_NOW",
																	)}
																/>
															</div>
														) : null}
														{user.userTypeId === STUDENT_USER_TYPE ? null : (
															<ActionButton
																type="button"
																spinnerId={`${buttonSpinnerId}_${item.id}`}
																clicked={() => {
																	generateGamifyToken(
																		5,
																		false,
																		item.id,
																		`${buttonSpinnerId}_${item.id}`,
																	);
																}}
																className="mindalay--btn-secondary-outline w-100 mt-3"
																name={translationService.translate("TR_EDIT")}
															/>
														)}
													</Card.Body>
												</Card>
											</div>
										);
									})
								) : user.userTypeId === STUDENT_USER_TYPE ? (
									<div className="no-data-container mt-5">
										<div className="no-data-wrapper">
											<p>{translationService.translate("TR_NO_DATA")}</p>
											<img src={NoDataImage} alt="/" />
										</div>
									</div>
								) : (
									<div
										className={`col-12 p-3 ${isDisabledFields ? "disabled" : ""
											}`}>
										<div className="row justify-content-center">
											<div className="col-12 col-md-8">
												<div
													className="gamification-nodata"
													onClick={() => generateGamifyToken(5, false)}>
													<span>
														<i className={`fas fa-plus`}></i>
													</span>
													<p>
														{translationService.translate(
															"TR_CREATE_YOUR_FIRST_ITEM",
														)}
													</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			}
			{isShowResourcesModal ? (
				<ModalComponentWithoutActionButton
					isLarge={true}
					title={translationService.translate("TR_RESOURCES")}
					cancel={() => {
						setIsShowResourcesModal(false);
					}}>
					<div className="row">
						{!isShowConvertForm &&
							resourcesAsStudent &&
							resourcesAsStudent.length > 1 ? (
							<div className="col-12 d-flex justify-content-end">
								<button
									type="button"
									className={`mindalay--btn-primary-outline mindalay--btn-small mb-2`}
									onClick={() => {
										setValues(values => ({
											...values,
											givenResourceId: resourcesAsStudent[0].resourceId,
											receivedResourceId: resourcesAsStudent[1].resourceId,
											quantity: 1,
										}));
										setGivenResource(resourcesAsStudent[0]);
										setIsShowConvertForm(true);
									}}>
									{translationService.translate("TR_CHANGE_COIN")}
								</button>
							</div>
						) : null}

						{isShowConvertForm ? (
							<Auxiliary>
								{resourcesAsStudent && resourcesAsStudent.length ? (
									<Auxiliary>
										<div className="col-lg-4">
											<ReactSelectOption
												value={values.receivedResourceId}
												selectedValue={(() => {
													const selectedItem = {
														...resourcesAsStudent.find(
															data =>
																+data.resourceId === +values.receivedResourceId,
														),
													};
													if (selectedItem) {
														selectedItem.label = selectedItem.resource?.name;
														selectedItem.value = selectedItem.resourceId;
													}
													return selectedItem;
												})()}
												className="mt-2 mt-lg-0"
												items={resourcesAsStudent
													.filter(item => item.resource.rate)
													.map(data => ({
														...data,
														label: data.resource.name,
														value: data.resourceId,
													}))}
												onChange={item =>
													onSelectChange(item, "receivedResourceId")
												}
											/>
										</div>
										<div className="col-lg-4">
											<div className="d-flex align-items-start">
												<div>
													<Input
														type="text"
														id="quantity"
														name="quantity"
														value={values.quantity}
														blockClassName="mb-0"
														className={`mt-2 mt-lg-0 ${calculate().isInvalidMaxValue
																? "error-bordered"
																: ""
															}`}
														isInvalidField={false}
														placeholder={translationService.translate(
															"TR_QUANTITY",
														)}
														onChange={event =>
															onNumberChange(
																event,
																"quantity",
																calculate().maxValue,
															)
														}
													/>
													{values.quantity ? (
														<small
															style={{ fontSize: "12px" }}
															className={
																calculate().isInvalidMaxValue ? "red-color" : ""
															}>
															{translationService.translate("TR_MAX")}:{" "}
															{calculate().maxValue}
														</small>
													) : null}
												</div>
												<b className="mx-2" style={{ marginTop: "10px" }}>
													=
												</b>
												<div>
													<Input
														type="text"
														id="quantity_1"
														name="quantity"
														value={
															calculate()?.calculatedPrice
																? `${calculate()?.calculatedPrice}`
																: 0
														}
														blockClassName="mb-0"
														className="mt-2 mt-lg-0 pointer-events-none"
														isInvalidField={false}
														onChange={() => { }}
													/>
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<ReactSelectOption
												value={values.givenResourceId}
												selectedValue={(() => {
													const selectedItem = {
														...resourcesAsStudent.find(
															data =>
																+data.resourceId === +values.givenResourceId,
														),
													};
													if (selectedItem) {
														selectedItem.label = selectedItem.resource?.name;
														selectedItem.value = selectedItem.resourceId;
													}
													return selectedItem;
												})()}
												className="mt-2 mt-lg-0"
												items={resourcesAsStudent
													.filter(item => item.resource.rate)
													.map(data => ({
														...data,
														label: data.resource.name,
														value: data.resourceId,
													}))}
												onChange={item =>
													onSelectChange(
														item,
														"givenResourceId",
														setGivenResource,
													)
												}
											/>
										</div>
									</Auxiliary>
								) : null}
								<div className="col-lg-6 mt-3">
									<div>
										{values.quantity ? (
											<span
												className="d-flex align-items-center mb-2 text-nowrap"
												dangerouslySetInnerHTML={{
													__html: convertTranslation(
														translationService.translate(
															"TR_WHEN_EXCHANGING_YOU_WILL_RECEVIE",
														),
														values,
														calculate(),
													),
												}}>
												{/* 												
												When exchanging
												<h5 style={{}}>
													&nbsp;{values.quantity}
													{calculate().firstResource?.resource?.name} &nbsp;
												</h5>
												you will receive{" "}
												<h5>
													{" "}
													&nbsp;{calculate()?.calculatedPrice}{" "}
													{calculate().secondResource?.resource?.name}
												</h5> */}
											</span>
										) : null}
									</div>
								</div>
								<div className="col-lg-6 mt-2">
									<div className="d-flex justify-content-end w-100">
										<button
											className="mindalay--btn-default mr-1 mindalay--btn-small"
											onClick={cancelConvert}>
											{translationService.translate("TR_CANCEL")}
										</button>
										<ActionButton
											type="button"
											spinnerId={`${buttonSpinnerId}_convert`}
											className={`mindalay--btn-dark py-2 ml-1 mindalay--btn-small ${!values.quantity ||
													!values.givenResourceId ||
													!values.receivedResourceId ||
													!calculate().calculatedPrice ||
													calculate()?.calculatedPrice >
													givenResource.resourceAmount
													? "disabled"
													: ""
												}`}
											name={translationService.translate("TR_SAVE")}
											clicked={onChangeCoin}
										/>
									</div>
								</div>

								<div className="col-12">
									<hr />
								</div>
							</Auxiliary>
						) : null}
					</div>

					<div className="row align-self-stretch">
						{resourcesAsStudent && resourcesAsStudent.length ? (
							resourcesAsStudent.map((item, index) => {
								return (
									<div key={index} className="col-12 col-md-6 col-lg-3 p-2">
										<Card className="w-100 h-100 position-relative overflow-hidden">
											<ResourceCount count={item.resourceAmount} />
											<div
												className="w-100"
												style={{
													backgroundImage: `url(${item.resource?.imagePath})`,
													height: "200px",
													backgroundPosition: "center",
													backgroundSize: "contain",
													backgroundRepeat: "no-repeat",
												}}
											/>
											<Card.Body className="p-2">
												<h3
													className="title text-center max-line-2"
													title={item.resource?.name}>
													{item.resource?.name}
												</h3>
											</Card.Body>
										</Card>
									</div>
								);
							})
						) : (
							<div className="no-data-container">
								<div className="no-data-wrapper">
									<p>{translationService.translate("TR_NO_DATA")}</p>
									<img src={NoDataImage} alt="/" />
								</div>
							</div>
						)}
					</div>
				</ModalComponentWithoutActionButton>
			) : null}
		</div>
	) : null;
};

export default withRouter(Badge);
