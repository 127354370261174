import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TRANSLATION_TYPES_KEY,
} from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	removePartialViewSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import { Tab } from "bootstrap";
import { Tabs } from "react-bootstrap";
import NoDataImage from "./../../assets/images/illustrations/nodata.svg";
import Input from "../../Components/Inputs/input";
import ReactPaginate from "react-paginate";
import EditSvg from "../../Components/Svg/editSvg";
import { addModalSpinner } from "../../Store/Actions/spinner";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import Textarea from "../../Components/Inputs/textArea";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";

const transactionType = [
	{ label: "All", value: null },
	{ label: "Default", value: 0 },
	{ label: "Email", value: 5 },
	{ label: "Notification", value: 6 },
	{ label: "Page", value: 7 },
];

const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

const TranslationsPage = () => {
	const dispatch = useDispatch();
	const pageSize = 10;
	const translations = useSelector(state => state.translation.translations);
	const languages = useSelector(state => state.language.languages);
	const [translationService, setTranslationService] = useState(null);
	const [systemTranslations, setSystemTranslations] = useState([]);
	const [tabKey, setTabKey] = useState("en");
	const [searchText, setSearchText] = useState("");
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [type, setType] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [values, setValues] = useState({
		languageId: null,
		shortKey: "TR_",
		translation1: "",
		type: null,
	});

	const searchDebounce = useDebounce(searchText, 1000);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (!searchText) getSystemTranslations(1, pageSize, "en", type);
	}, [searchText]);

	useEffect(() => {
		if (searchDebounce) {
			triggerChange(searchText);
		}
	}, [searchDebounce]);

	const getSystemTranslations = (
		pageNumber,
		pageSize,
		isocode,
		type = null,
	) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOrganizationTranslationsByOrganizationId(
			+pageNumber,
			+pageSize,
			isocode,
			type,
		)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data && response.data.length) {
					const data = [...response.data];
					setSystemTranslations(data);
				} else {
					setSystemTranslations([]);
				}
			})
			.catch(
				error =>
					error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	// const deleteTranslatio = item => {
	// 	if (!item) return;
	// 	const spinnerId = uuid();
	// 	AlertService.alertConfirm(
	// 		`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
	// 		"",
	// 		translationService.translate(TR_YES),
	// 		translationService.translate(TR_NO),
	// 	).then(() => {
	// 		dispatch(addPartialViewSpinner(spinnerId));
	// 		ApiService.deleteSystemTranslation(item.id)
	// 			.then(() => {
	// 				const newsystemTranslations = systemTranslations.filter(
	// 					_item => _item.id !== item.id,
	// 				);
	// 				if (newsystemTranslations.length || searchText || type !== null) {
	// 					setSystemTranslations(newsystemTranslations);
	// 				} else {
	// 					getSystemTranslations(1, pageSize, "en", type);
	// 					setActivePageNumber(0);
	// 				}

	// 				AlertService.alert(
	// 					SUCCESS_KEY,
	// 					translationService.translate("TR_DATA_SAVED"),
	// 				);
	// 			})
	// 			.catch(error =>
	// 				AlertService.alert(
	// 					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
	// 					error,
	// 				),
	// 			)
	// 			.finally(() => {
	// 				dispatch(removePartialViewSpinner(spinnerId));
	// 			});
	// 	});
	// };

	const handlePageClick = event => {
		setPageNumber(event.selected + 1);
		setActivePageNumber(event.selected);
		getSystemTranslations(event.selected + 1, pageSize, tabKey, type);
	};

	const getModalData = item => {
		setOpenModal(true);
		if (item) {
			setValues({
				...item,
				languageId: languages.find(el => el.isocode2 === tabKey).languageId,
			});
		} else {
			setValues(values => ({
				...values,
				languageId: languages.find(el => el.isocode2 === tabKey).languageId,
			}));
		}
	};

	const onChange = (event, fieldName, maxLength = null, cb) => {
		let regex = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^`{|}~]+$/u;

		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		if (fieldName === "shortKey") {
			let key = event.target.value.slice(2);
			if (!regex.test(key)) {
				return false;
			}
			setValues(values => ({
				...values,
				[fieldName]: event.target.value.toUpperCase(),
			}));
			return true;
		}
		setValues(values => ({
			...values,
			[fieldName]: event.target.value,
		}));
		setIsChange(true);
		setIsInvalidSubmit(false);
	};

	const onSelectOptionChange = (item, fieldName, cb) => {
		if (!item) {
			return false;
		}
		setIsChange(true);
		setIsInvalidSubmit(false);
		setValues(values => ({ ...values, [fieldName]: item.value }));
	};

	const typeChange = item => {
		if (!item) return false;
		setType(item.value);
		getSystemTranslations(1, pageSize, tabKey, item.value);
		setActivePageNumber(0);
	};

	const triggerChange = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.searchTranslations(searchText, tabKey)
			.then(response => {
				if (response && response.data) {
					const data = [...response.data];
					setSystemTranslations(data);
				} else {
					setSystemTranslations([]);
				}
			})
			.catch(
				error =>
					error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
				setType(null);
			});
	};

	const onSubmit = e => {
		if (
			!values.languageId ||
			!values.shortKey.slice(3).trim().length ||
			!values.translation1.trim().length
		) {
			setIsInvalidSubmit(true);
			return false;
		}
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));

		ApiService.updateOrganizationTransalation(values)
			.then(response => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				if (response.data && (searchText || type !== null)) {
					setSystemTranslations([...systemTranslations, response.data]);
				} else if (values.id || searchText || type !== null) {
					setSystemTranslations(
						systemTranslations.map(item => {
							if (item.id === values.id) {
								return { ...item, ...values };
							}
							return item;
						}),
					);
				} else {
					getSystemTranslations(1, pageSize, "en");
					setPageNumber(0);
					setActivePageNumber(0);
					setSearchText("");
					setType(null);
				}
				cancel();
			})
			.catch(
				error =>
					error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
			)
			.finally(() => {
				dispatch(removeModalSpinner(spinnerId));
			});
	};

	const cancel = () => {
		setValues({
			languageId: null,
			shortKey: "TR_",
			translation1: "",
			type: null,
		});
		setOpenModal(false);
		setIsInvalidSubmit(false);
		setIsChange(false);
	};

	return translationService && languages ? (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_TRANSLATIONS")}
					</h2>
					<hr />
				</div>
			</div>
			<div className="translation-search-block">
				<div className="row">
					<div className="col-12 col-lg-6">
						<Input
							type="text"
							id="searchText"
							name="searchText"
							value={searchText}
							labelValue={translationService.translate("TR_SEARCH")}
							placeholder={`${translationService.translate("TR_SEARCH")}`}
							labelClassName="mt-2"
							onChange={event => setSearchText(event.target.value)}
						/>
					</div>
					<div className="col-12 col-lg-6">
						<div className="form-group">
							<label className="mt-2">
								{translationService.translate("TR_TYPE")}
							</label>
							<ReactSelectOption
								value={type}
								selectedValue={(() => {
									const selectedItem = {
										...transactionType.find(data => data.value === type),
									};
									if (Object.keys(selectedItem).length) {
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={transactionType
									.filter(item => item.value !== type)
									.map(data => ({
										...data,
										label: data.label,
										value: data.value,
									}))}
								onChange={item => typeChange(item)}
							/>
						</div>
					</div>
				</div>
			</div>
			{openModal && (
				<ModalComponent
					title={values?.id ? "TR_CREATE" : "TR_UPDATE"}
					actionButtonTitle={translationService.translate("TR_SAVE")}
					addButtonIsDisabled={!isChange}
					cancel={cancel}
					onSubmit={onSubmit}>
					<div className="row">
						<div className="col-12">
							<div className="form-group">
								<label>{translationService.translate("TR_LANGUAGE")}*</label>
								<ReactSelectOption
									isDisabled={values?.id}
									value={values.languageId}
									isInvalidSubmit={isInvalidSubmit}
									selectedValue={(() => {
										const selectedItem = {
											...languages.find(
												data => data.languageId === values.languageId,
											),
										};
										if (Object.keys(selectedItem).length) {
											selectedItem.label = selectedItem.name;
											selectedItem.value = selectedItem.languageId;
											return selectedItem;
										} else {
											return {
												value: null,
												label: translationService.translate("TR_CHOOSE"),
											};
										}
									})()}
									items={languages
										.filter(item => item.languageId !== values.languageId)
										.map(data => ({
											...data,
											label: data.name,
											value: data.languageId,
										}))}
									onChange={item => onSelectOptionChange(item, "languageId")}
								/>
							</div>
						</div>
						{/* <div className="col-12 col-lg-6">
							<div className="form-group">
								<label>{translationService.translate("TR_TYPE")}*</label>
								<ReactSelectOption
									value={values.type}
									isInvalidSubmit={isInvalidSubmit}
									selectedValue={(() => {
										const selectedItem = {
											...transactionType
												.slice(1)
												.find(data => data.value === values.type),
										};
										if (Object.keys(selectedItem).length) {
											return selectedItem;
										} else {
											return { value: null, label: "Choose..." };
										}
									})()}
									items={transactionType
										.slice(1)
										.filter(item => item.value !== values.type)
										.map(data => ({
											...data,
											label: data.label,
											value: data.value,
										}))}
									onChange={item => onSelectOptionChange(item, "type")}
								/>
							</div>
						</div> */}
						<div className="col-12">
							<Input
								type="text"
								id="shortKey"
								name="shortKey"
								inputClassName="pr--5"
								value={values.shortKey}
								disabled={values?.id}
								fieldLength={40}
								isInvalidSubmit={
									isInvalidSubmit && !values.shortKey.slice(3).trim().length
								}
								placeholder={translationService.translate("TR_KEY")}
								labelValue={`${translationService.translate("TR_KEY")}*`}
								onChange={event => onChange(event, "shortKey", 40)}
							/>
						</div>
						<div className="col-12">
							<Textarea
								isInvalidSubmit={
									isInvalidSubmit && !values.translation1.trim().length
								}
								rows="5"
								id="translation1"
								name="translation1"
								value={values.translation1}
								labelValue={`${translationService.translate("TR_TEXT")} *`}
								placeholder={`${translationService.translate("TR_TEXT")} *`}
								onChange={event => onChange(event, "translation1")}
							/>
						</div>
					</div>
				</ModalComponent>
			)}
			{languages && languages.length ? (
				<div className="row">
					<div className="col-12">
						<div className="tab-title-group tab-content">
							<Tabs
								activeKey={tabKey}
								onSelect={key => {
									if (key !== tabKey) {
										setTabKey(key);
										getSystemTranslations(pageNumber, pageSize, key);
										setSearchText("");
									}
								}}>
								{languages.map(language => {
									return (
										<Tab
											key={language.languageId}
											eventKey={language.isocode2}
											title={language.name}>
											{systemTranslations && systemTranslations.length ? (
												<div className="mt-4">
													<div className="mindayal-admin-table table-responsive">
														<table className="table">
															<thead>
																<tr>
																	<th>
																		{translationService.translate("TR_KEY")}
																	</th>
																	<th>
																		{translationService.translate("TR_TEXT")}
																	</th>
																	<th>
																		{translationService.translate("TR_TYPE")}
																	</th>
																	<th>
																		{translationService.translate("TR_ACTION")}
																	</th>
																</tr>
															</thead>
															<tbody>
																{systemTranslations.map(
																	(systemTranslation, i) => {
																		return (
																			<tr key={i} className="cursor-default">
																				<td>
																					<div className="mindalay-admin-table-td-item">
																						{systemTranslation.shortKey}
																					</div>
																				</td>
																				<td>
																					<div className="mindalay-admin-table-td-item">
																						{systemTranslation.translation1}
																					</div>
																				</td>
																				<td>
																					<div className="mindalay-admin-table-td-item">
																						{TRANSLATION_TYPES_KEY.map(
																							(type, index) => {
																								if (
																									index ===
																									systemTranslation.type
																								) {
																									return type;
																								}
																							},
																						)}
																					</div>
																				</td>
																				<td>
																					<div className="table-action-btn-group d-flex align-items-center">
																						<>
																							<Link
																								to={"#"}
																								title={translationService.translate(
																									"TR_EDIT",
																								)}
																								onClick={e => {
																									e.stopPropagation();
																									getModalData(
																										systemTranslation,
																									);
																								}}
																								className="table-action-btn edit-btn">
																								<EditSvg />
																							</Link>
																							{/* <Link
																							to="#"
																							title={translationService.translate(
																								"TR_DELETE",
																							)}
																							className="table-action-btn delete-btn"
																							onClick={e => {
																								deleteTranslatio(
																									systemTranslation,
																								);
																							}}>
																							<DeleteSvg />
																						</Link> */}
																						</>
																					</div>
																				</td>
																			</tr>
																		);
																	},
																)}
															</tbody>
														</table>
													</div>
												</div>
											) : searchText ? (
												<div className="no-data-container mt-3">
													<div className="no-data-wrapper">
														<p>{translationService.translate("TR_NO_DATA")}</p>
														<img src={NoDataImage} alt="/" />
													</div>
												</div>
											) : null}
										</Tab>
									);
								})}
							</Tabs>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
			{pagination &&
				systemTranslations &&
				systemTranslations.length &&
				!searchText.trim() ? (
				<div className="row my-4 justify-content-end">
					<div className="col-12 d-flex justify-content-end">
						<div>
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};
export default withRouter(TranslationsPage);
