import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import TranslationService from "../Services/translationService";
import ArrowBackSvg from "../Components/Svg/arrowBackSvg";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
	WARNING_KEY,
} from "../Constants/mainKeys";
import uuid from "react-uuid";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import Input from "../Components/Inputs/input";
import InputCheckBox from "../Components/Inputs/inputCheckBox";
import ActionButton from "../Components/ActionButton/actionButton";
import MainService from "../Services/mainService";
import moment from "moment";
import Textarea from "../Components/Inputs/textArea";

const buttonSpinnerId = uuid();

function ConfigForm(props) {
	const mainService = new MainService();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const { configId } = props.match.params;
	const dispatch = useDispatch();
	const [values, setValues] = useState({
		name: "",
		value: "",
		description: "",
		isDefault: false,
		isReadonly: false,
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [currencys, setCurrency] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (configId) {
			getConfigById(configId);
		}
	}, [configId]);

	const getConfigById = id => {
		if (!id) return null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getConfigById(id)
			.then(response => {
				if (response.data) {
					const newValues = {};
					Object.entries(values).forEach(([key, value]) => {
						if (response.data.hasOwnProperty(key) && response.data[key]) {
							newValues[key] = response.data[key];
						}
					});
					newValues.id = +id;
					setValues({ ...values, ...newValues });
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};
	const onCheckBoxChange = (event, name) => {
		setValues(values => ({
			...values,
			[name]: event.target.checked,
		}));
	};

	const onSubmit = e => {
		e.preventDefault();
		if (!values.name.trim().length || !values.value.trim().length) {
			setIsInvalidSubmit(true);
			MainService.scrollToInvalidFieldPosition();
			return;
		}
		dispatch(addButtonSpinner(buttonSpinnerId));
		(!configId
			? ApiService.createConfig(values)
			: ApiService.updateConfig(values)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				setIsInvalidSubmit(false);
				props.history.push(`/${language}/developer/currency`);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	return (
		translationService && (
			<div className="container">
				<div className="row">
					<div className="col-12 content-title">
						<div className="d-flex align-items-center">
							<Link
								to={`/${language}/developer/configs`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							<h2 className="title">
								{!configId
									? translationService.translate("TR_CREATE_CONFIG")
									: translationService.translate("TR_UPDATE_CONFIG")}
							</h2>
						</div>
					</div>
				</div>
				<hr />

				<form className="row" onSubmit={onSubmit}>
					<div className="col-12 col-md-6">
						<Input
							type="text"
							id="name"
							name="name"
							value={values.name}
							isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							labelValue={`${translationService.translate("TR_NAME")}*`}
							onChange={event => onChange(event, "name")}
						/>
					</div>
					<div className={`col-12 col-md-6  my-1`}>
						<div className="position-relative">
							<Input
								type="value"
								id="value"
								name="value"
								value={values.value}
								// fieldLength={fieldsLength.nameMaxLength}
								isInvalidSubmit={isInvalidSubmit && !values.value}
								labelValue={`${translationService.translate("TR_VALUE")}*`}
								onChange={event => onChange(event, "value")}
							/>
						</div>
					</div>
					<div className="col-12">
						<Textarea
							rows="3"
							id="description"
							name="description"
							value={values.description}
							labelValue={`${translationService.translate(
								"TR_WL_DESCRIPTION",
							)}`}
							onChange={event => onChange(event, "description")}
						/>
					</div>
					<div className="col-12 col-md-6  my-1">
						<label htmlFor="isdefault">
							{translationService.translate("TR_IS_DEFAULT")}
						</label>
						<InputCheckBox
							id={`isdefault`}
							name={`isdefault`}
							checked={values.isDefault}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "isDefault");
							}}
						/>
					</div>
					<div className="col-12 col-md-6  my-1">
						<label htmlFor="isreadonly">
							{translationService.translate("TR_IS_READONLY")}
						</label>
						<InputCheckBox
							id={`isreadonly`}
							name={`isreadonly`}
							checked={values.isReadonly}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "isReadonly");
							}}
						/>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div>
							<ActionButton
								spinnerId={buttonSpinnerId}
								clicked={onSubmit}
								type="submit"
								className="mindalay--btn-default float-right mb-4 px-5"
								name={translationService.translate("TR_SAVE")}
							/>
						</div>
					</div>
				</form>
			</div>
		)
	);
}
export default withRouter(ConfigForm);
