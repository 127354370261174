import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import ModalSpinner from "../Spinners/modalSpinner";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { logout, userUpdateSuccess } from "../../Store/Actions/user";
import { setUserType } from "./../../Store/Actions/user";
import { ALL_VALID_USER_TYPES, ERROR_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import uuid from "react-uuid";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";

export default function ChooseUserTypeModal(props) {
	const dispatch = useDispatch();

	const [translationService, setTranslationService] = useState(null);
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const { modalSpinners } = useSelector(state => state.spinner);
	const { globalConnection } = useSelector(state => state.signalR);
	const [isShowModal, setIsShowModal] = useState(true);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const changeUserType = userTypeId => {
		setIsShowModal(false);
		if (props.setOpen) {
			props.setOpen(false);
		}
		const userData = { ...user };
		if (userTypeId === 2) {
			userData.isAdmin = true;
		}
		userData.userTypeId = userTypeId;
		updateUserStorageData(userData);
		dispatch(setUserType(userTypeId));
	};

	const updateUserStorageData = userData =>
		dispatch(userUpdateSuccess(userData));

	const logoutHandler = useCallback(() => {
		if (globalConnection && globalConnection.state === "Connected") {
			globalConnection.stop();
		}
		setIsShowModal(false);
		// dispatch(logout());
		logoutUser();
	}, [globalConnection]);

	const logoutUser = () => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.logoutUser().then(() => {
		}).catch(error => {
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		}).finally(() => {
			dispatch(logout());
			dispatch(removePageSpinner(spinnerId));
			window.location.href = "/"
		})
	}

	const cancel = () => {
		if (ALL_VALID_USER_TYPES.includes(user.userTypeId)) {
			setIsShowModal(false);
			if (props.setOpen) {
				props.setOpen(false);
			}
		} else {
			return;
		}
	};

	return translationService &&
		user &&
		user.defaultUserTypeIds &&
		(isShowModal || props.open) &&
		(props.open || !ALL_VALID_USER_TYPES.includes(user.userTypeId)) ? (
		<Modal size={"lg"} show={true} onHide={cancel}>
			<Modal.Header>
				{translationService.translate("TR_CHOOSE_USER_TYPE_MODAL_TITLE")} ?
			</Modal.Header>
			<Modal.Body>
				<div className="d-flex">
					{user.defaultUserTypeIds
						.filter(type => type !== user.userTypeId)
						.map(defaultUserTypeId => {
							return (() => {
								switch (defaultUserTypeId) {
									case 2:
										return (
											<button
												key={defaultUserTypeId}
												type="button"
												className="mindalay--btn-dark m-1 flex-1"
												disabled={modalSpinners.length}
												onClick={() => changeUserType(2)}>
												{translationService.translate("TR_ADMIN")}
											</button>
										);
									case 3:
										return (
											<button
												key={defaultUserTypeId}
												type="button"
												className="mindalay--btn-dark m-1 flex-1"
												disabled={modalSpinners.length}
												onClick={() => changeUserType(3)}>
												{translationService.translate("TR_CURATOR")}
											</button>
										);
									case 5:
										return (
											<button
												key={defaultUserTypeId}
												type="button"
												className="mindalay--btn-dark m-1 flex-1"
												disabled={modalSpinners.length}
												onClick={() => changeUserType(5)}>
												{translationService.translate("TR_LECTURER")}
											</button>
										);
									default:
										break;
								}
							})();
						})}
				</div>
			</Modal.Body>

			<Modal.Footer>
				<div>
					{props.open ? (
						<button
							type="button"
							className="mindalay--btn-red mindalay--btn-small m-1"
							disabled={modalSpinners.length}
							onClick={cancel}>
							{translationService.translate("TR_CLOSE")}
						</button>
					) : (
						<button
							type="button"
							className="mindalay--btn-red mindalay--btn-small m-1"
							disabled={modalSpinners.length}
							onClick={logoutHandler}>
							{translationService.translate("TR_LOG_OUT")}
						</button>
					)}
				</div>
			</Modal.Footer>
			<ModalSpinner spinners={modalSpinners} />
		</Modal>
	) : null;
}
