import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../../Services/translationService";
import { showOrHideWebinarScreenNotification } from "../../../../Store/Actions/signalR";
import { logout } from "../../../../Store/Actions/user";
import { EDIT_PROFILE_KEY } from "../../../../Constants/urlKeys";
import { changeShoppingCartOrWishListCount } from "../../../../Store/Actions/wishListOrShoppingCart";
import { showOrHideGeneralDashboardLeftMenu } from "../../../../Store/Actions/generalLeftMenu";
import { setSettings } from "../../../../Store/Actions/settings";

const AdminProfileDropdown = props => {
	const dispatch = useDispatch();

	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const globalConnection = useSelector(state => state.signalR.globalConnection);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<Dropdown>
			<div className="navbar-profile-dropdown">
				<Dropdown.Toggle variant="transparent">
					<div
						className="navbar-profile-image"
						style={{ backgroundImage: `url(${user.avatarFilePath})` }}
						onClick={() =>
							dispatch(showOrHideGeneralDashboardLeftMenu(false))
						}></div>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item
						className="profile-nav-link w-100"
						as={Link}
						to={`/${language}/${EDIT_PROFILE_KEY}`}>
						<div className="d-flex align-items-center">
							<div
								className="navbar-profile-image"
								style={{
									backgroundImage: `url(${user.avatarFilePath})`,
								}}></div>
							<div className="ml-3 user-profil-info-wrapper">
								<h2 className="profile-user-fullname">
									{user.firstname} {user.lastname}
								</h2>
								<small>{user.email}</small>
							</div>
						</div>
					</Dropdown.Item>
					<ul className="header-popup">
						<li className="d-none">
							<hr />
						</li>
						<li className="nav--item">
							<Link to={`/${language}/logout`} className="profile-nav-link w-100">
								{translationService.translate("TR_LOG_OUT")}
							</Link>
						</li>
					</ul>
				</Dropdown.Menu>
			</div>
		</Dropdown>
	) : null;
};

export default withRouter(AdminProfileDropdown);
