import React, { useEffect } from "react";
import ApiService from "../../Services/apiService";
import { useDispatch, useSelector } from "react-redux";
import AlertService from "../../Services/alertService";
import { logout } from "../../Store/Actions/user";
import uuid from "react-uuid";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { ERROR_KEY } from "../../Constants/mainKeys";
import { withRouter } from "react-router-dom";
import { changeShoppingCartOrWishListCount } from "../../Store/Actions/wishListOrShoppingCart";
import { showOrHideWebinarScreenNotification } from "../../Store/Actions/signalR";
import { setSettings } from "../../Store/Actions/settings";

const LogOut = (props) => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    const { language } = useSelector(state => state.language);
    const { globalConnection, globalUserConnection } = useSelector(state => state.signalR);

    useEffect(() => {
        logoutUser();
    }, [user])

    const logoutUser = () => {
        if(!user){return false;}
        const spinnerId = uuid();
        dispatch(addPageSpinner(spinnerId));
        ApiService.logoutUser().then(() => {
        }).catch(error => {
            AlertService.alert(
                AlertService.checkMessageType(error.respcode) || ERROR_KEY,
                error,
            );
        }).finally(() => {
            dispatch(logout());
            if (globalConnection && globalConnection.state === "Connected") {
                globalConnection.stop();
            }
            if (globalUserConnection && globalUserConnection.state === "Connected") {
                globalUserConnection.stop();
            }
            dispatch(changeShoppingCartOrWishListCount(0, 0));
            dispatch(showOrHideWebinarScreenNotification(null));
            dispatch(setSettings(null));
            document.documentElement.style.setProperty(
                "--mindalay--brand-color",
                "#32064A",
            );
            document.documentElement.style.setProperty(
                "--mindalay--brand-color-darken-35",
                "#220432",
            );
            document.documentElement.style.setProperty(
                "--mindalay--secondary-color",
                "#E56B1F",
            );
            document.documentElement.style.setProperty(
                "--mindalay--secondary-color-lightened-50",
                "#ffbf9f",
            );
            document.documentElement.style.setProperty(
                "--mindalay--secondary-color-lightened-minus-5",
                "#e26418",
            );
            dispatch(removePageSpinner(spinnerId));
            props.history.push(`/${language}`)
        })
    }

    return <div></div>;
}

export default withRouter(LogOut);