import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import ReactPaginate from "react-paginate";
import _InfoSvg from "../../Components/Svg/_infoSvg";
import EditSvg from "../../Components/Svg/editSvg";
import DeleteSvg from "../../Components/Svg/deleteSvg";
import TranslationService from "../../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import Input from "../../Components/Inputs/input";
import ModalComponentWithoutActionButton from "../../Components/ModalComponent/modalComponentWithoutActionButton";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import CustomTitle from "../../Components/CustomTitle/customTitle";
import ViewSvg from "../../Components/Svg/viewSvg";

const UserTypes = props => {
	const pageSize = 10;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [userTypes, setUserTypes] = useState([]);
	const [userTypeId, setUserTypeId] = useState([]);
	const [pageId, setPageId] = useState("");
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [pages, setPages] = useState([]);
	const [values, setValues] = useState({
		name: "",
		defaultUserTypeIds: [],
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [defaultUserTypes, setDefaultUserTypes] = useState(null);
	const [pagerole, setPagerole] = useState(null);
	const [pageroleModalOpen, setPageroleModalOpen] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getUsertypes(null);
	}, []);

	useEffect(() => {
		if (pages && pages.length) {
			scrollToCurrentPosition("pages");
		}
	}, [pages]);

	const scrollToCurrentPosition = id => {
		const currentElement = document.getElementById(id);
		if (currentElement) {
			setTimeout(() => {
				currentElement &&
					window.scrollTo({
						top:
							currentElement?.getBoundingClientRect().top +
							document.documentElement.scrollTop -
							100,
						behavior: "smooth",
					});
			}, 500);
		}
	};

	const handlePageClick = event => {
		getUsertypes(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getUsertypes = orgId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getUsertypes(orgId)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					setUserTypes(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getPageusertype = usertypeId => {
		if (!userTypeId) return null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPageusertype(usertypeId)
			.then(response => {
				if (
					response &&
					response.data &&
					response.data.pages &&
					response.data.pages.length
				) {
					setPages(response.data.pages);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deleteType = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteUsertype(item.id)
				.then(() => {
					setUserTypes(userTypes.filter(_item => _item.id !== item.id));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};
	const onCheckboxChange = data => {
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.postPageusertype(data)
				.then(() => {
					getPageusertype(userTypeId);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	const onClose = (close = true) => {
		setValues({
			name: "",
			defaultUserTypeIds: [],
		});
		setIsChange(false);
		setIsInvalidSubmit(false);
		setOpenModal(false);
		setPagerole(null);
		setPageroleModalOpen(false);
	};

	const getPagerole = (pageId, usertypeId) => {
		if (!pageId || !usertypeId) return false;
		setPageroleModalOpen(true);
		const spinerId = uuid();
		dispatch(addModalSpinner(spinerId));
		ApiService.getPagerole(pageId, usertypeId)
			.then(response => {
				if (response.data && response.data) {
					setPagerole(response.data);
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
				onClose();
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
	};

	const getModalData = id => {
		setOpenModal(true);
		const spinerId = uuid();
		dispatch(addModalSpinner(spinerId));
		ApiService.getDefaultUserType()
			.then(response => {
				if (response.data && response.data.length) {
					setDefaultUserTypes(response.data);
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
				onClose();
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
		if (id) {
			const spinerId = uuid();
			dispatch(addModalSpinner(spinerId));
			ApiService.getUserTypeById(id)
				.then(response => {
					if (response.data) {
						setValues({
							id: response.data.id || "",
							name: response.data.name || "",
							defaultUserTypeIds: response.data.defaultUserTypeIds || [],
						});
					}
				})
				.catch(error => {
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
					onClose();
				})
				.finally(() => {
					dispatch(removeModalSpinner(spinerId));
				});
		}
	};

	const onSave = () => {
		const spinerID = uuid();
		if (!values.name.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addModalSpinner(spinerID));
		(!values?.id
			? ApiService.createUserType(values)
			: ApiService.updateUserType(values)
		)
			.then(response => {
				if (!values?.id) {
					setUserTypes([...userTypes, response.data]);
				} else {
					setUserTypes(
						userTypes.map(item => {
							if (item.id === values.id) {
								return {
									...item,
									name: values.name,
								};
							}
							return item;
						}),
					);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_USER_TYPE_FORM_ALERT_MSG"),
				);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
				onClose(false);
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};

	const onModalCheckBoxChange = (event, id) => {
		setIsChange(true);
		if (!event.target.checked) {
			const selectedValue = values.defaultUserTypeIds.filter(el => el !== id);
			setValues(values => ({ ...values, defaultUserTypeIds: selectedValue }));
		} else {
			setValues(values => ({
				...values,
				defaultUserTypeIds: [...values.defaultUserTypeIds, id],
			}));
		}
	};

	const rolsChange = (event, item) => {
		if (!item) return false;
		const spinerId = uuid();
		dispatch(addModalSpinner(spinerId));
		const data = {
			create: event.target.checked,
			roleId: item.id,
			usertypeId: userTypeId,
		};
		ApiService.changeUserTypeRole(data)
			.then(response => {
				const newrols = pagerole.roles.map(item => {
					if (item.id === data.roleId) {
						return {
							...item,
							isBelongToCurrentUserType: data.create,
						};
					}
					return item;
				});
				setPagerole(prev => ({
					...prev,
					roles: newrols,
				}));
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
				onClose();
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
	};

	return translationService ? (
		<div className="container">
			{pageroleModalOpen && (
				<ModalComponentWithoutActionButton
					cancel={onClose}
					isLarge={true}
					title={translationService.translate("TR_USER_TYPE_ROLES")}>
					{pagerole && pagerole.roles && pagerole.roles.length ? (
						<div className="row">
							{pagerole.roles.map((item, i) => (
								<div className="col-12 col-md-6" key={i}>
									<InputCheckBox
										id={`pagerole${item.id}`}
										name={`pagerole${item.id}`}
										checked={item.isBelongToCurrentUserType}
										disabled={
											item.isBelongToCurrentUserType &&
											item.name.includes("view") &&
											!item.name.includes("password")
										}
										labelValue={item.name}
										onChange={event => {
											rolsChange(event, item);
										}}
									/>
								</div>
							))}
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponentWithoutActionButton>
			)}
			{openModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						onSave(openModal);
					}}
					addButtonIsDisabled={!isChange}
					isLarge={true}
					title={
						!values?.id
							? translationService.translate("TR_CREATE")
							: translationService.translate("TR_UPDATE")
					}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					{defaultUserTypes?.length ? (
						<div className="row">
							<div className="col-12">
								<Input
									type="text"
									id="name"
									name="name"
									inputClassName="pr--5"
									value={values.name}
									fieldLength={40}
									isInvalidSubmit={
										isInvalidSubmit && !values.name.trim().length
									}
									labelValue={`${translationService.translate("TR_NAME")}*`}
									onChange={event => onChange(event, "name", 40)}
								/>
							</div>
							<div className="col-12">
								<label>
									{translationService.translate("TR_SELECTDEF_USERTYPES")}
								</label>
								<hr className="mt-0" />
							</div>
							{defaultUserTypes.map((item, i) => (
								<div className="col-12 col-md-6" key={i}>
									<InputCheckBox
										id={`defaultUserTypes_${item.id}`}
										name={`defaultUserTypes_${item.id}`}
										checked={
											values.defaultUserTypeIds?.filter(el => el === item.id)
												.length
										}
										labelValue={translationService.translate(item.displayName)}
										onChange={event => {
											onModalCheckBoxChange(event, item.id);
										}}
									/>
								</div>
							))}
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_USER_TYPES_PAGE")}
					</h2>
					{/* <PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/> */}
					<PageInfoBlock
						pageTitle={translationService.translate("TR_USER_TYPES_PAGE")}
						pageName="admin_user_types"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-end justify-content-end">
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<button
						className="mindalay--btn-default"
						onClick={() => {
							getModalData();
						}}>
						{translationService.translate("TR_CREATEUSERTYPE")
							? translationService.translate("TR_CREATEUSERTYPE")
							: translationService.translate("TR_UPDATE_USER_TYPE")}
					</button>
				</div>
			</div>
			{userTypes && userTypes.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_USER_TYPES_PAGE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ORGANIZATION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_IS_DEFAULT")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{userTypes.map((item, index) => {
										return (
											<tr
												key={index}
												style={{
													backgroundColor:
														item.id === userTypeId
															? "var(--mindalay--gray-color-lightened-90)"
															: "",
													cursor: "default",
												}}>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{translationService.translate(item.displayName)
															? translationService.translate(item.displayName)
															: item.name}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.organizationName}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.isDefault
															? translationService.translate("TR_YES")
															: translationService.translate("TR_NO")}
													</div>
												</td>
												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														{item.isDefault ? (
															""
														) : (
															<>
																<CustomTitle
																	title={translationService.translate(
																		"TR_EDIT",
																	)}>
																	<Link
																		to={"#"}
																		onClick={e => {
																			e.stopPropagation();
																			getModalData(item.id);
																		}}
																		className="table-action-btn edit-btn">
																		<EditSvg />
																	</Link>
																</CustomTitle>
																<CustomTitle title={"Open pages"}>
																	<Link
																		to="#"
																		className="table-action-btn add-btn"
																		onClick={e => {
																			e.stopPropagation();
																			if (!item.isDefault) {
																				setUserTypeId(item.id);
																				getPageusertype(item.id);
																			}
																		}}>
																		<ViewSvg />
																	</Link>
																</CustomTitle>
																<CustomTitle
																	title={translationService.translate(
																		"TR_DELETE",
																	)}>
																	<Link
																		to="#"
																		className="table-action-btn delete-btn"
																		onClick={e => {
																			e.stopPropagation();
																			deleteType(item);
																		}}>
																		<DeleteSvg />
																	</Link>
																</CustomTitle>
															</>
														)}
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
			{pages && pages.length ? (
				<div id="pages" className="row mt-3">
					<div className="col-12 col-md-6">
						<div className="card mindalay-card">
							<div className="card-header  d-flex justify-content-center align-items-center">
								<h6 className="mb-0 text-elipsis">
									{translationService.translate("TR_PAGES_PAGE")}
								</h6>
							</div>
							{pages.map((item, index) => (
								<div key={index}>
									<div className="py-2 px-3">
										<div className="d-flex justify-content-between align-items-center">
											<div className="table-action-btn-group d-flex align-items-center">
												{item.uri !== "/" ? (
													<CustomTitle title={"Show types"}>
														<Link
															to="#"
															className="table-action-btn add-btn sm p-1 d-flex"
															onClick={() => {
																if (item.uri !== "/") {
																	getPagerole(item.id, userTypeId);
																}
															}}>
															<ViewSvg />
														</Link>
													</CustomTitle>
												) : null}
												<label className="cursor-pointer">
													{translationService.translate(item.name)}
												</label>
											</div>

											<InputCheckBox
												id={`pages${item.id}`}
												blockClassName="mb-0"
												name="recurringPayment"
												checked={item.isBelongToCurrentUserType}
												onChange={event => {
													const data = {
														create: event.target.checked,
														pageId: item.id,
														usertypeId: userTypeId,
													};
													onCheckboxChange(data, index);
												}}
												labelValue={" "}
											/>
										</div>
									</div>
									{item.children && item.children.length ? (
										<div className="p-2 m-2 border rounded shadow-lg">
											{item.children.map((el, i, arr) => (
												<div
													key={i}
													className={`p-2 ${i !== arr.length - 1 ? "border-bottom" : ""
														} `}>
													<div className="d-flex justify-content-between align-items-center">
														<div className="table-action-btn-group d-flex align-items-center">
															{el.uri !== "/" ? (
																<CustomTitle title={"Show types"}>
																	<Link
																		to="#"
																		className="table-action-btn add-btn sm p-1 d-flex"
																		onClick={() => {
																			if (el.uri !== "/") {
																				setPageId(el.id);
																				getPagerole(el.id, userTypeId);
																			}
																		}}>
																		<ViewSvg />
																	</Link>
																</CustomTitle>
															) : null}

															<label className="cursor-pointer">
																{translationService.translate(el.name)}
															</label>
														</div>
														<InputCheckBox
															id={`pages${el.id}`}
															name="recurringPayment"
															blockClassName="mb-0"
															checked={el.isBelongToCurrentUserType}
															onChange={event => {
																const data = {
																	create: event.target.checked,
																	pageId: el.id,
																	usertypeId: userTypeId,
																};
																onCheckboxChange(data, index, i);
															}}
															labelValue={" "}
														/>
													</div>
												</div>
											))}
										</div>
									) : null}
									{pages.length - 1 !== index ? (
										<hr className={`my-1`} />
									) : null}
								</div>
							))}
						</div>
					</div>
				</div>
			) : null}
			{pagination && pagination.TotalPages > 1 ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default withRouter(UserTypes);
