import axios from "axios";
import { IDENTITY_GET_TOKEN_PATH_KEY } from "../Constants/requestKeys";

const identityApi = axios.create({
	baseURL: process.env.REACT_APP_IDENTITY_API_URL,
	withCredentials: true,
});

identityApi.interceptors.request.use(
	config => {
		config.headers = {
			// "Access-Control-Allow-Origin": "*",
			"ngrok-skip-browser-warning": true,
		};

		return config;
	},
	err => Promise.reject(err),
);

export class IdentityService {
	static login(data) {
		return identityApi.post("/account/login", data);
	}
}
