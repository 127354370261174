import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../../Components/Inputs/input';
import ReactSelectOption from '../../Components/SelectOption/reactSelectOption';
import CloseSvg from '../../Components/Svg/closeSvg';
import InfoSvg from '../../Components/Svg/infoSvg';
import moment from 'moment';
import MainService from '../../Services/mainService';
import TranslationService from '../../Services/translationService';
import PlusSvg from '../../Components/Svg/plusSvg';
import ApiService from '../../Services/apiService';
import uuid from 'react-uuid';
import AlertService from '../../Services/alertService';
import Textarea from '../../Components/Inputs/textArea';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import StarSvg from '../../Components/Svg/starSvg';
import StarFilled from '../../Components/Svg/starFilled';
import { addPageSpinner, removePageSpinner, addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { RATINGS, CONSULTATION_LEVELS, SUCCESS_KEY, LANGUAGES_KEY, CONSULTATION_CHAT_TYPE_KEY, CONSULTATION_CONTRACT_BACK_KEY } from "../../Constants/mainKeys";
import { ERROR_KEY, NUMBER_KEY } from '../../Constants/mainKeys';
import ConsultantBlock from './consultantBlock';
import { ALL_CONSULTATIONS_KEY } from '../../Constants/urlKeys';
import { Link } from 'react-router-dom';
import ArrowBackSvg from '../../Components/Svg/arrowBackSvg';
import ActionButton from '../../Components/ActionButton/actionButton';

class CreateConsultation extends Component {
  ratings = RATINGS;
  levels = CONSULTATION_LEVELS;
  consultationChatType = CONSULTATION_CHAT_TYPE_KEY;

  state = {
    form: {
      themeId: 0,
      categoryId: 0,
      preferredDay: "",
      preferredStartTime: 0,
      preferredEndTime: 0,
      maxCost: "",
      durationMax: 30,
      experienceLevel: null,
      yearsOfExperience: "",
      consultationLanguages: [],
      consultantRating: 0,
      consultantIds: [],
      description: '',
      currencyId: 1,
      pageSize: 5,
      pageNumber: 1,
    },
    consultationId: +this.props.match.params.consultationId,
    languageId: null,
    language: null,
    languageLevel: null,
    languageLabel: null,
    firstLevelCategoryId: null,
    secondLevelCategorId: null,
    defaultValue: { label: "", value: "" },
    secondLevelCategories: [],
    thirdLevelCategories: [],
    translationService: null,
    categoryBlockName: "",
    isInvalidSubmit: false,
    constltantsList: [],
    isInvalidDate: false,
    preferredDayOnBlur: false,
    languageLevelsData: [],
    currencies: [],
    currencyRate: 1,
    languages: localStorage.getItem(LANGUAGES_KEY) || null,
    descriptionMaxLength: 2000,
    contentLanguages: [],
    pagination: null,
    buttonSpinnerId: uuid(),
  }

  componentDidMount() {
    this.setTranslations();
    this.getConsultationSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getConsultationSettings = () => {
    const { consultationId } = this.state;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getConsultationSettings().then(response => {
      if (response.data) {
        this.setState(prevState => ({
          ...prevState,
          currencies: response.data?.currencies,
          languageLevelsData: response.data?.language_levels,
          contentLanguages: response.data?.languages,
          form: {
            ...prevState.form,
            // durationMax: response.data?.minDuration
          }
        }), () => {
          consultationId && this.getConsultationById(consultationId);
        })
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  getConsultationById = (consultationId) => {
    var { languages, languageLevelsData, contentLanguages } = this.state;
    languages = languages ? JSON.parse(languages) : this.props.languages ? this.props.languages : [];
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getConsultationById(consultationId).then(response => {
      this.props.removePageSpinner(spinnerId);
      if (response && response.data) {
        const data = { ...response.data };
        var secondLevelCategories = [];
        var thirdLevelCategories = [];
        var consultationLanguages = data.consultationLanguages;
        if ((data.mainCategoryId || typeof data.mainCategoryId === NUMBER_KEY) && this.props.courseCategories) {
          this.props.courseCategories.forEach(courseCategory => {
            if (courseCategory.id === data.mainCategoryId) {
              secondLevelCategories = courseCategory.children ? courseCategory.children : courseCategory.themeChildren;
            }
          })
        }
        if ((data.categoryId || typeof data.categoryId === NUMBER_KEY) && secondLevelCategories) {
          secondLevelCategories.forEach(secondLevelCategory => {
            if (secondLevelCategory.id === data.categoryId) {
              thirdLevelCategories = secondLevelCategory.children ? secondLevelCategory.children : secondLevelCategory.themeChildren;
            }
          })
        }
        if (
          consultationLanguages
          && consultationLanguages.length
          && languages
          && languages.length
          && languageLevelsData
          && languageLevelsData.length
        ) {
          consultationLanguages.forEach(consultationLanguage => {
            contentLanguages.forEach(lang => {
              if (lang.id === consultationLanguage.languageId) {
                return consultationLanguage.language = lang.name
              }
            })
            languageLevelsData.forEach(level => {
              if (consultationLanguage.languageLevel === +level.name) {
                return consultationLanguage.languageLabel = level.displayName
              }
            })
          })
        }
        this.setState(prevState => ({
          ...prevState,
          languageId: data.languageId,
          firstLevelCategoryId: data.mainCategoryId,
          secondLevelCategories,
          thirdLevelCategories,
          form: {
            ...prevState.form,
            themeId: data.themeId,
            categoryId: data.categoryId,
            description: data.description ? data.description : "",
            experienceLevel: data.experienceLevel ? data.experienceLevel : null,
            durationMax: data.durationMax ? data.durationMax : "",
            consultationLanguages: data.consultationLanguages,
            maxCost: data.maxCost ? data.maxCost : "",
            yearsOfExperience: data.yearsOfExperience ? data.yearsOfExperience : "",
            preferredDay: data.preferredDay ? moment((new Date(data.preferredDay))).format("YYYY-MM-DD") : "",
            currencyId: data.currencyId ? data.currencyId : 1,
            preferredEndTime: data.preferredEndTime ? this.convertUtcDayTimeToLocal(data.preferredEndTime.hours, data.preferredEndTime.minutes) : "",
            preferredStartTime: data.preferredStartTime ? this.convertUtcDayTimeToLocal(data.preferredStartTime?.hours, data.preferredStartTime?.minutes) : "",
          }
        }))
      }
    }).catch(error => this.getFail(spinnerId, error))
  }

  onChangeCurrensy = (selectedCategory) => {
    this.setState(prevState =>
    ({
      ...prevState,
      currencyRate: selectedCategory.value,
      form: {
        ...prevState.form,
        currencyId: selectedCategory.id
      }
    }))
  }

  convertUtcDayTimeToLocal = (hh, mm) => {
    var d = new Date();
    var date = new Date(d.setHours(hh, mm))
    const localDate = MainService.convertUTCDateToLocalDate(date);
    let hours = localDate.getHours();
    let minutes = localDate.getMinutes()
    if (hours.toString().length === 1) {
      hours = "0" + hours
    }
    if (minutes.toString().length === 1) {
      minutes = "0" + minutes
    }
    return `${hours}:${minutes}`
  }

  setFirstSecondLevelCategory = (selectedCategory, name, themeId) => {
    let categoryId = 0;
    if (name === "thirdLevelCategories") categoryId = selectedCategory.id;
    if (name === "secondLevelCategories") {
      this.setState({
        thirdLevelCategories: []
      })
    }
    if (selectedCategory && name && themeId) {
      this.setState(prevState => ({
        ...prevState,
        [name]: selectedCategory.children ? selectedCategory.children : selectedCategory.themeChildren,
        [themeId]: selectedCategory.id,
        categoryBlockName: '',
        constltantsList: [],
        form: {
          ...prevState.form,
          categoryId,
          themeId: 0,
          consultantIds: []
        }
      }))
    }
  }

  setThirdLevelCategory = (selectedCategory, themeId) => {
    const thirdLevelCategories = [...this.state.thirdLevelCategories];
    let { categoryBlockName } = this.state;
    if (selectedCategory) {
      thirdLevelCategories.forEach(category => {
        if (+category.id === +selectedCategory.id) {
          categoryBlockName = category.name;
        }
      });
      this.setState(prevState => ({
        ...prevState,
        categoryBlockName,
        constltantsList: [],
        form: {
          ...prevState.form,
          [themeId]: selectedCategory.id,
          consultantIds: [],
          pageNumber: 1,
        }
      }), () => this.filterConsultants())
    }
  }

  removeCategory = () => {
    this.setState(prevState => ({
      ...prevState,
      categoryBlockName: '',
      constltantsList: [],
      form: {
        ...prevState.form,
        themeId: 0,
        consultantIds: []
      }
    }))
  }

  checkDateFormatAndIsValid = (date) => {
    const isInvalidDateFormat = MainService.isValidDateTime(date);
    const isInvalidDate = MainService.validateOnlyDate(date);
    if (!isInvalidDateFormat || !isInvalidDate) {
      this.setState({ isInvalidDate: true });
    } else this.setState({ isInvalidDate: false });
  }

  onDateChange = event => {
    this.setState(prevState => ({
      ...prevState,
      isInvalidDate: false,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  }

  onTiemChange = event => {
    var isValid;
    if (event.target.name === "preferredStartTime" || event.target.name === "preferredEndTime") isValid = MainService.isValidTime(event.target.value);
    if (isValid) {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          [event.target.name]: event.target.value,
          pageNumber: 1,
        }
      }), () => this.filterConsultants());
    }
  };

  experienceLevelChange = (selectedCategory, name) => {
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: selectedCategory.value + 1,
        pageNumber: 1,
      }
    }), () => this.filterConsultants());
  }

  onNumberChange = event => {
    const { currencyRate } = this.state;
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value.includes("e") || event.target.value.includes(".")) { return false };
    if (event.target.name === "yearsOfExperience") {
      if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0 && Number(event.target.value) <= 100)) {
        this.setState(prevState => ({ ...prevState, form: { ...prevState.form, [event.target.name]: +event.target.value } }));
      }
    }
    if (event.target.name === "maxCost") {
      if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0 && Number(event.target.value) * currencyRate <= 100000)) {
        this.setState(prevState => ({ ...prevState, form: { ...prevState.form, [event.target.name]: +event.target.value } }));
      }
    } else {
      if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0)) {
        this.setState(prevState => ({ ...prevState, form: { ...prevState.form, [event.target.name]: +event.target.value } }));
      }
    }
  };

  onLanguageChange = (selectedCategory, name) => {
    if (selectedCategory) {
      if (name === "languageId" && selectedCategory) {
        this.setState({ [name]: selectedCategory.id, language: selectedCategory.label });
      }
      if (name === "languageLevel" && selectedCategory) {
        this.setState({ [name]: +selectedCategory.name, languageLabel: selectedCategory.label })
      }
    }
  }

  addLanguage = () => {
    const { languageId, language, languageLevel, languageLabel } = this.state;
    const { consultationLanguages } = this.state.form;
    if (!consultationLanguages.length && languageId >= 0 && language && languageLevel >= 0 && languageLabel) {
      consultationLanguages.push({
        languageId,
        language,
        languageLevel,
        languageLabel
      });
      this.setState(prevState => ({
        ...prevState,
        isInvalidSubmit: false,
        form: {
          ...prevState.form,
          consultationLanguages,
          pageNumber: 1,
        }
      }), () => this.filterConsultants());
    } else {
      if (languageId >= 0 && language && languageLevel >= 0 && languageLabel) {
        const isExist = consultationLanguages.find(lang => lang.languageId === languageId);
        if (!isExist) {
          consultationLanguages.push({
            languageId,
            language,
            languageLevel,
            languageLabel
          });
          this.setState(prevState => ({
            ...prevState,
            isInvalidSubmit: false,
            form: {
              ...prevState.form,
              consultationLanguages,
              pageNumber: 1,
            }
          }), () => this.filterConsultants());
        }
      }
    }
  }

  removeLanguage = (item, array) => {
    var indexArray = array.findIndex(o => {
      if (item.languageId !== undefined) {
        return o.language === item.language && o.languageId === item.languageId && o.languageLabel === item.languageLabel && o.languageLevel === item.languageLevel;
      } else { return false; }
    })
    if (indexArray !== -1) {
      array.splice(indexArray, 1);
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          consultationLanguages: array,
          pageNumber: 1,
        }
      }), () => this.filterConsultants());
    }
  }

  convertUtcDayTimeToLocal = (hh, mm) => {
    var d = new Date();
    var date = new Date(d.setHours(hh, mm))
    const localDate = MainService.convertUTCDateToLocalDate(date);
    let hours = localDate.getHours();
    let minutes = localDate.getMinutes()
    if (hours.toString().length === 1) {
      hours = "0" + hours
    }
    if (minutes.toString().length === 1) {
      minutes = "0" + minutes
    }
    return `${hours}:${minutes}`
  }

  addConsultantToList = (event, id) => {
    var consultantIds = [...this.state.form.consultantIds];
    if (id) {
      if (event.target.checked) {
        consultantIds.push(id);
        this.setState(prevState => ({ ...prevState, form: { ...prevState.form, consultantIds } }));
      } else {
        const index = consultantIds.indexOf(id);
        if (index > -1) {
          consultantIds.splice(index, 1);
        }
        this.setState(prevState => ({ ...prevState, form: { ...prevState.form, consultantIds } }));
      }
    } else { return };
  }

  onChangeRating = (rating) => {
    if (rating) {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          consultantRating: rating,
          pageNumber: 1,
        }
      }), () => {
        this.filterConsultants();
      })
    }
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  }

  checkDuration = () => {
    const { durationMax } = this.state.form;
    if (durationMax < 30) {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          durationMax: 30,
          pageNumber: 1,
        }
      }), () => {
        this.filterConsultants();
      })
    }
  }

  getNextPageConsultants = () => {
    const { pageNumber } = this.state.form;
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        pageNumber: pageNumber + 1,
      }
    }), () => {
      this.filterConsultants(true);
    })
  }

  filterConsultants = (addNewConsultantsToConstltantsList) => {
    const { constltantsList } = this.state;
    const { consultantIds } = this.state.form;
    this.setState(prevState => ({
      ...prevState,
      constltantsList: !addNewConsultantsToConstltantsList ? [] : constltantsList,
      form: {
        ...prevState.form,
        consultantIds: !addNewConsultantsToConstltantsList ? [] : consultantIds,
      }
    }))
    const form = JSON.parse(JSON.stringify(this.state.form));
    if (form.themeId) {
      const spinnerId = uuid();
      form.durationMax = form.durationMax ? form.durationMax : null;
      form.consultationLanguages = form.consultationLanguages.length ? form.consultationLanguages : null;
      form.preferredDay = form.preferredDay ? form.preferredDay : null;
      form.yearsOfExperience = form.yearsOfExperience ? form.yearsOfExperience : null;
      form.consultantIds = !constltantsList.length ? [] : form.consultantIds;
      form.maxCost = form.maxCost ? form.maxCost : null;
      if (form.preferredStartTime) {
        const split = form.preferredStartTime.split(':');
        const date = new Date();
        date.setHours(+split[0]);
        date.setMinutes(+split[1]);
        form.preferredStartTime = moment.utc(date).format('HH:mm');
      } else {
        form.preferredStartTime = null;
      }
      if (form.preferredEndTime) {
        const split = form.preferredEndTime.split(':');
        const date = new Date();
        date.setHours(+split[0]);
        date.setMinutes(+split[1]);
        form.preferredEndTime = moment.utc(date).format('HH:mm');
      } else {
        form.preferredEndTime = null;
      }
      ApiService.filterConsultants(form).then(response => {
        if (response) {
          var data;
          response.pagination && this.setState({ pagination: JSON.parse(response.pagination) });
          if (!response.data) {
            data = [];
          } else {
            data = [...response.data];
          }
          if (addNewConsultantsToConstltantsList) {
            let constltantsListUpdated = [...constltantsList, ...data];
            this.setState({ constltantsList: constltantsListUpdated });
          } else {
            this.setState({ constltantsList: data });
          }
        }
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    const spinnerId = uuid();
    const { consultationId, isInvalidDate, constltantsList, translationService, buttonSpinnerId } = this.state;
    const form = JSON.parse(JSON.stringify(this.state.form));
    if (!form.themeId || !form.consultationLanguages.length || !form.durationMax || isInvalidDate) {
      this.setState({ isInvalidSubmit: true });
      MainService.scrollToInvalidFieldPosition();
    } else {
      this.props.addButtonSpinner(buttonSpinnerId);
      form.description = form.description ? form.description.trim() : null;
      form.preferredDay = form.preferredDay ? form.preferredDay : null;
      form.yearsOfExperience = +form.yearsOfExperience > 0 ? form.yearsOfExperience : null;
      form.consultantIds = !constltantsList.length ? [] : form.consultantIds;
      // form.maxCost = typeof +form.maxCost === NUMBER_KEY ? form.maxCost : null;
      form.maxCost = form.maxCost ? +form.maxCost : null;
      if (consultationId) { form.id = consultationId }
      if (form.preferredStartTime) {
        const split = form.preferredStartTime.split(':');
        const date = new Date();
        date.setHours(+split[0]);
        date.setMinutes(+split[1]);
        form.preferredStartTime = moment.utc(date).format('HH:mm');
      } else {
        form.preferredStartTime = null;
      }
      if (form.preferredEndTime) {
        const split = form.preferredEndTime.split(':');
        const date = new Date();
        date.setHours(+split[0]);
        date.setMinutes(+split[1]);
        form.preferredEndTime = moment.utc(date).format('HH:mm');
      } else {
        form.preferredEndTime = null;
      }

      (consultationId ? ApiService.updateConsultation(form) : ApiService.createConsultation(form)).then(response => {
        if (response) {
          this.props.history.push(`/${this.props.language}/${ALL_CONSULTATIONS_KEY}`)
          AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CONSULTATION_CREATED"));
        }
        this.props.removeButtonSpinner(buttonSpinnerId);
      }).catch(error => this.getFail(error, spinnerId, buttonSpinnerId));
    }
  }

  goBack = () => {
    const { consulatationContractBackUrl, history } = this.props;
    if (consulatationContractBackUrl) {
      consulatationContractBackUrl && history.push(consulatationContractBackUrl);
      localStorage.removeItem(CONSULTATION_CONTRACT_BACK_KEY);
    } else {
      history.push(`/${this.props.language}`);
    }
  }

  getFail = (error, spinnerId, buttonSpinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
    buttonSpinnerId && this.props.removeButtonSpinner(buttonSpinnerId);
  }

  render() {
    const { courseCategories, languages } = this.props;
    const {
      preferredDay,
      preferredStartTime,
      preferredEndTime,
      maxCost,
      durationMax,
      categoryId,
      themeId,
      experienceLevel,
      yearsOfExperience,
      consultationLanguages,
      description,
      consultantRating,
      currencyId
    } = this.state.form;

    const {
      translationService,
      defaultValue,
      firstLevelCategoryId,
      secondLevelCategories,
      thirdLevelCategories,
      categoryBlockName,
      languageId,
      isInvalidSubmit,
      constltantsList,
      languageLevel,
      isInvalidDate,
      languageLevelsData,
      currencies,
      consultationId,
      descriptionMaxLength,
      contentLanguages,
      pagination,
      buttonSpinnerId
    } = this.state;

    return (translationService && languages && courseCategories && contentLanguages.length ? <section className="m-section">
      <div className="container consultations-container">
        <div className="row">
          <div className="col-12">
            {
              consultationId ?
                <h2 className="content-title p-0">
                  <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}>
                    <ArrowBackSvg />
                  </Link>
                  {translationService.translate("TR_UPDATE_CONSULTATION")}
                </h2>
                : <h2 className="content-title p-0">
                  <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}>
                    <ArrowBackSvg />
                  </Link>
                  {translationService.translate("TR_ORDER_CONSULTATION")}
                </h2>
            }
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={this.onSubmit} onChange={() => this.setState({ isInvalidSubmit: false })}>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        {
                          courseCategories && courseCategories.length ?
                            <div className="form-group">
                              <label htmlFor="">{`${translationService.translate("TR_CATEGORY")}*`}</label>
                              <ReactSelectOption
                                defaultValue={defaultValue}
                                value={themeId ? true : false}
                                selectedValue={(() => {
                                  const selectedValue = { ...courseCategories.find(data => data.id === firstLevelCategoryId) };
                                  if (selectedValue) {
                                    selectedValue.label = selectedValue.name;
                                    selectedValue.value = selectedValue.id;
                                  }
                                  return selectedValue;
                                })()}
                                items={courseCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                                onChange={(item) => this.setFirstSecondLevelCategory(item, "secondLevelCategories", 'firstLevelCategoryId')}
                                isInvalidSubmit={isInvalidSubmit}
                              />
                            </div>
                            : null
                        }
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      {
                        secondLevelCategories && secondLevelCategories.length ?
                          <div className="form-group">
                            <label htmlFor="">{translationService.translate("TR_SUBCATEGORY")}</label>
                            <ReactSelectOption
                              defaultValue={defaultValue}
                              value={themeId ? true : false}
                              selectedValue={(() => {
                                const selectedValue = { ...secondLevelCategories.find(data => data.id === categoryId) };
                                if (selectedValue) {
                                  selectedValue.label = selectedValue.name;
                                  selectedValue.value = selectedValue.id;
                                }
                                return selectedValue;
                              })()}
                              items={secondLevelCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                              onChange={(item) => this.setFirstSecondLevelCategory(item, "thirdLevelCategories", 'categoryId')}
                              isInvalidSubmit={isInvalidSubmit}
                            />
                          </div>
                          : null
                      }
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        {
                          thirdLevelCategories && thirdLevelCategories.length ?
                            <div className="form-group">
                              <label htmlFor="">{translationService.translate("TR_SUBCATEGORY")}</label>
                              <ReactSelectOption
                                defaultValue={defaultValue}
                                value={themeId ? true : false}
                                selectedValue={(() => {
                                  const selectedValue = { ...thirdLevelCategories.find(data => data.id === themeId) };
                                  if (selectedValue) {
                                    selectedValue.label = selectedValue.name;
                                    selectedValue.value = selectedValue.id;
                                  }
                                  return selectedValue;
                                })()}
                                items={thirdLevelCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                                onChange={(item) => this.setThirdLevelCategory(item, "themeId")}
                                isInvalidSubmit={isInvalidSubmit}
                              />
                            </div>
                            : null
                        }
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="selected-item-container">
                        {
                          categoryBlockName ?
                            <div className="selected-item-wrapper">
                              <div className="selected-item">
                                <p>{categoryBlockName}</p>
                              </div>
                              <button type="button" className="mindalay--btn-blue" onClick={this.removeCategory}>
                                <CloseSvg />
                              </button>
                            </div>
                            : null
                        }
                      </div>
                    </div>
                    <div className="col-12"><hr /></div>
                    {
                      themeId ?
                        <Auxiliary>
                          <div className="col-12">
                            <Textarea
                              rows="6"
                              id="description"
                              name="description"
                              textAreaClassName="pr--5"
                              value={description}
                              max={descriptionMaxLength}
                              labelValue={translationService.translate('TR_DESCRIPTION')}
                              onChange={event => this.onChange(event, descriptionMaxLength)}
                            />
                          </div>
                          <div className="col-12"><hr /></div>
                        </Auxiliary>
                        : null
                    }
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="form-group">
                        <div className="d-flex">
                          <label htmlFor="">{translationService.translate("TR_EXPERIENCE_LEVEL")}</label>
                          <div className="info-title d-inline-block">
                            <div>
                              <InfoSvg />
                              <span style={{ display: "none" }}>
                                {translationService.translate("TR_EXPERIENCE_LEVEL_INFO")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <ReactSelectOption
                          defaultValue={defaultValue}
                          selectedValue={{ label: translationService.translate(this.levels[experienceLevel - 1]), value: experienceLevel - 1 }}
                          value={experienceLevel}
                          items={this.levels.map((data, index) => ({ label: translationService.translate(data), value: index }))}
                          onChange={(item) => this.experienceLevelChange(item, "experienceLevel")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12">
                      <Input
                        id="yearsOfExperience"
                        type="number"
                        name="yearsOfExperience"
                        infoText={translationService.translate("TR_EXPERIENCE_LEVEL_INFO")}
                        value={yearsOfExperience}
                        labelValue={translationService.translate("TR_YEARS_OF_EXPERIENCE")}
                        onChange={this.onNumberChange}
                        // onBlur={() => this.filterConsultants()}
                        onBlur={() => this.setState(prevState => ({
                          ...prevState,
                          form: {
                            ...prevState.form,
                            pageNumber: 1,
                          }
                        }), () => {
                          this.filterConsultants();
                        })}
                      />
                    </div>
                    <div className="col-12"><hr /></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="d-sm-flex">
                        <Input
                          type="date"
                          id="preferredDay"
                          name="preferredDay"
                          blockClassName='mx-1'
                          inputClassName={`mr-2 ${isInvalidDate ? "is-invalid error-bordered" : ""}`}
                          labelValue={translationService.translate("TR_PREFERED_DAY")}
                          infoText={translationService.translate("TR_PREFERED_DAY_INFO")}
                          value={preferredDay}
                          onChange={this.onDateChange}
                          onBlur={() => this.checkDateFormatAndIsValid(preferredDay)}
                        />
                        <Input
                          type="time"
                          id="preferredStartTime"
                          name="preferredStartTime"
                          blockClassName='mx-1'
                          labelValue={translationService.translate("TR_START_TIME")}
                          value={preferredStartTime}
                          onChange={this.onTiemChange}
                        />
                        <Input
                          type="time"
                          id="preferredEndTime"
                          name="preferredEndTime"
                          blockClassName='mx-1'
                          labelValue={translationService.translate("TR_END_TIME")}
                          value={preferredEndTime}
                          onChange={this.onTiemChange}
                        />
                      </div>
                      {isInvalidDate ? <small className="red-color">{translationService.translate("TR_INVALID_DATE")}</small> : null}
                    </div>
                    <div className="col-12"><hr /></div>
                    <div className="col-12">
                      <div className="d-sm-flex">
                        <div className="mx-1 form-group currency-select-option">
                          <label htmlFor="">{translationService.translate("TR_CURRENCY")}</label>
                          <ReactSelectOption
                            defaultValue={defaultValue}
                            selectedValue={(() => {
                              const selectedValue = { ...currencies.find(data => data.id === currencyId) };
                              if (selectedValue) {
                                selectedValue.label = selectedValue.isocode;
                                selectedValue.value = selectedValue.rate;
                              }
                              return selectedValue;
                            })()}
                            items={currencies.map(data => ({ label: data.isocode, value: data.rate, id: data.id }))}
                            onChange={(item) => this.onChangeCurrensy(item, "currensy")}
                          />
                        </div>
                        <div className="form-group">
                          <Input
                            type="number"
                            name="maxCost"
                            value={maxCost}
                            blockClassName='mx-1'
                            infoText={translationService.translate("TR_MAX_COST_FOR_CONSULTATION_INFO")}
                            placeholder={translationService.translate("TR_MAX")}
                            labelValue={translationService.translate("TR_MAX_COST")}
                            onChange={this.onNumberChange}
                            // onBlur={(durationMax && typeof maxCost === "number") ? () => this.filterConsultants() : null}
                            onBlur={(durationMax && typeof maxCost === "number")
                              ? () => this.setState(prevState => ({
                                ...prevState,
                                form: {
                                  ...prevState.form,
                                  pageNumber: 1,
                                }
                              }), () => {
                                this.filterConsultants();
                              }) : null}
                          />
                        </div>
                        <div className="form-group">
                          <Input
                            type="number"
                            name="durationMax"
                            min="30"
                            blockClassName='mx-1'
                            infoText={translationService.translate("TR_MIN_TIME_FOR_CONSULTATION_INFO")}
                            value={durationMax}
                            placeholder={translationService.translate("TR_MAX")}
                            labelValue={`${translationService.translate("TR_APPROXIMATE")} ${translationService.translate("TR_DURATION")}`}
                            onChange={this.onNumberChange}
                            isInvalidSubmit={isInvalidSubmit}
                            onBlur={this.checkDuration}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12"><hr /></div>
                    <div className="col-md-6 col-12">
                      <div>
                        <label>{`${translationService.translate("TR_LANGUAGES")}*`}</label>
                        <div className="info-title d-inline-block">
                          <div>
                            <InfoSvg />
                            <span style={{ display: "none" }}>
                              {translationService.translate("TR_LANGUAGES_INFO")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <ReactSelectOption
                          defaultValue={defaultValue}
                          items={contentLanguages.map(data => ({ ...data, label: data.displayName, value: data.id }))}
                          className="w-100 mr-2"
                          value={consultationLanguages.length ? true : false}
                          selectedValue={(() => {
                            const selectedValue = { ...contentLanguages.find(data => data.id === languageId) };
                            if (selectedValue) {
                              selectedValue.label = selectedValue.displayName;
                              selectedValue.value = selectedValue.languageId;
                            }
                            return selectedValue;
                          })()}
                          onChange={(item) => this.onLanguageChange(item, "languageId")}
                          isInvalidSubmit={isInvalidSubmit}
                        />
                        {
                          languageLevelsData ?
                            <ReactSelectOption
                              defaultValue={defaultValue}
                              className="w-100"
                              value={consultationLanguages.length ? consultationLanguages : null}
                              selectedValue={(() => {
                                const selectedValue = { ...languageLevelsData.find(data => +data.name === languageLevel) };
                                if (selectedValue) {
                                  selectedValue.label = selectedValue.displayName;
                                  selectedValue.value = selectedValue.name;
                                }
                                return selectedValue;
                              })()}
                              items={languageLevelsData.map(data => ({ ...data, label: data.displayName, value: data.name }))}
                              onChange={(item) => this.onLanguageChange(item, "languageLevel")}
                              isInvalidSubmit={isInvalidSubmit}
                            />
                            : null
                        }
                        <button type="button" className="mindalay--btn-secondary svg-button ml-2 w-auto" onClick={this.addLanguage}>
                          <PlusSvg />
                        </button>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="selected-item-container">
                        {
                          consultationLanguages.length ?
                            consultationLanguages.map((item, index) => {
                              return <div key={index} className="selected-item-wrapper">
                                <div className="selected-item">
                                  <p>{item.language}</p>
                                  <small>{item.languageLabel}</small>
                                </div>
                                <button
                                  type="button"
                                  className="mindalay--btn-blue"
                                  onClick={() => this.removeLanguage(item, consultationLanguages)}
                                >
                                  <CloseSvg />
                                </button>
                              </div>
                            })
                            : null
                        }
                      </div>
                    </div>
                    <Auxiliary>
                      <div className="col-12"><hr /></div>
                      <div className="col-12">
                        <div className="mindalay--consultation-tab p-0">
                          <div className="consultation-feeback-wrapper p-0">
                            <label>{translationService.translate("TR_SELECT_CONSULTATION_MIN_RATING")}</label>
                            <div className="rating-process-container justify-content-start mt-2">
                              {
                                this.ratings && this.ratings.map(rating => {
                                  if (consultantRating < rating) {
                                    return <div key={rating} onClick={() => this.onChangeRating(rating)}><StarSvg width={50} /></div>
                                  } else {
                                    return <div key={rating} onClick={() => this.onChangeRating(rating)}><StarFilled width={50} /></div>
                                  }
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </Auxiliary>
                    <div className="col-12"><hr /></div>
                    <div className="col-12">
                      <p className="flex-1 mb-3 light-blue-background p-3 blue-color blue-border mindalay--information">
                        <InfoSvg />
                        {translationService.translate("TR_CHECK_CONSULTANT_INFO")}
                      </p>
                      {
                        consultationId ?
                          <ActionButton
                            type="submit"
                            spinnerId={buttonSpinnerId}
                            className="mindalay--btn-default mt-3 w-100"
                            name={translationService.translate("TR_UPDATE_CONSULTATION")} />
                          : <ActionButton
                            type="submit"
                            spinnerId={buttonSpinnerId}
                            className="mindalay--btn-default mt-3 w-100"
                            name={translationService.translate("TR_ORDER_CONSULTATION")} />

                      }
                    </div>
                    <div className="col-12"><hr /></div>
                  </div>
                </div>
              </div>
              {
                constltantsList.length ?
                  <div className="row">
                    <div className="col-12">
                      <h3 className="section-title">{translationService.translate("TR_THE_LIST_OF_CONSULTANTS")}</h3>
                      <hr />
                    </div>
                    <div className="col-12">
                      <div className="row">
                        {
                          constltantsList && constltantsList.length ?
                            constltantsList.map(consultant => {
                              return <ConsultantBlock
                                key={consultant.id}
                                consultant={consultant}
                                durationMax={durationMax}
                                convertUtcDayTimeToLocal={this.convertUtcDayTimeToLocal}
                                addConsultantToList={this.addConsultantToList}
                              />
                            })
                            : null
                        }
                      </div>
                      {
                        pagination && pagination.HasNext && constltantsList.length ?
                          <Auxiliary>
                            <button type="button" className="mindalay--btn-dark mt-3 w-100" onClick={this.getNextPageConsultants}>
                              {translationService.translate("TR_SHOW_MORE")}
                            </button>
                          </Auxiliary>
                          : null
                      }
                      {
                        constltantsList?.length ?
                          consultationId ?
                            <ActionButton
                              type="submit"
                              spinnerId={buttonSpinnerId}
                              className="mindalay--btn-default mt-3 w-100"
                              name={translationService.translate("TR_CREATE_CONSULTATION")}
                            />
                            :
                            <ActionButton
                              type="submit"
                              spinnerId={buttonSpinnerId}
                              className="mindalay--btn-default mt-3 w-100"
                              name={translationService.translate("TR_ORDER_CONSULTATION")}
                            />

                          : null
                      }
                    </div>
                  </div>
                  : null
              }
            </form>
          </div>
        </div>
      </div>
    </section> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  courseCategories: state.course.courseCategories,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user,
  consulatationContractBackUrl: state.main.consulatationContractBackUrl
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  addButtonSpinner,
  removeButtonSpinner,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateConsultation)

