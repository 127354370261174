import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import * as moment from "moment";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import uuid from "react-uuid";
import TranslationService from "../../Services/translationService";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import MainService from "../../Services/mainService";
import ActionButton from "../../Components/ActionButton/actionButton";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import {
	addPageSpinner,
	removePageSpinner,
	addButtonSpinner,
	removeButtonSpinner,
} from "../../Store/Actions/spinner";
import {
	ERROR_KEY,
	QUIZ_ATTEMPT_STATUS_TYPES,
	GRADING_METHODS_ARR_KEY,
	PURE_LAYOUT_BACK_KEY,
	TR_YES,
	TR_NO,
} from "../../Constants/mainKeys";
import {
	QUIZ_ATTEMPT_KEY,
	ON_GOING_KEY,
	START_TIME_KEY,
	STATE_KEY,
	ATTEMPT_ID_KEY,
	GRACE_PERIOD_END_DATE_KEY,
	PURE_KEY,
} from "../../Constants/urlKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import InfoSvg from "../../Components/Svg/infoSvg";
import { Helmet } from "react-helmet";

class QuizAttemptView extends Component {
	state = {
		quizId: this.props.match.params.quizId,
		quizData: null,
		attemptsData: null,
		translationService: null,
		spinnerId: uuid(),
		finishSpinnerId: uuid(),
	};
	quizAttemptStatusTypes = QUIZ_ATTEMPT_STATUS_TYPES;
	gradeingMethods = GRADING_METHODS_ARR_KEY;

	componentDidMount() {
		this.setTranslations();
		this.getQuizAttempts();
		this.getQuizById();
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	componentWillUnmount() {
		localStorage.removeItem(PURE_LAYOUT_BACK_KEY);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
			JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	getQuizAttempts = () => {
		const spinnerId = uuid();
		this.props.addPageSpinner(spinnerId);
		ApiService.getQuizAttempts(this.state.quizId)
			.then(response => {
				this.setState({ attemptsData: response.data });
				this.props.removePageSpinner(spinnerId);
			})
			.catch(error => this.getFail(spinnerId, error));
	};

	getQuizById = () => {
		const spinnerId = uuid();
		const { user } = this.props;
		this.props.addPageSpinner(spinnerId);
		(user && user.isOrganizationUser
			? ApiService.getQuizByIdByOrgStudent(this.state.quizId)
			: ApiService.getQuizById(this.state.quizId)
		)
			.then(response => {
				this.setState({ quizData: response.data });
				this.props.removePageSpinner(spinnerId);
			})
			.catch(error => this.getFail(spinnerId, error));
	};

	goAttempt = () => {
		const { attemptsData, quizId, spinnerId, quizData } = this.state;
		if (attemptsData.attempt || attemptsData.reAttempt) {
			if (!quizData.questions.length) {
				return;
			}
			this.props.addButtonSpinner(spinnerId);
			ApiService.createQuizAttempt(quizId)
				.then(response => {
					this.props.removeButtonSpinner(spinnerId);
					const { history, language, match } = this.props;
					const attemptId = response.data.id;
					const goAttemptPath =
						attemptId &&
						`/${language}/${PURE_KEY}/${QUIZ_ATTEMPT_KEY}/${match.params.courseId}/${quizId}?${ON_GOING_KEY}=${attemptId}`;
					history.push(goAttemptPath);
				})
				.catch(error => this.getFail(spinnerId, error));
		} else if (attemptsData.continueAttemptId) {
			const { history, language, match } = this.props;
			const onGoingAttempt =
				attemptsData &&
				attemptsData.attemptResponseModels &&
				attemptsData.attemptResponseModels.find(
					data => data.id === attemptsData.continueAttemptId,
				);
			const onGoingAttemptStartTime =
				onGoingAttempt && onGoingAttempt.startTime;
			const goAttemptPath = `/${language}/${PURE_KEY}/${QUIZ_ATTEMPT_KEY}/${match.params.courseId
				}/${quizId}${attemptsData && attemptsData.continueAttemptId
					? `?${ON_GOING_KEY}=${attemptsData.continueAttemptId}${onGoingAttemptStartTime
						? `&${START_TIME_KEY}=${onGoingAttemptStartTime}`
						: ""
					}`
					: ""
				}`;
			history.push(goAttemptPath);
		}
	};

	getFail = (spinnerId, error) => {
		this.props.removePageSpinner(spinnerId);
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		this.props.history.push("/");
	};

	submitFail = (spinnerId, error) => {
		this.props.removeButtonSpinner(spinnerId);
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	finishAttempt = quizId => {
		const { finishSpinnerId } = this.state;
		this.props.addButtonSpinner(finishSpinnerId);
		ApiService.finishAttempt(quizId)
			.then(response => {
				response.data && this.setState({ attemptsData: response.data });
				this.props.removeButtonSpinner(finishSpinnerId);
			})
			.catch(error => this.submitFail(finishSpinnerId, error));
	};

	backToTheCourse = () => {
		const { pureLayoutBackUrl } = this.props;
		if (pureLayoutBackUrl) {
			this.props.history.push(pureLayoutBackUrl);
			localStorage.removeItem(PURE_LAYOUT_BACK_KEY);
		} else {
			window.history.back();
		}
	};

	goBack = () => {
		if (this.props.pureLayoutBackUrl) {
			this.props.history.push(this.props.pureLayoutBackUrl);
			localStorage.removeItem(PURE_LAYOUT_BACK_KEY);
		} else {
			window.history.back();
		}
	};

	render() {
		const {
			translationService,
			attemptsData,
			quizId,
			spinnerId,
			finishSpinnerId,
			quizData,
		} = this.state;
		const { language, match } = this.props;
		let quizAttemptsColumns;

		if (translationService && attemptsData) {
			quizAttemptsColumns = [
				{
					name: `${translationService.translate("TR_STATUS")}`,
					selector: "status",
				},
				{
					name: `${translationService.translate("TR_COMPLETED")}`,
					selector: "finishDate",
					wrap: true,
				},
				// {
				//   name: translationService.translate("TR_STATE"),
				//   selector: 'cell',
				// },
				{
					name: `${translationService.translate("TR_MARKS")} / ${attemptsData.maxMark
						}`,
					selector: "earnedMark",
					sortable: true,
				},
				{
					name: `${translationService.translate("TR_GRADE")} / ${attemptsData.maxGrade
						}`,
					selector: "earnedGrade",
					sortable: true,
				},
				{
					name: translationService.translate("TR_REVIEW"),
					selector: "actions",
					sortable: false,
				},
			];
		}

		if (attemptsData && attemptsData.attemptResponseModels) {
			attemptsData.attemptResponseModels.forEach(attemptRespModel => {
				const reviewPath = `/${language}/${PURE_KEY}/${QUIZ_ATTEMPT_KEY}/${match.params.courseId
					}/${quizId}?${STATE_KEY}=${attemptRespModel.state}&${ATTEMPT_ID_KEY}=${attemptRespModel.id
					}${attemptRespModel.gracePeriodEndDate
						? `&${GRACE_PERIOD_END_DATE_KEY}=${attemptRespModel.gracePeriodEndDate}`
						: ""
					}`;

				// attemptRespModel.cell = (() => {
				//   return <div>
				//     <p className="text-capitalize">
				//       {
				//         translationService.translate(this.quizAttemptStatusTypes[attemptRespModel.state]) ?
				//           translationService.translate(this.quizAttemptStatusTypes[attemptRespModel.state]) :
				//           attemptRespModel.state
				//       }
				//     </p>
				//     {
				//       attemptRespModel.endTime ? <p>
				//         <span className="mr-1">{translationService.translate("TR_SUBMITTED")}</span>
				//         {moment(MainService.convertUTCDateToLocalDate(new Date(attemptRespModel.endTime))).format('YYYY-MM-DD HH:mm:ss')}
				//       </p> : null
				//     }
				//   </div>;
				// })();
				attemptRespModel.status = (() => {
					return (
						<div>
							<p className="text-capitalize">
								{translationService.translate(
									this.quizAttemptStatusTypes[attemptRespModel.state],
								)
									? translationService.translate(
										this.quizAttemptStatusTypes[attemptRespModel.state],
									)
									: attemptRespModel.state}
							</p>
						</div>
					);
				})();
				attemptRespModel.finishDate = (() => {
					return (
						<div>
							{attemptRespModel.endTime ? (
								<p>
									{moment(
										MainService.convertUTCDateToLocalDate(
											new Date(attemptRespModel.endTime),
										),
									).format("ll HH:mm")}
								</p>
							) : null}
						</div>
					);
				})();

				attemptRespModel.actions = (() => {
					return attemptRespModel.state === 1 ||
						attemptRespModel.state === 2 ? (
						<Link to={reviewPath}>
							{translationService.translate("TR_REVIEW")}
						</Link>
					) : null;
				})();
			});
		}

		return translationService && attemptsData && quizData ? (
			<div className="container">
				{
					<Helmet>
						<title>{`${quizData.name} | Mindalay`}</title>
					</Helmet>
				}
				<div className="row">
					<div className="col-lg-3">
						<div className="background box-shadow-4 mb-lg-0 mb-3">
							<div className="quiz-fildest-information">
								{quizData.timeLimit ? (
									<fieldset>
										<legend>
											{translationService.translate("TR_TIME_LIMIT")}
										</legend>
										<p>
											<span className="mr-1">{quizData.timeLimit}</span>
											{(() => {
												switch (quizData.timeLimitEntity) {
													case 0:
														return translationService.translate("TR_SECONDS");
													case 1:
														return translationService.translate("TR_MINUTES");
													case 2:
														return translationService.translate("TR_HOURS");
													case 3:
														return translationService.translate("TR_DAYS");
													case 4:
														return translationService.translate("TR_WEEKS");
													default:
														break;
												}
											})()}
										</p>
									</fieldset>
								) : null}
								{quizData.openDate ? (
									<fieldset>
										<legend>
											{translationService.translate("TR_START_DATE")}
										</legend>
										<p>
											{moment(
												MainService.convertUTCDateToLocalDate(
													new Date(quizData.openDate),
												),
											).format("YYYY-MM-DD HH:mm:ss")}
										</p>
									</fieldset>
								) : null}
								{quizData.closeDate ? (
									<fieldset>
										<legend>
											{translationService.translate("TR_END_DATE")}
										</legend>
										<p>
											{moment(
												MainService.convertUTCDateToLocalDate(
													new Date(quizData.closeDate),
												),
											).format("YYYY-MM-DD HH:mm:ss")}
										</p>
									</fieldset>
								) : null}
								{quizData.questions ? (
									<fieldset>
										<legend>
											{translationService.translate("TR_QUESTIONS_COUNT")}
										</legend>
										<p>{quizData.questions.length}</p>
									</fieldset>
								) : null}
								{attemptsData.gradingMethod ||
									attemptsData.gradingMethod === 0 ? (
									<fieldset>
										<legend>
											{translationService.translate("TR_GRADING_METHOD")}
										</legend>
										<p>
											{translationService.translate(
												this.gradeingMethods[attemptsData.gradingMethod],
											)}
										</p>
									</fieldset>
								) : null}
							</div>
						</div>
					</div>
					<div className="col-lg-9 col-12">
						<div className="background box-shadow-4 content">
							<h2 className="content-title word-break-break-word">
								<Link
									to="#"
									title={translationService.translate("TR_BACK")}
									onClick={() => this.goBack()}>
									<ArrowBackSvg />
								</Link>
								{quizData.name}
							</h2>
							<hr />
							<div className="content-body pt-0">
								{quizData.questions && !quizData.questions.length ? (
									<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
										<InfoSvg />
										{translationService.translate("TR_EMPTY_QUIZ_WARNING_MSG")}
									</p>
								) : null}
								<div className="content-sub-title">
									<h3>{translationService.translate("TR_QUIZ_ATTEMPTS")}</h3>
								</div>
								<div className="data-table-search-right">
									{attemptsData &&
										attemptsData.attemptResponseModels &&
										attemptsData.attemptResponseModels.length ? (
										<DataTableExtensions
											columns={quizAttemptsColumns}
											data={attemptsData.attemptResponseModels}
											export={false}
											print={false}
											filter={false}
											filterHidden={false}
											filterPlaceholder={translationService.translate(
												"TR_SEARCH",
											)}>
											<DataTable
												noHeader
												defaultSortField="earnedMark"
												defaultSortAsc={false}
												highlightOnHover
												responsive={true}
												pagination
												className="data-table-quiz-attempt"
												paginationComponentOptions={{
													rowsPerPageText:
														translationService.translate("TR_ROWS_PER_PAGE"),
												}}>
												{translationService.translate("TR_QUIZ_ATTEMPTS")}
											</DataTable>
										</DataTableExtensions>
									) : attemptsData.attempt ||
										attemptsData.reAttempt ||
										attemptsData.continueAttemptId ? (
										<p
											title={quizData.description}
											className="word-break-break-word">
											{quizData.description}
										</p>
									) : null}
								</div>
								{attemptsData.attempt ||
									attemptsData.reAttempt ||
									attemptsData.continueAttemptId ? null : (
									<div className="text-center mb-3">
										<p>
											{translationService.translate("TR_FINAL_GRADE_MSG")}{" "}
											{attemptsData.attemptGrade}/{attemptsData.maxGrade}
										</p>
									</div>
								)}
								<div className="d-flex justify-content-end mt-4">
									{quizData.questions &&
										quizData.questions.length &&
										(attemptsData.attempt ||
											attemptsData.reAttempt ||
											attemptsData.continueAttemptId) ? (
										<Auxiliary>
											<ActionButton
												type="button"
												spinnerId={spinnerId}
												name={
													attemptsData.attempt
														? translationService.translate("TR_ATTEMPT")
														: attemptsData.reAttempt
															? translationService.translate("TR_REATTEMPT")
															: attemptsData.continueAttemptId
																? translationService.translate("TR_CONTINUE")
																: ""
												}
												className="btn mindalay--btn-default mr-2"
												clicked={this.goAttempt}
												disabled={
													!MainService.checkDates(
														MainService.convertUTCDateToLocalDate(
															new Date(quizData.openDate),
														),
														new Date(),
													)
												} //////////////
											/>
											{attemptsData.reAttempt ? (
												<ActionButton
													type="button"
													spinnerId={finishSpinnerId}
													name={translationService.translate("TR_FINISH")}
													className="btn mindalay--btn-default ml-2"
													clicked={() => {
														AlertService.alertConfirm(
															`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
															"",
															translationService.translate(TR_YES),
															translationService.translate(TR_NO),
														).then(() => {
															this.finishAttempt(attemptsData.courseQuizId)
														});
													}
													}
												/>
											) : null}
										</Auxiliary>
									) : (
										<button
											type="button"
											className="mindalay--btn-default"
											onClick={this.backToTheCourse}>
											{translationService.translate("TR_BACK_TO_COURSE")}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	language: state.language.language,
	languages: state.language.languages,
	pureLayoutBackUrl: state.main.pureLayoutBackUrl,
	user: state.user.user,
});

const mapDispatchToProps = {
	addPageSpinner,
	removePageSpinner,
	addButtonSpinner,
	removeButtonSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(QuizAttemptView);
