import { SET_NOTIFICATION_COUNT, SET_UNREAD_MESSAGES_COUNT, SHOW_WEBINAR_SCREEN_NOTIFICATION, SET_GLOBAL_CONNECTION, SET_GLOBAL_USER_CONNECTION } from "../../Constants/reduxKeys";

export function setNotificationCount(data) {
  return {
    type: SET_NOTIFICATION_COUNT,
    payload: data
  }
}

export function setUnreadMessagesCount(data) {
  return {
    type: SET_UNREAD_MESSAGES_COUNT,
    payload: data
  }
}

export function showOrHideWebinarScreenNotification(value) {
  return {
    type: SHOW_WEBINAR_SCREEN_NOTIFICATION,
    payload: value
  }
}

export function setGlobalConnection(data) {
  return {
    type: SET_GLOBAL_CONNECTION,
    payload: data
  }
}

export function setGlobalUserConnection(data) {
  return {
    type: SET_GLOBAL_USER_CONNECTION,
    payload: data
  }
}

