import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import AlertService from "../../Services/alertService";
import { setPureLayoutBackUrl } from "../../Store/Actions/main";
import {
	ERROR_KEY,
	INSTRUCTOR_USER_TYPE,
	CONSULTANT_USER_TYPE,
} from "../../Constants/mainKeys";
import Parser from "html-react-parser";
import {
	BECOME_CONSULTANT_KEY,
	BECOME_INSTRUCTOR_KEY,
	CONSULTANT_INFORMATION_KEY,
	CONSULTANT_KEY,
	COURSES_KEY,
	CUSTOM_PAYMENT_PACKAGE_KEY,
	INSTRUCTOR_KEY,
	LEARN_IN_MINDALAY_KEY,
	LOGIN_KEY,
	MINDALAY_FOR_INSTITUTIONS_KEY,
	ORGANIZATION_ADMIN_KEY,
	ORGANIZATION_FORM_KEY,
	PURE_KEY,
	TEACH_IN_MINDALAY_KEY,
	VIEW_KEY,
} from "../../Constants/urlKeys";
import MainService from "../../Services/mainService";
import RolesService from "../../Services/rolesService";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

const Info = props => {
	const dispatch = useDispatch();
	const { settings } = useSelector(state => state.settings);
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { role, user } = useSelector(state => state.user);
	const [pageContentData, setPageContentData] = useState([]);
	const [translationService, setTranslationService] = useState(null);
	const [paymentPackages, setPaymentPackages] = useState([]);

	const history = useHistory();

	useEffect(() => {
		setPageContent();
		if (
			history.location.pathname.includes("mindalay-for-institutions/info") ||
			MainService.isShowOnlyB2B()
		) {
			setPaymentPackages([]);
			getAllPaymentPackages();
		}
	}, [history.location.pathname]);

	useEffect(() => {
		if (settings) {
			props.history.push(`/${language}/login`);
		}
	}, [history.location.pathname, settings]);

	const getAllPaymentPackages = () => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getAllPaymentPackages()
			.then(response => {
				if (response.data && response.data.length) {
					setPaymentPackages(response.data);
				} else {
					setPaymentPackages([]);
				}
				dispatch(removePageSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const setPageContent = () => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		setPageContentData(null);
		getPageContent()
			.then(response => {
				if (
					response &&
					response.data &&
					response.data?.pageContentResponses?.length
				) {
					setPageContentData(response.data?.pageContentResponses);
				}
				dispatch(removePageSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePageSpinner(spinnerId));
	};

	const getPageContent = () => {
		const url = history.location.pathname;
		switch (true) {
			case url.includes(MINDALAY_FOR_INSTITUTIONS_KEY) ||
				MainService.isShowOnlyB2B():
				return ApiService.getMindalayForBusinessInfoPageContent();
			case url.includes(LEARN_IN_MINDALAY_KEY):
				return ApiService.getLearnInMindalayInfoPageContent();
			case url.includes(TEACH_IN_MINDALAY_KEY):
				return ApiService.getTeachInMindalayInfoPageContent();
			case url.includes(BECOME_CONSULTANT_KEY):
				return ApiService.getBecomeConsultantInfoPageContent();
			default:
				return new Promise((res, rej) => {
					return res(false);
				});
		}
	};

	const redirectPage = () => {
		if (
			props.location.pathname.includes(MINDALAY_FOR_INSTITUTIONS_KEY) ||
			MainService.isShowOnlyB2B()
		) {
			props.history.push(
				`/${language}/${ORGANIZATION_ADMIN_KEY}/${ORGANIZATION_FORM_KEY}`,
			);
		} else {
			dispatch(setPureLayoutBackUrl(props.location.pathname));
			if (
				!user &&
				// props.location.pathname.includes(MINDALAY_FOR_INSTITUTIONS_KEY) ||
				(props.location.pathname.includes(LEARN_IN_MINDALAY_KEY) ||
					props.location.pathname.includes(TEACH_IN_MINDALAY_KEY) ||
					props.location.pathname.includes(BECOME_CONSULTANT_KEY))
			) {
				props.history.push(`/${language}/${LOGIN_KEY}`);
			} else if (
				user &&
				user.userTypeId !== INSTRUCTOR_USER_TYPE &&
				user.userTypeId !== CONSULTANT_USER_TYPE &&
				props.location.pathname.includes(TEACH_IN_MINDALAY_KEY)
			) {
				props.history.push(`/${language}/${PURE_KEY}/${BECOME_INSTRUCTOR_KEY}`);
			} else if (
				user &&
				(user.userTypeId === INSTRUCTOR_USER_TYPE ||
					user.userTypeId === CONSULTANT_USER_TYPE) &&
				props.location.pathname.includes(TEACH_IN_MINDALAY_KEY)
			) {
				props.history.push(
					`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`,
				);
			} else if (
				user &&
				user.userTypeId === CONSULTANT_USER_TYPE &&
				props.location.pathname.includes(BECOME_CONSULTANT_KEY)
			) {
				props.history.push(
					`/${language}/${CONSULTANT_KEY}/${CONSULTANT_INFORMATION_KEY}`,
				);
			} else if (
				user &&
				user.userTypeId !== CONSULTANT_USER_TYPE &&
				props.location.pathname.includes(BECOME_CONSULTANT_KEY)
			) {
				props.history.push(`/${language}/${PURE_KEY}/${BECOME_CONSULTANT_KEY}`);
			}
		}
	};

	return translationService ? (
		pageContentData && pageContentData.length ? (
			<section>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="info-cover-image-wrapper mt-4">
								<div
									style={{
										backgroundImage: `url(${pageContentData[0]?.imagePath})`,
									}}
									className="info-main-image"
								/>
								{/* <img src={pageContentData[0]?.imagePath} className="w-100" alt="/" /> */}
								{JSON.parse(pageContentData[0]?.fullContent) ? (
									<div className="info-cover-text-wrapper mt-5">
										<h2 className="info-cover-title">
											{JSON.parse(pageContentData[0]?.fullContent)?.title}
										</h2>
										<hr />
										<p className="info-cover-subtitle">
											{JSON.parse(pageContentData[0]?.fullContent)?.description}
										</p>
										{user &&
											(props.location.pathname.includes(
												MINDALAY_FOR_INSTITUTIONS_KEY,
											) ||
												props.location.pathname.includes(
													LEARN_IN_MINDALAY_KEY,
												)) ? null : (
											<div className="">
												<Link
													to="#"
													className="mindalay--btn-link mindalay--btn mindalay--btn-dark my-4"
													onClick={redirectPage}>
													{translationService.translate("TR_GET_STARTED")}
												</Link>
											</div>
										)}
									</div>
								) : null}
							</div>
						</div>
					</div>
					<div className="info-section-container">
						<div className="row">
							{pageContentData.map((pageContent, index) => {
								if (index > 0) {
									if (index % 2 !== 0) {
										return (
											<div key={index} className="col-12">
												<div className="row">
													<ParserComponent text={pageContent.fullContent} />

													<div className="col-md-6 col-12">
														<div className="justify-content-center d-flex">
															<img
																src={pageContent.imagePath}
																className="info-section-image"
																alt="/"
															/>
														</div>
													</div>
												</div>
												<hr />
											</div>
										);
									} else {
										return (
											<div key={index} className="col-12">
												<div className="row">
													<div className="col-md-6 col-12">
														<div className="justify-content-center d-flex">
															<img
																src={pageContent.imagePath}
																className="info-section-image"
																alt="/"
															/>
														</div>
													</div>
													<ParserComponent text={pageContent.fullContent} />
												</div>
												<hr />
											</div>
										);
									}
								} else {
									return false;
								}
							})}
						</div>
					</div>
					<div className="mindalay-business-info-container">
						<section className="pricing py-5">
							<div className="container">
								<div className="row">
									{(props.location.pathname.includes(
										MINDALAY_FOR_INSTITUTIONS_KEY,
									) ||
										MainService.isShowOnlyB2B()) &&
										paymentPackages &&
										paymentPackages.length
										? paymentPackages.map((paymentPackage, index) => {
											return (
												<div
													key={index}
													className="col-xl-3 col-sm-6 col-12 my-2">
													<div className={`card payment-card`}>
														<div className="card-body">
															<h5 className="card-title text-muted text-uppercase text-center">
																{paymentPackage.name}
															</h5>
															<h6 className="card-price text-center">
																{paymentPackage.cost}
																<span className="period">{` /${translationService.translate(
																	"TR_MONTH_PER_USER",
																)}`}</span>
															</h6>
															<hr />
															<ul className="fa-ul">
																{paymentPackage.properties &&
																	paymentPackage.properties.length
																	? paymentPackage.properties.map(
																		(property, i) => {
																			if (
																				property.value &&
																				property.value !== "false" &&
																				property.value !== "0" &&
																				property.value !== 0
																			) {
																				return (
																					<li key={i}>
																						<span className="fa-li">
																							<i className="fas fa-check"></i>
																						</span>
																						<strong>
																							{property.value === "true"
																								? null
																								: property.value}{" "}
																							{property.key}
																						</strong>
																					</li>
																				);
																			} else {
																				return false;
																			}
																		},
																	)
																	: null}
																{paymentPackage.properties &&
																	paymentPackage.properties.length
																	? paymentPackage.properties.map(
																		(property, i) => {
																			if (
																				!property.value ||
																				property.value === "false" ||
																				property.value === "0" ||
																				property.value === 0
																			) {
																				return (
																					<li
																						key={i}
																						className="text-muted">
																						<span className="fa-li">
																							<i className="fas fa-times"></i>
																						</span>
																						{property.key}
																					</li>
																				);
																			} else {
																				return false;
																			}
																		},
																	)
																	: null}
															</ul>
														</div>
													</div>
												</div>
											);
										})
										: null}
								</div>
							</div>
						</section>
						{user &&
							props.location.pathname.includes("learn-in-mindalay") ? null : (
							<div className="d-flex justify-content-end flex-wrap">
								<Link
									to={`/${language}/custom-payment-package`}
									className="mindalay--btn-link mindalay--btn mindalay--btn-dark my-2 mr-2"
								>
									{translationService.translate("TR_CALCULATE_CUSTOM_PACKAGE")}
								</Link>
								<Link
									to="#"
									className="mindalay--btn-link mindalay--btn mindalay--btn-dark my-2"
									onClick={redirectPage}>
									{translationService.translate("TR_GET_STARTED")}
								</Link>
							</div>
						)}
					</div>
				</div>
			</section>
		) : null
	) : null;
};

export default withRouter(Info);
