import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../../Services/translationService";
import {
	setBackUrl,
	setPureLayoutBackUrl,
} from "../../../../Store/Actions/main";
import ReurnUrl from "../../../../Services/returnUrl";
import Auxiliary from "../../../../hoc/auxiliary/auxiliary";
import {
	CONSULTANT_USER_TYPE,
	INSTRUCTOR_USER_TYPE,
	NUMBER_KEY,
	SHOPPING_CART_COUNT_KEY,
	SHOPPING_CART_KEY,
	STUDENT_USER_TYPE,
	WISH_LIST_COUNT_KEY,
	WISH_LIST_KEY,
} from "../../../../Constants/mainKeys";
import { INSTRUCTOR_PATH_KEY } from "../../../../Constants/requestKeys";
import {
	ALL_CONSULTATIONS_KEY,
	BECOME_CONSULTANT_KEY,
	CONSULTANT_DASHBOARD_KEY,
	CONSULTANT_FEED_KEY,
	CONSULTANT_INFORMATION_KEY,
	CONSULTANT_KEY,
	COURSES_KEY,
	EDIT_PROFILE_KEY,
	INFO_KEY,
	INSTRUCTOR_KEY,
	LEARN_IN_MINDALAY_KEY,
	PROFILE_KEY,
	PURE_KEY,
	TEACH_IN_MINDALAY_KEY,
	TICKETS_KEY,
	VIEW_KEY,
	HELP_KEY,
	MINDALAY_FOR_INSTITUTIONS_KEY,
	STUDENT_KEY,
	FINANCES_KEY,
	OVERVIEW_KEY,
	ALL_MESSAGES_KEY,
	CREATE_ONE_TIME_WEBINAR_KEY,
} from "../../../../Constants/urlKeys";

const UserBurgerMenu = props => {
	const dispatch = useDispatch();

	const showMobileBurgerMenu = props.showMobileBurgerMenu;

	const user = useSelector(state => state.user.user);
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const potentialconsultationsCount = useSelector(
		state => state.consultation.potentialconsultationsCount,
	);
	const unreadMessgesCount = useSelector(
		state => state.signalR.unreadMessgesCount,
	);
	const globalConnection = useSelector(state => state.signalR.globalConnection);
	const [translationService, setTranslationService] = useState(null);
	const { wishListCount, shoppingCartCount } = useSelector(
		state => state.wishListOrShoppingCart,
	);
	const [wishListCountWithStorage, setWishListCountWithStorage] = useState(0);
	const [shoppingCartCountWithStorage, setShoppingCartCountWithStorage] =
		useState(0);

	// if (localStorage.getItem(WISH_LIST_COUNT_KEY) && +localStorage.getItem(WISH_LIST_COUNT_KEY) !== this.state.wishListCountWithStorage) {
	//   this.getWishListOrShoppingCartData();
	// }
	// if (localStorage.getItem(SHOPPING_CART_COUNT_KEY) && +localStorage.getItem(SHOPPING_CART_COUNT_KEY) !== this.state.shoppingCartCountWithStorage) {
	//   this.getWishListOrShoppingCartData();
	// }

	useState(() => {
		if (localStorage.getItem(WISH_LIST_COUNT_KEY)) {
			setWishListCountWithStorage(+localStorage.getItem(WISH_LIST_COUNT_KEY));
		}
		if (localStorage.getItem(SHOPPING_CART_COUNT_KEY)) {
			setShoppingCartCountWithStorage(
				+localStorage.getItem(SHOPPING_CART_COUNT_KEY),
			);
		}
	}, [
		localStorage.getItem(WISH_LIST_COUNT_KEY),
		localStorage.getItem(SHOPPING_CART_COUNT_KEY),
	]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const setBackUrlPureLayout = url => {
		dispatch(setPureLayoutBackUrl(url));
		props.close();
	};

	const returnUrlWithoutRedirect = url => {
		ReurnUrl.setUrl(url);
		props.close();
	};

	const setUrl = () => {
		dispatch(setBackUrl(props.location.pathname));
	};

	return translationService ? (
		<div
			className={`burger-menu-container ${
				showMobileBurgerMenu ? "toggle-burger" : ""
			}`}>
			<div
				className={`burger-menu-body ${
					showMobileBurgerMenu ? "toggle-burger-menu" : ""
				}`}>
				<div className="user-burger-scroll-block">
					<div className="col-12 profile-nav-link profile-block d-md-flex">
						<Link
							to={`/${language}/${EDIT_PROFILE_KEY}`}
							className="d-flex align-items-center"
							onClick={props.close}>
							{user.avatarFilePath ? (
								<div
									className="navbar-profile-image"
									style={{
										backgroundImage: `url(${user.avatarFilePath})`,
									}}></div>
							) : null}
							<div className="ml-3 user-profil-info-wrapper">
								<h2 className="profile-user-fullname">
									{user.firstname} {user.lastname}
								</h2>
								<small>{user.email}</small>
							</div>
						</Link>
					</div>
					<div id="close-burger-menu" className="nav-item">
						<div
							className={`right-close-btn side-menu-close-btn ${
								showMobileBurgerMenu ? "burger-close-button-scala" : ""
							}`}
							onClick={props.close}>
							<span></span>
						</div>
					</div>
					<ul className={`burger-profile-container ${user ? "top-100" : ""}`}>
						<li>
							<hr className="d-none" />
						</li>
						<li className="nav--item important-action mb-2">
							<Link
								to={`/${language}/${LEARN_IN_MINDALAY_KEY}/${INFO_KEY}`}
								className="profile-nav-link"
								onClick={props.close}>
								{translationService.translate("TR_LEARN_IN_MINDALAY")}
							</Link>
						</li>
						<li className="nav--item important-action mb-2">
							{user &&
							(user.userTypeId === INSTRUCTOR_USER_TYPE ||
								user.userTypeId === CONSULTANT_USER_TYPE) ? (
								<Link
									to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`}
									className="profile-nav-link"
									onClick={props.close}>
									{translationService.translate("TR_INSTRUCTOR")}
								</Link>
							) : (
								<Link
									to={`/${language}/${TEACH_IN_MINDALAY_KEY}/${INFO_KEY}`}
									className="profile-nav-link"
									onClick={() => {
										setBackUrlPureLayout(`/${language}`);
										props.close();
									}}>
									{translationService.translate("TR_TEACH_IN_MINDALAY")}
								</Link>
							)}
						</li>
						<li className="nav--item important-action">
							{user ? (
								<Link
									to={
										user.userTypeId === CONSULTANT_USER_TYPE
											? `/${language}/${CONSULTANT_KEY}/${CONSULTANT_INFORMATION_KEY}`
											: `/${language}/${BECOME_CONSULTANT_KEY}/${INFO_KEY}`
									}
									className="profile-nav-link"
									title={
										user.userTypeId === CONSULTANT_USER_TYPE &&
										!user.dontDisturb
											? translationService.translate(
													"TR_ACTIVE_CONSULTANT_INFO",
											  )
											: user.userTypeId === CONSULTANT_USER_TYPE &&
											  user.dontDisturb
											? translationService.translate(
													"TR_INACTIVE_CONSULTANT_INFO",
											  )
											: ""
									}
									onClick={() => setBackUrlPureLayout(`/${language}`)}>
									{user.userTypeId === CONSULTANT_USER_TYPE
										? translationService.translate("TR_CONSULTANT")
										: translationService.translate("TR_BECOME_A_CONSULTANT")}
									{user.userTypeId === CONSULTANT_USER_TYPE &&
									user.dontDisturb ? (
										<p className={`d-inline dont-disturb-point is-passive`} />
									) : null}
									{user.userTypeId === CONSULTANT_USER_TYPE &&
									!user.dontDisturb ? (
										<p className={`d-inline dont-disturb-point is-active`} />
									) : null}
								</Link>
							) : (
								<Link
									to={`/${language}/${BECOME_CONSULTANT_KEY}/${INFO_KEY}`}
									className="profile-nav-link"
									onClick={() =>
										returnUrlWithoutRedirect(
											`/${language}/${PURE_KEY}/${BECOME_CONSULTANT_KEY}`,
										)
									}>
									{translationService.translate("TR_BECOME_A_CONSULTANT")}
								</Link>
							)}
						</li>
						<li className="nav--item important-action mb-2">
							<Link
								to={`/${language}/${MINDALAY_FOR_INSTITUTIONS_KEY}/${INFO_KEY}`}
								className="profile-nav-link"
								onClick={props.close}>
								{translationService.translate("TR_MINDALAY_FOR_INSTITUTIONS")}
							</Link>
						</li>
						<li>
							<hr />
						</li>
						{/* <li className="nav--item">
            <Link
              to={`/${language}/${WISH_LIST_KEY}`}
              className="profile-nav-link"
              onClick={props.close}
            >
              {translationService.translate("TR_WISH_LIST")}
            </Link>
          </li> */}
						<li className="nav--item">
							<Link
								to={`/${language}/${WISH_LIST_KEY}`}
								className={`profile-nav-link ${
									wishListCount || wishListCountWithStorage
										? "has-number has-number-in-menu"
										: ""
								}`}
								rel={
									typeof wishListCount === NUMBER_KEY
										? wishListCount
										: wishListCountWithStorage
										? wishListCountWithStorage
										: 0
								}
								onClick={props.close}>
								{translationService.translate("TR_WISH_LIST")}
							</Link>
						</li>
						<li className="nav--item">
							<Link
								to={`/${language}/${SHOPPING_CART_KEY}`}
								className={`profile-nav-link ${
									shoppingCartCount || shoppingCartCountWithStorage
										? "has-number has-number-in-menu"
										: ""
								}`}
								rel={
									typeof shoppingCartCount === NUMBER_KEY
										? shoppingCartCount
										: shoppingCartCountWithStorage
										? shoppingCartCountWithStorage
										: 0
								}
								onClick={props.close}>
								{translationService.translate("TR_SHOPPING_CART")}
							</Link>
						</li>
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Link
								to={`/${language}/${ALL_MESSAGES_KEY}`}
								className={`profile-nav-link w-100 ${
									unreadMessgesCount ? "has-value" : ""
								} `}
								onClick={props.close}
								rel={unreadMessgesCount ? unreadMessgesCount : null}>
								{translationService.translate("TR_MESSAGES")}
							</Link>
						</li>
						<li className="nav--item">
							<Link
								to={`/${language}/${TICKETS_KEY}`}
								className="profile-nav-link"
								onClick={props.close}>
								{translationService.translate("TR_TICKETS")}
							</Link>
						</li>
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Link
								to={`/${language}/${EDIT_PROFILE_KEY}`}
								className="profile-nav-link"
								onClick={props.close}>
								{translationService.translate("TR_EDIT_PROFILE")}
							</Link>
						</li>
						<li className="nav--item">
							<Link
								to={`/${language}/${PROFILE_KEY}`}
								className="profile-nav-link"
								onClick={props.close}>
								{translationService.translate("TR_MY_COURSES")}
							</Link>
						</li>
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Link
								to={`/${language}/${ALL_CONSULTATIONS_KEY}`}
								className="profile-nav-link"
								onClick={props.close}>
								{translationService.translate("TR_CONSULTATIONS")}
							</Link>
						</li>
						{user && user.userTypeId === CONSULTANT_USER_TYPE ? (
							<li className="nav--item position-relative">
								<Link
									className={`profile-nav-link w-100 ${
										potentialconsultationsCount ? "has-value" : ""
									} `}
									to={`/${language}/${CONSULTANT_FEED_KEY}`}
									rel={
										potentialconsultationsCount
											? potentialconsultationsCount
											: null
									}
									onClick={props.close}>
									{translationService.translate("TR_CONSULTANT_FEED")}
								</Link>
							</li>
						) : null}
						{user.userTypeId === CONSULTANT_USER_TYPE ||
						user.userTypeId === INSTRUCTOR_USER_TYPE ? (
							<Auxiliary>
								<li>
									<hr />
								</li>
								<li className="nav--item">
									<Link
										className="profile-nav-link w-100"
										to={
											user.userTypeId === CONSULTANT_USER_TYPE
												? `/${language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`
												: user.userTypeId === INSTRUCTOR_USER_TYPE
												? `/${language}/${INSTRUCTOR_PATH_KEY}/${COURSES_KEY}/${VIEW_KEY}`
												: "#"
										}
										onClick={props.close}>
										{translationService.translate("TR_DASHBOARD")}
									</Link>
								</li>
							</Auxiliary>
						) : null}
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Link
								to={`/${language}/${CREATE_ONE_TIME_WEBINAR_KEY}`}
								className="profile-nav-link"
								onClick={props.close}>
								{translationService.translate("TR_VIDEO_CONFERENCES")}
							</Link>
						</li>

						{/* <li><hr /></li> */}
						{user && user.userTypeId === STUDENT_USER_TYPE ? (
							<Auxiliary>
								<li>
									<hr />
								</li>
								<li className="nav--item">
									<Link
										className="profile-nav-link"
										to={`/${language}/${STUDENT_KEY}/${FINANCES_KEY}`}
										onClick={props.close}>
										{translationService.translate("TR_FINANCES")}
									</Link>
								</li>
								<li className="nav--item">
									<Link
										className="profile-nav-link"
										to={`/${language}/${STUDENT_KEY}/${OVERVIEW_KEY}`}
										onClick={props.close}>
										{translationService.translate("TR_PAYMENT_HISTORY")}
									</Link>
								</li>
							</Auxiliary>
						) : null}
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Link
								to={`/${language}/${HELP_KEY}`}
								className="profile-nav-link"
								onClick={() => {
									props.close();
									setUrl();
								}}>
								{translationService.translate("TR_HELP")}
							</Link>
						</li>
						<li className="nav--item">
							<Link to={`/${language}/logout`} className="profile-nav-link">
								{translationService.translate("TR_LOG_OUT")}
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(UserBurgerMenu);
