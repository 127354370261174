import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import uuid from "react-uuid";
import TranslationService from "../../Services/translationService";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import ReactPaginate from "react-paginate";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import MainService from "../../Services/mainService";
import moment from "moment";

const StudentLogs = props => {
	const studentId = props.match.params.studentId || null;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [logs, setLogs] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getAuthenticationLog(studentId, 1);
	}, [studentId]);

	const getAuthenticationLog = (userId, pageNumber, pageSize = 10) => {
		if (!userId) return false;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getAuthenticationLog(userId, pageNumber, pageSize)
			.then(response => {
				if (response && response.data) {
					setLogs(response.data);
				}
				if (response.pagination) {
					setPagination(JSON.parse(response.pagination));
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const handlePageClick = event => {
		// getUsertypes(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	return translationService ? (
		<Auxiliary>
			<div className="container">
				<div className="row">
					<div className="col-12 mt-3">
						<div className="content-title p-0">
							<div className="d-flex align-items-center">
								<Link
									to={`/${language}/student`}
									title={translationService.translate("TR_BACK")}>
									<ArrowBackSvg />
								</Link>
								<h2 className="content-title p-0">
									{translationService.translate("TR_LOGS")}
								</h2>
							</div>
						</div>
						<PageInfoBlock
							pageTitle={translationService.translate("TR_LOGS")}
							infoText={
								"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
							}
							// pageName={
							// 	"curator_lecturer_selection_committee_student_documents"
							// }
							translationService={translationService}
						/>
					</div>
				</div>
				<hr />

				{logs && logs.length ? (
					<div className="row">
						<div className="col-12">
							<div className="mindayal-admin-table table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>{translationService.translate("TR_DATE")}</th>
											<th>{translationService.translate("TR_IP_ADDRESS")}</th>
											<th>{translationService.translate("TR_TEXT")}</th>
										</tr>
									</thead>
									<tbody>
										{logs.map((item, index) => {
											const date = MainService.convertUTCDateToLocalDate(
												new Date(item.date),
											);
											return (
												<tr key={index} className="cursor-default">
													<td>{moment(date).format("YYYY-MM-DD HH:mm:ss")}</td>
													<td>{item.ipAddress}</td>
													<td>
														{item
															? translationService.translate("TR_LOGGED_IN")
															: translationService.translate("TR_LOGGED_OUT")}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				) : (
					<div>
						<div className="no-data-container">
							<div className="no-data-wrapper">
								<p>{translationService.translate("TR_NO_DATA")}</p>
								<img src={NoDataImage} alt="/" />
							</div>
						</div>
					</div>
				)}

				{pagination && pagination.TotalPages > 1 ? (
					<div className="row mt-3">
						<div className="col-12">
							<div className="d-flex justify-content-end">
								<ReactPaginate
									nextLabel={translationService.translate("TR_NEXT")}
									onPageChange={handlePageClick}
									pageRangeDisplayed={3}
									marginPagesDisplayed={2}
									pageCount={pagination.TotalPages}
									previousLabel={translationService.translate("TR_PREVIOUS")}
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									breakLabel="..."
									breakClassName="page-item"
									breakLinkClassName="page-link"
									containerClassName="pagination"
									activeClassName="active"
									renderOnZeroPageCount={null}
									forcePage={activePageNumber}
								/>
							</div>
						</div>
					</div>
				) : null}
			</div>
		</Auxiliary>
	) : null;
};

export default withRouter(StudentLogs);
