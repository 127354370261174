import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import TranslationService from "../Services/translationService";
import ArrowBackSvg from "../Components/Svg/arrowBackSvg";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import {
	ERROR_KEY,
	STRING_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
	WARNING_KEY,
} from "../Constants/mainKeys";
import uuid from "react-uuid";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import Input from "../Components/Inputs/input";
import InputCheckBox from "../Components/Inputs/inputCheckBox";
import ActionButton from "../Components/ActionButton/actionButton";
import MainService from "../Services/mainService";
import Textarea from "../Components/Inputs/textArea";
import CloseSvg from "../Components/Svg/closeSvg";
import UploadButton from "../Components/UploadButton/uploadButton";
import RichTextEditor from "../Pages/Questions/Components/RichTextEditor";

const buttonSpinnerId = uuid();

function PageContentForm(props) {
	const mainService = new MainService();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const { pageContentId, languageId } = props.match.params;
	const dispatch = useDispatch();
	const [values, setValues] = useState({
		name: "",
		shortContent: "",
		fullContent: null,
		showInSlider: false,
		image: "",
		buttonLink: "",
		buttonLinkUnauth: "",
		languageId: null,
		pageId: null,
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [forcontent, setForcontent] = useState([]);
	const [uploadedImage, setUploadedImage] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getForcontent();
	}, []);

	useEffect(() => {
		if (pageContentId && languageId) {
			getPageContentByLangByID(pageContentId, languageId);
		} else {
			setValues(values => ({
				...values,
				fullContent: "",
			}));
		}
	}, [pageContentId, languageId]);

	// useEffect(() => {
	// 	if (paymentId) {
	// 		getPaymentPackage(paymentId);
	// 	}
	// }, [paymentId]);

	const getPageContentByLangByID = (pageContentId, languageId) => {
		if (!pageContentId || !languageId) return null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPageContentByLangByID(languageId, pageContentId)
			.then(response => {
				if (response.data) {
					const newValues = {};
					Object.entries(values).forEach(([key, value]) => {
						if (key === "image" && response.data?.imagePath) {
							newValues[key] = response.data?.imagePath;
							setUploadedImage(response.data?.imagePath);
						}
						if (response.data.hasOwnProperty(key) && response.data[key]) {
							newValues[key] = response.data[key] || "";
						}
					});
					newValues.id = response.data.id;
					setValues(newValues);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onEditorChange = event => {
		const newRichText = event.editor.getData();
		setValues(values => ({ ...values, fullContent: newRichText }));
	};

	const getForcontent = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getForcontent()
			.then(response => {
				if (response.data) {
					setForcontent(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		let regex = /^[^\d\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/u;
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onSelectOptionChange = (item, key) => {
		if (!item) return null;
		if (pageContentId) {
			getPageContentByLangByID(pageContentId, item.value);
		}
		setIsInvalidSubmit(false);
		setValues(values => ({ ...values, [key]: item.value }));
	};

	const onCheckBoxChange = (event, name) => {
		setValues(values => ({
			...values,
			[name]: event.target.checked,
		}));
	};

	const removeImg = () => {
		setIsInvalidSubmit(false);
		setUploadedImage(null);

		setValues(values => ({ ...values, image: null }));
	};

	const uploadImage = event => {
		setIsInvalidSubmit(false);
		const file = event.target.files[0];
		mainService
			.readFile(file, VALID_IMAGE_TYPES_KEY)
			.then(image => {
				if (!image) {
					return;
				}
				setUploadedImage(image);
				setValues(values => ({ ...values, image: file }));
			})
			.catch(error => {
				AlertService.alert(
					WARNING_KEY,
					translationService.translate("TR_VALID_IMAGE_ERROR_MSG"),
				);
			});
	};

	const onSubmit = e => {
		e.preventDefault();
		let clon = { ...values };
		if (!values.name.trim().length || !values.languageId || !values.pageId) {
			setIsInvalidSubmit(true);
			MainService.scrollToInvalidFieldPosition();
			return;
		}
		dispatch(addButtonSpinner(buttonSpinnerId));
		const formData = new FormData();
		Object.entries(values)
			.forEach(([key, value]) => {
				formData.append(key, value);
			})(
				!pageContentId
					? ApiService.creataPageContent(formData)
					: ApiService.updatePageContent(formData),
			)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				setIsInvalidSubmit(false);
				props.history.push(`/${language}/developer/page-contents`);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	return (
		translationService && (
			<div className="container">
				<div className="row">
					<div className="col-12 content-title">
						<div className="d-flex align-items-center">
							<Link
								to={`/${language}/developer/page-contents`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							<h2 className="title">
								{!pageContentId
									? translationService.translate("TR_CREATE_PAGE_CONTENT")
									: translationService.translate("TR_UPDATE_PAGE_CONTENT")}
							</h2>
						</div>
					</div>
				</div>
				<hr />

				<form className="row" onSubmit={onSubmit}>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>{translationService.translate("TR_PAGE")}*</label>
							<ReactSelectOption
								value={values.pageId}
								isInvalidSubmit={isInvalidSubmit}
								selectedValue={(() => {
									const selectedItem = {
										...forcontent.find(data => data.id === values.pageId),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = translationService.translate(
											selectedItem.name,
										);
										selectedItem.value = selectedItem.id;
										selectedItem.label = translationService.translate(
											selectedItem.name,
										);
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={forcontent
									.filter(item => item.id !== values.pageId)
									.map(data => ({
										...data,
										label: translationService.translate(data.name),
										value: data.id,
									}))}
								onChange={item => onSelectOptionChange(item, "pageId")}
							/>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>{translationService.translate("TR_LANGUAGE")}*</label>
							<ReactSelectOption
								value={values.languageId}
								isInvalidSubmit={isInvalidSubmit}
								selectedValue={(() => {
									const selectedItem = {
										...languages.find(
											data => data.languageId === values.languageId,
										),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.languageId;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={languages
									.filter(item => item.languageId !== values.languageId)
									.map(data => ({
										...data,
										label: data.name,
										value: data.languageId,
									}))}
								onChange={item => {
									setValues(values => ({ ...values, fullContent: null }));
									onSelectOptionChange(item, "languageId");
								}}
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="row mt-2">
							<div className="col-md-6 col-12 mb-2">
								<div className="form-group position-relative mb-0">
									<div className="course-media-wrapper">
										{uploadedImage && (
											<div className="upload-file-content">
												<div
													className="img"
													style={{
														backgroundImage: `url(${uploadedImage ? uploadedImage : ""
															})`,
													}}
													alt="course-image"></div>
												{uploadedImage ? (
													<div onClick={() => removeImg()}>
														<CloseSvg />
													</div>
												) : null}
											</div>
										)}
									</div>
									<UploadButton
										textInputClasses="pl-100"
										text={translationService.translate("TR_UPLOADAVATAR")}
										clicked={uploadImage}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<Input
							type="text"
							id="name"
							name="name"
							inputClassName="pr--5"
							value={values.name}
							isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							labelValue={`${translationService.translate("TR_NAME")}*`}
							onChange={event => onChange(event, "name")}
						/>
					</div>
					<div className={`col-12 col-md-6  my-1`}>
						<div className="position-relative">
							<Input
								type="text"
								id="buttonLink"
								name="buttonLink"
								inputClassName="pr--5"
								value={values.buttonLink}
								labelValue={`${translationService.translate("TR_BUTTON_LINK")}`}
								onChange={event => onChange(event, "buttonLink")}
							/>
						</div>
					</div>
					<div className="col-12 col-md-6 my-1">
						<Input
							type="text"
							id="storageSize"
							name="storageSize"
							inputClassName="pr--5"
							value={values.buttonLinkUnauth}
							labelValue={`${translationService.translate(
								"TR_BUTTON_LINK_FOR_UNAUTHORISED_USERS",
							)}`}
							onChange={event => onChange(event, "buttonLinkUnauth")}
						/>
					</div>
					<div className="col-12 col-md-6  my-1">
						<Input
							type="text"
							id="shortContent"
							name="shortContent"
							inputClassName="pr--5"
							value={values.shortContent}
							labelValue={`${translationService.translate(
								"TR_WEBINAR_STUDENTS_MAX_COUNT",
							)}`}
							onChange={event => onChange(event, "shortContent")}
						/>
					</div>
					<div className="col-12 col-md-6 col-lg-4 my-1">
						<label htmlFor="showInSlider">
							{translationService.translate("TR_SHOW_IN_SLIDER")}
						</label>
						<InputCheckBox
							id={`showInSlider`}
							name={`showInSlider`}
							checked={values.showInSlider}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "showInSlider");
							}}
						/>
					</div>
					<div className="col-12">
						{typeof values.fullContent === STRING_KEY ? (
							<RichTextEditor
								value={values.fullContent}
								config={{
									toolbar: [
										["Bold", "Italic", "Strike", "-", "RemoveFormat"],
										["Undo", "Redo"],
										[
											"NumberedList",
											"BulletedList",
											"-",
											"Outdent",
											"Indent",
											"-",
											"Blockquote",
										],
										["Styles", "Format"],
										// ['Maximize']
									],
								}}
								onEditorChange={event => onEditorChange(event)}
							/>
						) : null}
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div>
							<ActionButton
								spinnerId={buttonSpinnerId}
								clicked={onSubmit}
								type="submit"
								className="mindalay--btn-default float-right mb-4 px-5"
								name={translationService.translate("TR_SAVE")}
							/>
						</div>
					</div>
				</form>
			</div>
		)
	);
}
export default withRouter(PageContentForm);
