import React from "react";
import InfoSvg from "../Svg/infoSvg";
import PlusSvg from "../Svg/plusSvg";
import { LANGUAGE_KEY } from "../../Constants/mainKeys";
const Input = React.forwardRef((props, ref) => {
	const failedFieldName =
		props.name && props.name.charAt(0).toUpperCase() + props.name.slice(1);

	return (
		<div
			className={`${
				props.blockClassName ? props.blockClassName : ""
			} form-group`}>
			{props.labelValue && (
				<label
					htmlFor={props.id}
					className={`${props.labelClassName ? props.labelClassName : ""}`}
					style={{
						color: props.textColor
							? props.textColor
							: "var(--mindalay--gray-color)",
					}}>
					{props.labelValue}
				</label>
			)}
			{props.infoText ? (
				<div className="info-title d-inline-block">
					{/* <p date-info={props.infoText}>
              <InfoSvg />
            </p> */}
					<div>
						<InfoSvg />
						<span style={{ display: "none" }}>{props.infoText}</span>
					</div>
				</div>
			) : null}
			<div className={`input-wrapper`}>
				<div
					className={`${props.showPlusSvg ? "d-flex align-items-center" : ""}`}>
					<div className="position-relative w-100">
						{props.type === "number" && props.fieldLength ? (
							<span className="input-lenght">{props.fieldLength}</span>
						) : props.fieldLength ? (
							<span className="input-lenght">
								{props.fieldLength - (props.value && props.value.length)}
							</span>
						) : null}
						{/* {props.fieldLength && (
							<span className="input-lenght">
								{props.fieldLength - (props.value && props.value.length)}
							</span>
						)} */}
						<input
							ref={ref ? ref : null}
							readOnly={props.readOnly}
							id={props.id}
							type={props.type}
							name={props.name}
							min={props.min}
							max={props.max}
							lang={localStorage.getItem(LANGUAGE_KEY)}
							// maxLength={props.max}
							defaultValue={props.defaultValue}
							value={props.value}
							disabled={props.disabled}
							required={props.required}
							onKeyPress={props.onKeyPress}
							autoComplete={props.autoComplete ? props.autoComplete : "off"}
							onBlur={props.onBlur}
							style={props.style}
							onFocus={props.onFocus}
							placeholder={props.placeholder}
							onKeyDown={props.onKeyDown}
							autoFocus={props.autoFocus}
							className={`form-control ${
								props.className
									? props.className
									: props.inputClassName
									? props.inputClassName
									: ""
							}
          ${
						(props.failedFields &&
							props.failedFields.hasOwnProperty(failedFieldName)) ||
						(props.isInvalidSubmit && !props.value) ||
						props.isInvalidField
							? // || props.isInvalidSubmit
							  "is-invalid error-bordered"
							: ""
					}`}
							onChange={props.onChange}
						/>
						{props.withoutSpace && <small>{props.withoutSpace}</small>}
					</div>
					{props.showPlusSvg ? (
						<button
							type="button"
							className="mindalay--btn-secondary svg-button add-language-btn width-fit-content ml-2 plus-svg-in-input"
							onClick={props.onPlusSvgClick}>
							<PlusSvg />
						</button>
					) : null}
				</div>
			</div>
			{props.message ? (
				<small className="form-text text-muted">{props.message}</small>
			) : null}
			{props.failedFields &&
			props.failedFields.hasOwnProperty(failedFieldName) ? (
				<div className="small-error-msg">
					{props.failedFields[failedFieldName]}
				</div>
			) : null}
		</div>
	);
});

export default Input;
