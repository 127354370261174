import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import { LANGUAGE_KEY } from "../Constants/mainKeys";
import Translations from "../ContentManagerPages/Translations/translations";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import LogOut from "../Pages/LogOut/LogOut";


const ContentManagerRoutes = () => {
  return <Switch>
    {/* Profile */}
    <Route path="/:language/content-manager/translations" exact component={Translations} />

    {/* AuthenticationByRefreshToken */}
    <Route path="/:language/authentication/:refreshToken" exact component={AuthenticationByRefreshToken} />

    <Route path="/:language/under-maintenance" exact component={UnderMaintenancePage} />
    <Route path="/:language/logout" exact component={LogOut} />
    <Redirect to={`/${localStorage.getItem(LANGUAGE_KEY)}/content-manager/translations`} />

  </Switch>;
}

export default ContentManagerRoutes;
